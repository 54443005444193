import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

	private PATH_LOGIN:String = "/auth/login";
	private PATH_PROFILE:String = "/auth/profile";
	private PATH_REFRESH_TOKEN:String = "/auth/refreshtoken";
	private PATH_RECOVERY:String = "/auth/recoveryPassword";
	private PATH_CHANGE:String = "/auth/changePassword";

	private profileSource = new BehaviorSubject({});

	currentProfile = this.profileSource.asObservable();

	constructor(private http: HttpClient, private router: Router) {
		var profile = JSON.parse(localStorage.getItem("@PROFILE"));
		this.onProfileChanged(profile);
	}

	onProfileChanged(profile: any) {
		localStorage.removeItem('@PROFILE');
		localStorage.setItem("@PROFILE", JSON.stringify(profile));
		this.profileSource.next(profile);
	}

	getCurrentProfile(){
		var profile = JSON.parse(localStorage.getItem("@PROFILE"));
		return profile;
	}

	saveAccessToken(accessToken){
		localStorage.removeItem('@ACCESS_TOKEN');
		if(accessToken){
			localStorage.setItem("@ACCESS_TOKEN", accessToken);					
		}
	}

	login(obj) {
    	return this.http.post<any>(environment.apiUrl + this.PATH_LOGIN, obj, httpOptions)
    		.pipe(tap( // Log the result or error
	          data => console.log(this.PATH_LOGIN, data),
	          error => throwError(error)
	        )
	    );
    }

    recoverPassword(obj) {
    	return this.http.get<any>(environment.apiUrl + this.PATH_RECOVERY + '?email=' + obj.email, httpOptions)
    		.pipe(tap( // Log the result or error
	          data => console.log(this.PATH_LOGIN, data),
	          error => throwError(error)
	        )
	    );
    }

    changePassword(obj) {
    	return this.http.post<any>(environment.apiUrl + this.PATH_CHANGE, obj, httpOptions)
    		.pipe(tap( // Log the result or error
	          data => console.log(this.PATH_LOGIN, data),
	          error => throwError(error)
	        )
	    );
    }

    getProfile() {
    	return this.http.get<any>(environment.apiUrl + this.PATH_PROFILE, httpOptions)
    		.pipe(tap( // Log the result or error
	          data => console.log(this.PATH_PROFILE, data),
	          error => throwError(error)
	        )
	    );
    }

    logout() {
    	console.log("logout");
    	this.onProfileChanged(null);
    	this.saveAccessToken(null);
    	this.router.navigateByUrl('/login');
	}

}