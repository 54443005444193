<section class="container justify-content-center text-center p-0">
  <article class="row justify-content-center">
    <div class="col-12">
      <h4 class="catalog-title font-weight-bold text-dark mb-3">
        <img class="header-image" src="../assets/icons/ic_transportlines.png"/>
      LÍNEAS DE TRANSPORTE</h4>
      <div class="container">
        <div class="row mb-5" style="align-items: baseline;">
          <div class="col-lg-6 col-8">
            <div class="search-control">
              <i class="fa fa-search"></i>
              <input type="search" #searchInput [(ngModel)]="config.searchText" (search)="onSearch()" class="form-control input-search text-dark" placeholder="Buscar por RFC, Razón social, Nombre comercial, Ciudad"/>
            </div>
          </div>
          <div class="col-lg-6 col-4 text-right">
            <div class="catalog-btn">
              <img src="../assets/icons/ic_plus.png" (click)="openTransportLineModal();" />
              <label (click)="openTransportLineModal();">NUEVA LÍNEA</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">RFC</th>
                    <th scope="col">RAZÓN SOCIAL</th>
                    <th scope="col">NOMBRE COMERCIAL</th>
                    <th scope="col">CIUDAD</th>
                    <th scope="col">DIRECCIÓN</th>
                    <th scope="col">ACCIONES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rows | paginate: config">
                    <td>{{item.id}}</td>
                    <td>{{item.rfc | mask: 'AAAA-AAAAAA-AAA'}}</td>
                    <td>{{item.businessName}}</td>
                    <td>{{item.tradename}}</td>
                    <td>{{item.city}}</td>
                    <td>{{item.address}}</td>
                    <td class="iconContainer" style="display: flex;">
                      <img class="catalog-icon" src="../assets/icons/ic_edit.png" (click)="openTransportLineModal(item);" placement="top" ngbTooltip="editar"/>
                      <img *ngIf="item.status == 1" class="catalog-icon" src="../assets/icons/ic_enabled.png" (click)="disable(item);" placement="top" ngbTooltip="deshabilitar"/>
                      <img *ngIf="item.status == 0" class="catalog-icon" src="../assets/icons/ic_disabled.png" (click)="enable(item);" placement="top" ngbTooltip="habilitar"/>
                      <img class="catalog-icon" src="../assets/icons/ic_drivers.png" (click)="showDrivers(item);" placement="top" ngbTooltip="choferes"/>
                      <img *ngIf="false" class="catalog-icon" src="../assets/icons/ic_permissions.png" (click)="changePermissions(item);" placement="top" ngbTooltip="permisos"/>
                      <img *ngIf="!item.enabled" class="catalog-icon" src="../assets/icons/ic_delete.png" (click)="delete(item);" placement="top" ngbTooltip="eliminar"/>
                    </td>
                  </tr>
                  <tr *ngIf="(rows).length === 0">
                    <td colspan="6">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagerChanged($event)">

              <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                  <a (click)="p.previous()" *ngIf="!p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

              <div *ngFor="let page of p.pages" class="page-number" [class.current]="p.getCurrent() === page.value">
                  <a (click)="p.setCurrent(page.value);" *ngIf="p.getCurrent() !== page.value">
                      <span>{{ page.label }}</span>
                  </a>
                  <div *ngIf="p.getCurrent() === page.value">
                      <span>{{ page.label }}</span>
                  </div>
              </div>

              <div class="pagination-next" [class.disabled]="p.isLastPage()">
                  <a (click)="p.next()" *ngIf="!p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

            </pagination-template>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 pager">
            <label>Mostrar <select [(ngModel)]="config.itemsPerPage" (change)='onPagerChanged(1);'>
              <option *ngFor="let c of config.options" [ngValue]="c">{{c}}</option>
            </select> Registros por página</label>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>

<ngx-smart-modal customClass="xl-dialog closable" #transportLineModal identifier="transportLineModal" [closable]="true">
  <button class="btn-back-modal" (click)="transportLineModal.close()">
    <i class="fas fa-long-arrow-alt-left"></i>
  </button>
  <div class="container justify-content-center text-left">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <form [formGroup]="formTransportLine">
          <div class="row justify-content-center mb--4">
            <div class="col-6 text-center">
              <h1 class="h6 font-weight-bold text-dark mb-0 ml-3">
                <p *ngIf="!itemSelected.id">AGREGAR LÍNEA DE TRANSPORTE</p>
                <p *ngIf="itemSelected.id">EDITAR LÍNEA DE TRANSPORTE</p>
              </h1>
            </div>
          </div>
          <div class="{{itemSelected.rfc? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
            <label for="rfc" class="dark"><b class="red">*</b>RFC</label>
            <input type="text" class="form-control no-border" name="rfc" id="rfc" formControlName="rfc" value="{{itemSelected.rfc | mask: 'AAAA-AAAAAA-AAA'}}" [dropSpecialCharacters]="false" mask="AAAA-AAAAAA-AAA" />
            <div class="border-control"></div>
            <div *ngIf="formTransportLine.controls['rfc'].invalid && (formTransportLine.controls['rfc'].dirty || formTransportLine.controls['rfc'].touched)" class="inputAlert">
              <div *ngIf="formTransportLine.controls['rfc'].errors && formTransportLine.controls['rfc'].errors.required">
                  RFC obligatorio
              </div>
            </div>
          </div>
          <div class="{{itemSelected.businessName? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
            <label for="businessName" class="dark"><b class="red">*</b>Razón Social</label>
            <input type="text" class="form-control no-border" name="businessName" id="businessName" formControlName="businessName" value="{{itemSelected.businessName}}">
            <div class="border-control"></div>
            <div *ngIf="formTransportLine.controls['businessName'].invalid && (formTransportLine.controls['businessName'].dirty || formTransportLine.controls['businessName'].touched)" class="inputAlert">
              <div *ngIf="formTransportLine.controls['businessName'].errors && formTransportLine.controls['businessName'].errors.required">
                 Razón Social obligatoria
              </div>
            </div>
          </div>
          <div class="{{itemSelected.tradename? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
            <label for="tradename" class="dark"><b class="red">*</b>Nombre Comercial</label>
            <input type="text" class="form-control no-border" name="tradename" id="tradename" formControlName="tradename" value="{{itemSelected.tradename}}">
            <div class="border-control"></div>
            <div *ngIf="formTransportLine.controls['tradename'].invalid && (formTransportLine.controls['tradename'].dirty || formTransportLine.controls['tradename'].touched)" class="inputAlert">
              <div *ngIf="formTransportLine.controls['tradename'].errors && formTransportLine.controls['tradename'].errors.required">
                  Nombre Comercial obligatorio
              </div>
            </div>
          </div>
          <div class="{{itemSelected.city? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
              <label for="city" class="dark"><b class="red">*</b>Ciudad</label>
              <input type="text" class="form-control no-border" name="city" id="city" formControlName="city" value="{{itemSelected.city}}">
              <div class="border-control"></div>
              <div *ngIf="formTransportLine.controls['city'].invalid && (formTransportLine.controls['city'].dirty || formTransportLine.controls['city'].touched)" class="inputAlert">
                <div *ngIf="formTransportLine.controls['city'].errors && formTransportLine.controls['city'].errors.required">
                    Ciudad obligatoria
                </div>
              </div>
            </div>
            <div class="{{itemSelected.address? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
              <label for="address" class="dark"><b class="red">*</b>Dirección</label>
              <input type="text" class="form-control no-border" name="address" id="address" formControlName="address" value="{{itemSelected.address}}">
              <div class="border-control"></div>
              <div *ngIf="formTransportLine.controls['address'].invalid && (formTransportLine.controls['address'].dirty || formTransportLine.controls['address'].touched)" class="inputAlert">
                <div *ngIf="formTransportLine.controls['address'].errors && formTransportLine.controls['address'].errors.required">
                    Dirección obligatoria
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-5">
                <button type="submit" (click)="submitTransportLine(itemSelected.id)" class="btn btn-primary btn-user btn-block shadow-sm">
                  <span *ngIf="!itemSelected.id">AGREGAR</span>
                  <span *ngIf="itemSelected.id">GUARDAR</span>
                  <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                </button>
              </div>
              <div class="col-lg-3">
                <button type="cancel" (click)="transportLineModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
              </div>
            </div>
        </form>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="xxxl-dialog" #driversModal identifier="driversModal" [closable]="true">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <app-drivers [transportLine]="itemSelected"></app-drivers>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #successModal1 identifier="successModal1" [closable]="false">
  <div *ngIf="successModal1.hasData()" class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">{{ successModal1.getData().title }}</h1>
        <p class="h6" style="font-size: 0.9rem;">{{ successModal1.getData().subtitle }} </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal1.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #deleteTransportModal identifier="deleteTransportModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_warning.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¿Desea eliminar este registro?</h1>
        <p class="h6" style="font-size: 0.9rem;">Recuerda que será eliminado de la base de datos</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <button type="submit" (click)="deleteTransportLine(itemSelected.id)" class="btn btn-primary btn-user btn-block shadow-sm">SÍ, ELIMINAR<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
      <div class="col-4">
        <button type="cancel" (click)="deleteTransportModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
      </div>
    </div>
  </div>
</ngx-smart-modal>