<div class="container justify-content-center text-left">
  <div class="row justify-content-center mt-3">
    <div class='col-12'>
      <div class="container">
        <div class="row mb-5" style="align-items: baseline;">
          <div class="col-lg-6 col-8">
            <div class="search-control">
              <i class="fa fa-search"></i>
              <input type="search" #searchInput [(ngModel)]="config.searchText" (search)="onSearch()" class="form-control input-search text-dark" placeholder="Buscar por nombre"/>
            </div>
          </div>
          <div class="col-lg-6 col-4 text-right">
            <div class="catalog-btn">
              <img src="../assets/icons/ic_plus.png" (click)="openTagModal();" />
              <label (click)="openTagModal();">Nueva Etiqueta</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">NOMBRE LARGO</th>
                    <th scope="col">ACCIONES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rows | paginate: config">
                    <td>{{item.id}}</td>
                    <td>{{item.name}}</td>
                    <td class="iconContainer" style="display: flex;">
                      <img class="catalog-icon" src="../assets/icons/ic_edit.png" (click)="edit(item);" placement="top" ngbTooltip="editar"/>
                      <!--
                      <img *ngIf="item.enabled" class="catalog-icon" src="../assets/icons/ic_enabled.png" (click)="active(item, false);" placement="top" ngbTooltip="deshabilitar"/>
                      <img *ngIf="!item.enabled" class="catalog-icon" src="../assets/icons/ic_disabled.png" (click)="active(item, true);" placement="top" ngbTooltip="habilitar"/>
                      <img *ngIf="false" class="catalog-icon" src="../assets/icons/ic_permissions.png" (click)="changePermissions(item);" placement="top" ngbTooltip="permisos"/>
                      !-->
                      <img *ngIf="!item.enabled" class="catalog-icon" src="../assets/icons/ic_delete.png" (click)="delete(item.id);" placement="top" ngbTooltip="eliminar"/>
                    </td>
                  </tr>
                  <tr *ngIf="(rows).length === 0">
                    <td colspan="6">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagerChanged($event)">

              <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                  <a (click)="p.previous()" *ngIf="!p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

              <div *ngFor="let page of p.pages" class="page-number" [class.current]="p.getCurrent() === page.value">
                  <a (click)="p.setCurrent(page.value);" *ngIf="p.getCurrent() !== page.value">
                      <span>{{ page.label }}</span>
                  </a>
                  <div *ngIf="p.getCurrent() === page.value">
                      <span>{{ page.label }}</span>
                  </div>
              </div>

              <div class="pagination-next" [class.disabled]="p.isLastPage()">
                  <a (click)="p.next()" *ngIf="!p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

            </pagination-template>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 pager">
            <label>Mostrar <select [(ngModel)]="config.itemsPerPage" (change)='onPagerChanged(1);'>
              <option *ngFor="let c of config.options" [ngValue]="c">{{c}}</option>
            </select> Registros por página</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-smart-modal customClass="xl-dialog closable" #TagModal identifier="TagModal" [closable]="true">
  <button class="btn-back-modal" (click)="TagModal.close()">
    <i class="fas fa-long-arrow-alt-left"></i>
  </button>
  <div class="container justify-content-center text-left">
    <div class="row justify-content-center mb--4">
      <div class="col-6 text-center">
        <h1 class="h6 font-weight-bold text-dark mb-0 ml-3">
          <p *ngIf="!itemSelected.id">AGREGAR ETIQUETA</p>
          <p *ngIf="itemSelected.id">EDITAR ETIQUETA</p>
        </h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <form [formGroup]="formTag">
          <div class="container justify-content-center text-left">
            <div class="row">
              <div class="col-lg-7">
                <div class="{{itemSelected.name? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                  <label for="name" class="dark">
                    <b class="red">*</b>Ingresar nombre de la etiqueta
                  </label>
                  <input type="text" class="form-control no-border" name="name" id="name" formControlName="name" value="{{itemSelected.name}}">
                  <div class="border-control"></div>
                  <div *ngIf="formTag.controls['name'].invalid && (formTag.controls['name'].dirty || formTag.controls['name'].touched)" class="inputAlert">
                    <div *ngIf="formTag.controls['name'].errors && formTag.controls['name'].errors.required">
                      Nombre Largo obligatorio
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="modal-catalog-btn mt-3">
                  <img src="../assets/icons/ic_plus.png" (click)="submitUnit(itemSelected.id);" />
                  <label *ngIf="!itemSelected.id" (click)="submitUnit(itemSelected.id);">AGREGAR</label>
                  <label *ngIf="itemSelected.id" (click)="submitUnit(itemSelected.id);">ACTUALIZAR</label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ngx-smart-modal>