<section class="container justify-content-center text-center p-0">
  <article class="row justify-content-center">
    <div class="col-lg-11 col-sm-12">
      <ngb-tabset type="pills" justify="center" #t="ngbTabset">
        <!-- UNIDAD !-->
        <ngb-tab id="tab-selectbyid1">
          <ng-template ngbTabTitle>
            <span (click)="tabClicked(Unit)">UNIDAD</span>
            <div class="border-tab"></div>
          </ng-template>
          <ng-template ngbTabContent>
            <app-units-unit [product]="currentProduct"></app-units-unit>
          </ng-template>
        </ngb-tab>
        <!-- CALIDAD !-->
        <ngb-tab id="tab-selectbyid2">
          <ng-template ngbTabTitle>
            <span (click)="tabClicked(Quality)">CALIDAD</span>
            <div class="border-tab"></div>
          </ng-template>
          <ng-template ngbTabContent>
            <app-units-quality [product]="currentProduct"></app-units-quality>
          </ng-template>
        </ngb-tab>
        <!-- ETIQUETA !-->
        <ngb-tab id="tab-selectbyid3">
          <ng-template ngbTabTitle>
            <span (click)="tabClicked(Tag)">ETIQUETA</span>
            <div class="border-tab"></div>
          </ng-template>
          <ng-template ngbTabContent>
            <app-units-tag [product]="currentProduct"></app-units-tag>
          </ng-template>
        </ngb-tab>
        <!-- TAMAÑO !-->
        <ngb-tab id="tab-selectbyid4">
          <ng-template ngbTabTitle>
            <span (click)="tabClicked(Size)">TAMAÑO</span>
            <div class="border-tab"></div>
          </ng-template>
          <ng-template ngbTabContent>
            <app-units-size [product]="currentProduct"></app-units-size>
          </ng-template>
        </ngb-tab>
        <!-- PRESENTACIÓN !-->
        <ngb-tab id="tab-selectbyid5">
          <ng-template ngbTabTitle>
            <span (click)="tabClicked(Presentation)">PRESENTACIÓN</span>
            <div class="border-tab"></div>
          </ng-template>
          <ng-template ngbTabContent>
            <app-units-presentation [product]="currentProduct"></app-units-presentation>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </article>
  <!--
  <article class="row justify-content-center">
    <div class="col-6 text-left">
      <div style="display:flex; flex-direction:column; width:100%; align-items:end;">
        <img class="btn-icon" style="transform: rotate(180deg); width: 2rem; cursor:pointer;" src="../assets/icons/ic_dark_chevron_double_right.png" (click)="prevTab();"/>
        <label style="font-size: 0.6rem; cursor:pointer;" (click)="prevTab();">ANTERIOR</label>
      </div>
    </div>
    <div class="col-6 text-right">
      <div style="display:flex; flex-direction:column; width:100%; align-items:flex-end;">
        <img class="btn-icon" style="width: 2rem; cursor:pointer;" src="../assets/icons/ic_dark_chevron_double_right.png" (click)="nextTab();" />
        <label style="font-size: 0.6rem; cursor:pointer;" (click)="nextTab();" >SIGUIENTE</label>
      </div>
    </div>
  </article>
  !-->
</section>

<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div *ngIf="successModal.hasData()" class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">{{ successModal.getData().title }}</h1>
        <p class="h6" style="font-size: 0.9rem;">{{ successModal.getData().subtitle }} </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>