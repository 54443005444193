<div class="container">
    <!-- Outer Row -->
    <div class="row justify-content-center">
      <div class="col-xl-9 col-lg-9 col-md-9">
        <div id="card-company" class="card shadow o-hidden">
          <div class="card-body pt-3 pl-5 pr-5">
            <!-- Nested Row within Card Body -->
            <div class="row">
              <div class="col-lg-12">
              	<form [formGroup]="formCompany" (ngSubmit)="updateCompany()">
              		<div class="row justify-content-center mb--4">
              			<div class="col-lg-4 col-md-4 col-sm-4 ml-4">
        							<div class="list-group-horizontal text-center align-items-center">
        								<img class="header-image" src="../assets/icons/ic_company.png"/>
        								<h1 class="h4 font-weight-bold text-dark mb-0 ml-3">EMPRESA</h1>
        							</div>
        						</div>
        					</div>
              		<div class="{{company.rfc? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
              			<label for="rfc" class="dark"><b class="red">*</b>RFC</label>
              			<input type="text" class="form-control no-border" name="rfc" id="rfc" formControlName="rfc" value="{{company.rfc | mask: 'AAAA-AAAAAA-AAA'}}" [dropSpecialCharacters]="false" mask="AAAA-AAAAAA-AAA">
                    <div class="border-control"></div>
                    <div *ngIf="formCompany.controls['rfc'].invalid && (formCompany.controls['rfc'].dirty || formCompany.controls['rfc'].touched)" class="inputAlert">
                      <div *ngIf="formCompany.controls['rfc'].errors && formCompany.controls['rfc'].errors.required">
                          RFC obligatorio
                      </div>
                    </div>
                  </div>
                  <div class="{{company.name? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                    <label for="name" class="dark"><b class="red">*</b>Nombre de la empresa</label>
              			<input type="text" class="form-control no-border" name="name" id="name" formControlName="name" value="{{company.name}}">
                    <div class="border-control"></div>
                    <div *ngIf="formCompany.controls['name'].invalid && (formCompany.controls['name'].dirty || formCompany.controls['name'].touched)" class="inputAlert">
                      <div *ngIf="formCompany.controls['name'].errors && formCompany.controls['name'].errors.required">
                          Nombre de la empresa obligatorio
                      </div>
                    </div>
                  </div>
                  <div class="{{company.state? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                    <label for="state" class="dark"><b class="red">*</b>Estado</label>
                    <input type="text" class="form-control no-border" name="state" id="state" formControlName="state" value="{{company.state}}">
                    <div class="border-control"></div>
                    <div *ngIf="formCompany.controls['state'].invalid && (formCompany.controls['state'].dirty || formCompany.controls['state'].touched)" class="inputAlert">
                      <div *ngIf="formCompany.controls['state'].errors && formCompany.controls['state'].errors.required">
                          Estado obligatorio
                      </div>
                    </div>
                  </div>
                  <div class="{{company.postalCode? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                    <label for="postalCode" class="dark"><b class="red">*</b>Código Postal</label>
                    <input type="text" class="form-control no-border" name="postalCode" id="postalCode" formControlName="postalCode" value="{{company.postalCode}}">
                    <div class="border-control"></div>
                    <div *ngIf="formCompany.controls['postalCode'].invalid && (formCompany.controls['postalCode'].dirty || formCompany.controls['postalCode'].touched)" class="inputAlert">
                      <div *ngIf="formCompany.controls['postalCode'].errors && formCompany.controls['postalCode'].errors.required">
                          Código Postal obligatorio
                      </div>
                    </div>
                  </div>
                  <div class="{{company.city? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                    <label for="city" class="dark"><b class="red">*</b>Ciudad</label>
                    <input type="text" class="form-control no-border" name="city" id="city" formControlName="city" value="{{company.city}}">
                    <div class="border-control"></div>
                    <div *ngIf="formCompany.controls['city'].invalid && (formCompany.controls['city'].dirty || formCompany.controls['city'].touched)" class="inputAlert">
                      <div *ngIf="formCompany.controls['city'].errors && formCompany.controls['city'].errors.required">
                          Ciudad obligatoria
                      </div>
                    </div>
                  </div>
                  <div class="{{company.address? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                    <label for="address" class="dark"><b class="red">*</b>Dirección</label>
                    <input type="text" class="form-control no-border" name="address" id="address" formControlName="address" value="{{company.address}}">
                    <div class="border-control"></div>
                    <div *ngIf="formCompany.controls['address'].invalid && (formCompany.controls['address'].dirty || formCompany.controls['address'].touched)" class="inputAlert">
                      <div *ngIf="formCompany.controls['address'].errors && formCompany.controls['address'].errors.required">
                          Dirección obligatoria
                      </div>
                    </div>
                  </div>
                  
                  <div class="{{company.phone? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                    <label for="phone" class="dark"><b class="red">*</b>Teléfonos</label>
                    <input type="text" class="form-control no-border" name="phone" id="phone" formControlName="phone" value="{{company.phone | mask: '(000)000-00-00'}}" [dropSpecialCharacters]="false" mask="(000)000-00-00">
                    <div class="border-control"></div>
                    <div *ngIf="formCompany.controls['phone'].invalid && (formCompany.controls['phone'].dirty || formCompany.controls['phone'].touched)" class="inputAlert">
                      <div *ngIf="formCompany.controls['phone'].errors && formCompany.controls['phone'].errors.required">
                          Teléfonos obligatorios
                      </div>
                    </div>
                  </div>
                  <div class="{{company.representative? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                    <label for="representative" class="dark"><b class="red">*</b>Representante</label>
                    <input type="text" class="form-control no-border" name="representative" id="representative" formControlName="representative" value="{{company.representative}}">
                    <div class="border-control"></div>
                    <div *ngIf="formCompany.controls['representative'].invalid && (formCompany.controls['representative'].dirty || formCompany.controls['representative'].touched)" class="inputAlert">
                      <div *ngIf="formCompany.controls['representative'].errors && formCompany.controls['representative'].errors.required">
                          Representante obligatorio
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center" style="margin-top: 2rem !important;">
                    <div class="col-12 m-2 list-group-horizontal align-items-center">
                      <img [src]="url" class="header-image">
                      <div>
                        <label (click)="openLogoModal();" for="files" class="btn" style="margin-top: -0.8rem;">Seleccionar logo...</label>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-4 mt-2">
                      <button type="submit" class="btn btn-primary btn-user btn-block shadow-sm">
                        GUARDAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                      </button>
                    </div>
                  </div>
              	</form>
              </div>
      			</div>
          </div>
        </div>
      </div>
    </div>

</div>

<ngx-smart-modal customClass="sm-dialog closable" #logoModal identifier="logoModal" [closable]="true">
  <div class="container justify-content-center text-center mb-1">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div id="dropzone" class="custom-dropzone" ngx-dropzone [multiple]="false" (change)="onSelect($event)" accept="image/*">
          <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
          <img *ngIf="files.length == 0" src="../assets/icons/ic_plus.png" style="width: 60px; margin: 10px;" alt="" />
          <p *ngIf="files.length == 0" style="font-size: 0.8rem;">Selecciona y/o arrastra el logo</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p style="height: 3rem; padding: 0.5rem;" class="{{resLogoMessage.type === 'success'? 'primary-color': 'red'}}">{{resLogoMessage.message}}</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <button type="submit" (click)="submitlogo()" class="btn btn-primary btn-user btn-block shadow-sm">GUARDAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
      <div class="col-4">
        <button type="cancel" (click)="logoModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
      </div>
    </div>

  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">!Datos de empresa guardados!</h1>
        <p class="h6" style="font-size: 0.9rem;">los datos han sido guardados exitosamente.</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>