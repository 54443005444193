export class Sale {
  id: number = 0;
  trip: String = "";
  purchaseDate: String = "";
  arrivalDate: String =  "";
  quantity: number = 0;
  amount: number = 0;
  client: number = 0;
  transportLines: any = null;
  driver: any = null;
  saleProductsProductsOrder:any = [];
  observation:any = [];
  expenseRecordsTransportLine:any = [];
  expenseRecordsAdditional:any = [];
};