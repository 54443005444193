<div class="container">
  <!-- Outer Row -->
  <div class="row justify-content-center mtr-2">
    <div class="col-12 bordered" id="print-section">
      <table class="table main-table">
        <thead>
          <tr>
            <th colspan="3" scope="col" style="vertical-align: top;">
              <div class="company-container">
                <img id="logo" [src]="environment.imagesUrl + company.logo" style="height: 120px;" />
                <div class="datos">
                  <span class="title">{{company.name}}</span>
                  <span>{{company.representative}}</span>
                  <span>R.F.C. {{company.rfc}}</span>
                  <span>{{company.state}}, {{company.city}} C.P. {{company.postalCode}}</span>
                  <span>{{company.address}}</span>
                  <span>TELS. {{company.phone}}</span>
                </div>
              </div>
            </th>
            <th colspan="1" scope="col"></th>
            <th colspan="2" scope="col" style="vertical-align: top;">
              <div class="gray-area m-1 pl-3 pr-3 pt-1 pb-1" style="border-radius: 30px;">
                <h1 class="primary" style="font-size: 18px; font-family: poppins-semibold;">
                  Nota de venta
                </h1>
                <div class="text-center" style="display: table;">
                  <h1 style="font-size: 10px; margin: 0px;">Número de Remisión</h1>
                  <h1 class="primary" style="font-size: 42px;font-family: poppins-semibold;">{{saleSelected.id}}</h1>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th colspan="8">
              <div *ngIf="saleSelected.transportLines" class="row text-left gray-area m-1 p-1">
                <div class="col-12">
                  <div class="container mb-0">
                    <div class="row text-left gray-area m-1 p-1" style="align-items: center;">
                      <div class="col-3">
                        <h1><b class="primary">{{saleSelected.client.businessName}}</b></h1>
                        <p class="m-0">Cliente</p>
                      </div>
                      <div class="col-3">
                        <p class="m-0"><span class="primary">Tel:</span> {{saleSelected.client.phone}}</p>
                        <p><span class="primary">E-mail:</span> {{saleSelected.client.email}}</p>
                      </div>
                      <div class="col-3">
                        <p><span class="primary">Dirección:</span> {{saleSelected.client.address}}. {{saleSelected.client.city}}</p>
                      </div>
                      <div class="col-3">
                        <p class="m-0">Fecha de Expedición</p>
                        <h1><b class="primary">{{saleSelected.purchaseDate}}</b></h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <td colspan="7" scope="col">

              <table class="table secondary-table">
                <thead class="gray-area" style="border: 1px solid black;">
                  <tr>
                    <th scope="col" style="color: black !important; width: 10% !important;">VIAJE</th>
                    <th scope="col" style="color: black !important;">CANTIDAD</th>
                    <th scope="col" style="color: black !important;">UNIDAD</th>
                    <th scope="col" style="color: black !important;">PRODUCTO</th>
                    <th scope="col" style="color: black !important;">CALIDAD</th>
                    <th scope="col" style="color:black !important;">PRESENTACIÓN</th>
                    <th scope="col" style="color: black !important;">ETIQUETA</th>
                    <th scope="col" style="color: black !important;">TAMAÑO</th>
                    <th scope="col" style="color: black !important;">PRECIO DE VENTA</th>
                    <th scope="col" style="color: black !important;">IMPORTE</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="saleSelected">
                    <tr *ngFor="let item of saleSelected.saleProductsProductsOrder">
                      <td>{{item.trip}}</td>
                      <td>{{item.quantity}}</td>
                      <td>{{item.units}}</td>
                      <td>{{item.productName}}</td>
                      <td>{{item.quality}}</td>
                      <td>{{item.presentation}}</td>
                      <td>{{item.tag}}</td>
                      <td>{{item.size}}</td>
                      <td>{{item.price | currency}}</td>
                      <td>{{(item.price * item.quantity) | currency }}</td>
                    </tr>
                    <tr *ngIf="(saleSelected.saleProductsProductsOrder).length === 0">
                      <td colspan="7">No se encontraron elementos</td>
                    </tr>
                    <tr>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                    </tr>
                    <tr>
                      <td colspan="1" style="color: black !important;">TOTAL DE PRODUCTOS:</td>
                      <td colspan="1"><b>{{saleSelected.quantity}}</b></td>
                      <td colspan="6" style="border-bottom: none !important;border-right: none !important;"></td>
                      <td colspan="1" style="color: black !important;">IMPORTE TOTAL</td>
                      <td colspan="1"><b>{{saleSelected.amount | currency}}</b></td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>

            </td>
          </tr>
          <tr style="text-align: left;">
            <td colspan="7">
              <b class="primary">GASTOS DE TRANSPORTE</b>
            </td>
          </tr>
          <tr>
            <td colspan="7" scope="col">
              <table class="table secondary-table">
                <thead class="gray-area" style="border: 1px solid black;">
                  <tr>
                    <th scope="col" style="color: black !important;width:10% !important;">TRANSPORTE</th>
                    <th scope="col" style="color: black !important;width:10% !important;">CHOFER</th>
                    <th scope="col" style="color: black !important;width:10% !important;">TELÉFONO</th>
                    <th scope="col" style="color: black !important;width:10% !important;">CONCEPTO</th>
                    <th scope="col" style="color: black !important;width:10% !important;">VALOR</th>
                    <th scope="col" style="color: black !important;width:10% !important;">IVA</th>
                    <th scope="col" style="color: black !important;width:10% !important;">IMPORTE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of saleSelected.expenseRecordsTransportLine">
                    <td colspan="1">{{saleSelected.transportLines? saleSelected.transportLines.tradename: ''}}</td>
                    <td colspan="1">{{saleSelected.driver? saleSelected.driver.name + " " + saleSelected.driver.firstSurname: ''}}</td>
                    <td colspan="1">{{saleSelected.driver? saleSelected.driver.phone: ''}}</td>
                    <td colspan="1">{{item.concept}}</td>
                    <td colspan="1">{{item.value | currency}}</td>
                    <td colspan="1">{{(item.iva?(item.value*0.16):0) | currency}}</td>
                    <td colspan="1">{{(item.iva?(item.value*1.16):item.value) | currency}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="height: 30px;"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                  </tr>
                  <tr *ngIf="(saleSelected.expenseRecordsTransportLine).length === 0">
                    <td colspan="7">No se encontraron elementos</td>
                  </tr>
                  <tr>
                    <td colspan="5" style="border-bottom: none !important; border-left: none !important;"></td>
                    <td colspan="1" style="color: black !important;">GASTOS DE TRANSPORTE:</td>
                    <td colspan="1" class="gray-area"><b>{{saleSelected.transportLinesExpense | currency}}</b></td>
                  </tr>
                </tbody>
              </table>

            </td>
          </tr>

          <tr style="text-align: left;">
            <td colspan="7">
              <b class="primary">GASTOS ADICIONALES</b>
            </td>
          </tr>
          <tr>
            <td colspan="7" scope="col">
              <table class="table secondary-table">
                <thead class="gray-area" style="border: 1px solid black;">
                  <tr>
                    <th scope="col" style="color: black !important;width:40% !important;">CONCEPTO</th>
                    <th scope="col" style="color: black !important;width:9% !important;">VALOR</th>
                    <th scope="col" style="color: black !important;width:9% !important;">IVA</th>
                    <th scope="col" style="color: black !important;width:10% !important;">IMPORTE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of saleSelected.expenseRecordsAdditional">
                    <td colspan="1">{{item.concept}}</td>
                    <td colspan="1">{{item.value | currency}}</td>
                    <td colspan="1">{{(item.iva?(item.value*0.16):0) | currency}}</td>
                    <td colspan="1">{{(item.iva?(item.value*1.16):item.value) | currency}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="height: 30px;"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                  </tr>
                  <tr *ngIf="(saleSelected.expenseRecordsAdditional).length === 0">
                    <td colspan="4">No se encontraron elementos</td>
                  </tr>
                  <tr>
                    <td colspan="2" style="border-bottom: none !important; border-left: none !important;"></td>
                    <td colspan="1" style="color: black !important;">GASTOS ADICIONALES:</td>
                    <td colspan="1" class="gray-area"><b>{{saleSelected.additionalCosts | currency}}</b></td>
                  </tr>
                </tbody>
              </table>

            </td>
          </tr>

          <tr style="text-align: left;">
            <td colspan="7">
              <b class="primary">OBSERVACIONES</b>
            </td>
          </tr>
          <tr>
            <td colspan="4" scope="col">
              <table class="table secondary-table">
                <thead class="gray-area" style="border: 1px solid black;">
                  <tr>
                    <th colspan="1" scope="col" style="color: black !important; text-align: left;">DESCRIPCIÓN DE LA OBSERVACIÓN</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of saleSelected.observation">
                    <td colspan="1" style="text-align: left;">{{item.observation}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="height: 30px;"></td>
                  </tr>
                  <tr *ngIf="(saleSelected.observation).length === 0">
                    <td colspan="2">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td colspan="3" scope="col">
              <table class="table secondary-table">
                <tbody>
                  <tr>
                    <td colspan="1" style="border: none !important; color: black;width: 49%; font-size: 12px; text-align: right;">
                      <b>IMPORTE TOTAL DE PRODUCTOS:</b>
                    </td>
                    <td colspan="1">{{saleSelected.amount | currency}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="border: none !important; color: black;font-size: 12px; text-align: right;">
                      <b>GASTOS DE TRANSPORTE:</b>
                    </td>
                    <td colspan="1">{{saleSelected.transportLinesExpenseSinIva  | currency}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="border: none !important; color: black;font-size: 12px; text-align: right;">
                      <b>GASTOS ADICIONALES:</b>
                    </td>
                    <td colspan="1">{{saleSelected.additionalCostsSinIva  | currency}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="border: none !important; color: black;font-size: 12px; text-align: right;">
                      <b>IVA:</b>
                    </td>
                    <td colspan="1" style="color: lightslategray;">{{saleSelected.ivaTotal | currency}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="border: none !important; color: black; font-size: 18px !important; text-align: right;"><b>TOTAL A PAGAR:</b></td>
                    <td colspan="1" class="gray-area"><b class="primary" style="padding: 0px 24px; font-size: 18px;">{{(saleSelected.amount + saleSelected.additionalCosts + saleSelected.transportLinesExpense) | currency}}</b></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </thead>
        <tbody id="footer" class="footer" style="border: none !important;">
          <tr>
            <td colspan="1" scope="col" style="text-align: center;">PÁG. 1 DE 1</td>
            <td colspan="1" scope="col"></td>
            <td colspan="1" scope="col">Venta registrada y realizada por:</td>
            <td colspan="2" scope="col">{{saleSelected.employee && saleSelected.employee.user? saleSelected.employee.name + ' ' + saleSelected.employee.firstSurname + ' ' + saleSelected.employee.user.email: ''}}</td>
            <td colspan="1" scope="col" style="border-right: 1px solid;">Fecha y hora: {{saleSelected.purchaseDate}}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-3">
      <button type="submit" (click)="saveSale()" class="btn btn-primary btn-user btn-block shadow-sm" printSectionId="print-section" [useExistingCss]="true" ngxPrint>GUARDAR E IMPRIMIR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
      </button>
    </div>
    <div class="col-2">
      <button type="submit" (click)="saveSale()" class="btn btn-primary btn-user btn-block shadow-sm">GUARDAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
      </button>
    </div>
    <div class="col-2">
       <button type="cancel" [routerLink]="['/sales']" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
    </div>
  </div>
</div>

<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¡Venta registrada con éxito!</h1>
        <p class="h6" style="font-size: 0.9rem;">La venta se ha registrado con el Nº Remisión <b>{{saleSelected.id}}</b></p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" [routerLink]="['/sales']" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>