import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { MouseEvent } from '@agm/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbTabset, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../../environments/environment';
import { FormsService } from './../../../services/forms/forms.service';
import { LossesService } from './../../../services/losses/losses.service';
import { Lost } from './../Lost';

@Component({
  selector: 'app-lost',
  templateUrl: './lost.component.html',
  styleUrls: ['./lost.component.css']
})
export class LostComponent implements OnInit {
  @ViewChild('t') tabs:NgbTabset;
  public currentTab:number = 1;
  public lostSelected:Lost = new Lost();

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private lossesService: LossesService, private toastService: ToastrService, public route:ActivatedRoute,
    @Inject(DOCUMENT) document,  private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if(params.id){
        this.lostSelected.id = +params['id'];
        this.getLost(this.lostSelected.id);
      }
    });
  }

  public getLost(id){
    console.log("getLost-->", id);
    this.spinner.show();
    this.lossesService.getLost(id).subscribe(
      (res) => {
        console.log("lost-res->", res);
        this.lostSelected = res;
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public beforeChange($event: NgbTabChangeEvent) {
    console.log("$event", $event);
    switch ($event.nextId) {
      case 'tab-selectbyid1':
        this.currentTab = 1;
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.remove("active");
            document.getElementById('tab-selectbyid3').classList.remove("active");
          }, 100);
        break;
      case 'tab-selectbyid2':
        console.log("this.currentTab", this.currentTab);
        if(this.currentTab < 2 && !this.lostSelected.id){
          $event.preventDefault();
        }else{
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.add("active");
            document.getElementById('tab-selectbyid3').classList.remove("active");
          }, 100);
          this.currentTab = 2;
        }
        break;
      case 'tab-selectbyid3':
        if(this.currentTab < 3 && !this.lostSelected.id){
          $event.preventDefault();
        }else{
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.add("active");
            document.getElementById('tab-selectbyid3').classList.add("active");
          }, 100);
          this.currentTab = 3;
        }
        break;
      default:
        // code...
        break;
    }
  }

  public continue($event){
    this.currentTab++;
    this.tabs.select('tab-selectbyid' + this.currentTab);
    console.log("tab", this.currentTab);
  }

  public updateLost($event){
    this.spinner.show();
    this.lossesService.updateLost(this.lostSelected.id, $event).subscribe(
      (res) => {
        this.lostSelected = res;
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
        this.toastService.warning(err.error.message);
      }
    );
  }
  
}