<section class="container justify-content-center text-center p-0">
  <article class="row justify-content-center">
    <div class="col-lg-11 col-sm-12">
      <h1 class="h6 font-weight-bold text-dark mb-0 ml-3" style="text-transform: uppercase;">
        AGREGAR CHOFER A LÍNEA DE TRANSPORTE
      </h1>
    </div>
  </article>
  <article class="row justify-content-center">
    <div class="col-6">
      <div class="container  justify-content-center text-left">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <form [formGroup]="formDriver">
              <div class="{{itemSelected.name? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                <label for="name" class="dark"><b class="red">*</b>Nombres</label>
                <input type="text" class="form-control no-border" name="name" id="name" formControlName="name" value="{{itemSelected.name}}">
                <div class="border-control"></div>
                <div *ngIf="formDriver.controls['name'].invalid && (formDriver.controls['name'].dirty || formDriver.controls['name'].touched)" class="inputAlert">
                  <div *ngIf="formDriver.controls['name'].errors && formDriver.controls['name'].errors.required">
                      Nombre obligatorio
                  </div>
                </div>
              </div>
              <div class="{{itemSelected.firstSurname? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                  <label for="firstSurname" class="dark"><b class="red">*</b>Apellido paterno</label>
                  <input type="text" class="form-control no-border" name="firstSurname" id="firstSurname" formControlName="firstSurname" value="{{itemSelected.firstSurname}}">
                  <div class="border-control"></div>
                  <div *ngIf="formDriver.controls['firstSurname'].invalid && (formDriver.controls['firstSurname'].dirty || formDriver.controls['firstSurname'].touched)" class="inputAlert">
                    <div *ngIf="formDriver.controls['firstSurname'].errors && formDriver.controls['firstSurname'].errors.required">
                        Apellido paterno obligatorio
                    </div>
                  </div>
                </div>
                <div class="{{itemSelected.secondSurname? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                  <label for="secondSurname" class="dark"><b class="red">*</b>Apellido materno</label>
                  <input type="text" class="form-control no-border" name="secondSurname" id="secondSurname" formControlName="secondSurname" value="{{itemSelected.secondSurname}}">
                  <div class="border-control"></div>
                  <div *ngIf="formDriver.controls['secondSurname'].invalid && (formDriver.controls['secondSurname'].dirty || formDriver.controls['secondSurname'].touched)" class="inputAlert">
                    <div *ngIf="formDriver.controls['secondSurname'].errors && formDriver.controls['secondSurname'].errors.required">
                        Apellido materno obligatorio
                    </div>
                  </div>
                </div>
                <div class="{{itemSelected.phone? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                  <label for="phone" class="dark"><b class="red">*</b>Teléfonos</label>
                  <input type="text" class="form-control no-border" name="phone" id="phone" formControlName="phone" value="{{itemSelected.phone | mask: '(000)000-00-00'}}" [dropSpecialCharacters]="false" mask="(000)000-00-00">
                  <div class="border-control"></div>
                  <div *ngIf="formDriver.controls['phone'].invalid && (formDriver.controls['phone'].dirty || formDriver.controls['phone'].touched)" class="inputAlert">
                    <div *ngIf="formDriver.controls['phone'].errors && formDriver.controls['phone'].errors.required">
                        Teléfono obligatorio
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-lg-5">
                    <button type="submit" (click)="submitDriver(itemSelected.id)" class="btn btn-primary btn-user btn-block shadow-sm">
                      <span *ngIf="!itemSelected.id">AGREGAR CHOFER</span>
                      <span *ngIf="itemSelected.id">ACTUALIZAR</span>
                      <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                    </button>
                  </div>
                  <div class="col-lg-3">
                    <button type="cancel" (click)="clearForm();" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
                  </div>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 1px; position: absolute; top: 10%; height: 70%; background: darkgray; margin: 0 auto;"></div>
    <div class="col-6">
      <div class="container" style="margin-top: 4rem !important;">
        <div class="row mb-3" style="align-items: baseline;">
          <div class="col-lg-10">
            <div class="search-control">
              <i class="fa fa-search"></i>
              <input type="search" #searchInput [(ngModel)]="config2.searchText" (search)="onSearch()" class="form-control input-search text-dark" placeholder="Buscar por Nombres, Apellidos"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">NOMBRES</th>
                    <th scope="col">APELLIDO PATERNO</th>
                    <th scope="col">APELLIDO MATERNO</th>
                    <th scope="col">TELÉFONO</th>
                    <th scope="col">ACCIONES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rows | paginate: config2">
                    <td>{{item.id}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.firstSurname}}</td>
                    <td>{{item.secondSurname}}</td>
                    <td>{{item.phone | mask: '(000)000-00-00'}}</td>
                    <td class="iconContainer" style="display: flex;">
                      <img class="catalog-icon" src="../assets/icons/ic_edit.png" (click)="openDriverModal(item);" placement="top" ngbTooltip="editar"/>
                      <!--
                      <img *ngIf="item.enabled" class="catalog-icon" src="../assets/icons/ic_enabled.png" (click)="active(item, false);" placement="top" ngbTooltip="deshabilitar"/>
                      <img *ngIf="!item.enabled" class="catalog-icon" src="../assets/icons/ic_disabled.png" (click)="active(item, true);" placement="top" ngbTooltip="habilitar"/>
                      <img *ngIf="false" class="catalog-icon" src="../assets/icons/ic_permissions.png" (click)="changePermissions(item);" placement="top" ngbTooltip="permisos"/>
                      !-->
                      <img *ngIf="!item.enabled" class="catalog-icon" src="../assets/icons/ic_delete.png" (click)="delete(item);" placement="top" ngbTooltip="eliminar"/>
                    </td>
                  </tr>
                  <tr *ngIf="(rows).length === 0">
                    <td colspan="6">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 6.2rem !important;">
          <div class="col-6">
            <pagination-template #p2="paginationApi" [id]="config2.id" (pageChange)="onPagerChanged($event)">

              <div class="pagination-previous" [class.disabled]="p2.isFirstPage()">
                  <a (click)="p2.previous()" *ngIf="!p2.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p2.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

              <div *ngFor="let page of p2.pages" class="page-number" [class.current]="p2.getCurrent() === page.value">
                  <a (click)="p2.setCurrent(page.value);" *ngIf="p2.getCurrent() !== page.value">
                      <span>{{ page.label }}</span>
                  </a>
                  <div *ngIf="p2.getCurrent() === page.value">
                      <span>{{ page.label }}</span>
                  </div>
              </div>

              <div class="pagination-next" [class.disabled]="p2.isLastPage()">
                  <a (click)="p2.next()" *ngIf="!p2.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p2.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

            </pagination-template>
          </div>
          <div class="col-lg-12 pager">
            <label>Mostrar <select [(ngModel)]="config2.itemsPerPage" (change)='onPagerChanged(1);'>
              <option *ngFor="let c of config2.options" [ngValue]="c">{{c}}</option>
            </select> Registros por página</label>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>

<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div *ngIf="successModal.hasData()" class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">{{ successModal.getData().title }}</h1>
        <p class="h6" style="font-size: 0.9rem;">{{ successModal.getData().subtitle }} </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #deleteModal identifier="deleteModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_warning.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¿Desea eliminar este registro?</h1>
        <p class="h6" style="font-size: 0.9rem;">Recuerda que será eliminado de la base de datos</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <button type="submit" (click)="deleteDriver(itemSelected.id)" class="btn btn-primary btn-user btn-block shadow-sm">SÍ, ELIMINAR<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
      <div class="col-4">
        <button type="cancel" (click)="deleteModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
      </div>
    </div>
  </div>
</ngx-smart-modal>