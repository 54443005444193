import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { MouseEvent } from '@agm/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbTabset, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../../environments/environment';
import { FormsService } from './../../../services/forms/forms.service';
import { SalesService } from './../../../services/sales/sales.service';
import { Sale } from './../Sale';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.css']
})
export class SaleComponent implements OnInit {
  @ViewChild('t') tabs:NgbTabset;
  public currentTab:number = 1;
  public saleSelected:Sale = new Sale();

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private saleService: SalesService, private toastService: ToastrService, public route:ActivatedRoute,
    @Inject(DOCUMENT) document,  private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if(params.id){
        this.saleSelected.id = +params['id'];
        this.getSale(this.saleSelected.id);
      }
    });
  }

  public getSale(id){
    console.log("getSale-->", id);
    this.spinner.show();
    this.saleService.getSale(id).subscribe(
      (res) => {
        console.log("Sale-res->", res);
        this.saleSelected = res;
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public beforeChange($event: NgbTabChangeEvent) {
    console.log("$event", $event);
    switch ($event.nextId) {
      case 'tab-selectbyid1':
        this.currentTab = 1;
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.remove("active");
            document.getElementById('tab-selectbyid3').classList.remove("active");
            document.getElementById('tab-selectbyid4').classList.remove("active");
            document.getElementById('tab-selectbyid5').classList.remove("active");
            document.getElementById('tab-selectbyid6').classList.remove("active");
          }, 100);
        break;
      case 'tab-selectbyid2':
        console.log("this.currentTab", this.currentTab);
        if(this.currentTab < 2 && !this.saleSelected.id){
          $event.preventDefault();
        }else{
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.add("active");
            document.getElementById('tab-selectbyid3').classList.remove("active");
            document.getElementById('tab-selectbyid4').classList.remove("active");
            document.getElementById('tab-selectbyid5').classList.remove("active");
            document.getElementById('tab-selectbyid6').classList.remove("active");
          }, 100);
          this.currentTab = 2;
        }
        break;
      case 'tab-selectbyid3':
        if(this.currentTab < 3 && !this.saleSelected.id){
          $event.preventDefault();
        }else{
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.add("active");
            document.getElementById('tab-selectbyid3').classList.add("active");
            document.getElementById('tab-selectbyid4').classList.remove("active");
            document.getElementById('tab-selectbyid5').classList.remove("active");
            document.getElementById('tab-selectbyid6').classList.remove("active");
          }, 100);
          this.currentTab = 3;
        }
        break;
      case 'tab-selectbyid4':
        if(this.currentTab < 4 && !this.saleSelected.id){
          $event.preventDefault();
        }else{
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.add("active");
            document.getElementById('tab-selectbyid3').classList.add("active");
            document.getElementById('tab-selectbyid4').classList.add("active");
            document.getElementById('tab-selectbyid5').classList.remove("active");
            document.getElementById('tab-selectbyid6').classList.remove("active");
          }, 100);
          this.currentTab = 4;
        }
        break;
      case 'tab-selectbyid5':
        if(this.currentTab < 5 && !this.saleSelected.id){
          $event.preventDefault();
        }else{
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.add("active");
            document.getElementById('tab-selectbyid3').classList.add("active");
            document.getElementById('tab-selectbyid4').classList.add("active");
            document.getElementById('tab-selectbyid5').classList.add("active");
            document.getElementById('tab-selectbyid6').classList.remove("active");
          }, 100);
          this.currentTab = 5;
        }
        break;
      case 'tab-selectbyid6':
        if(this.currentTab < 6 && !this.saleSelected.id){
          $event.preventDefault();
        }else{
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.add("active");
            document.getElementById('tab-selectbyid3').classList.add("active");
            document.getElementById('tab-selectbyid4').classList.add("active");
            document.getElementById('tab-selectbyid5').classList.add("active");
            document.getElementById('tab-selectbyid6').classList.add("active");
          }, 100);
          this.currentTab = 6;
        }
        break;
      default:
        // code...
        break;
    }
  }

  public continue($event){
    this.currentTab++;
    this.tabs.select('tab-selectbyid' + this.currentTab);
    console.log("tab", this.currentTab);
  }

  public updateSale($event){
    this.spinner.show();
    this.saleService.updateSale(this.saleSelected.id, $event).subscribe(
      (res) => {
        this.saleSelected = res;
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
        this.toastService.warning(err.error.message);
      }
    );
  }
  
}