<section class="container justify-content-center text-center p-0">
  <article class="row justify-content-center">
    <div class="col-12">
      <h4 class="catalog-title font-weight-bold text-dark mb-3">
        <img class="header-image" src="../assets/icons/ic_inventory.png"/>
        GESTIÓN DE INVENTARIO
      </h4>
      <div class="container">
        <div class="row mb-3" style="align-items: baseline;">
          <div class="col-lg-6 col-8">
            <div class="search-control">
              <i class="fa fa-search"></i>
              <input type="search" #searchInput [(ngModel)]="config.searchText" (search)="onSearch()" class="form-control input-search text-dark" placeholder="Buscar por producto"/>
            </div>
          </div>
          <div class="col-lg-3 col-1 text-right"></div>
          <div class="col-lg-3 col-3 text-right">
            <button type="submit" class="btn btn-primary btn-user btn-block shadow-sm" [routerLink]="['/inventory-all']"> <img src="../assets/icons/ic_inventory.png" style="width: 44px; margin-right: 4px;" /> Ver todo </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" style="width: 5%;"></th>
                    <th scope="col" class="text-left" style="width:85%;">PRODUCTO</th>
                    <th scope="col">ACCIONES</th>
                    <th scope="col" style="width: 10%;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rows | paginate: config">
                    <td style="width: 5%;"></td>
                    <td class="text-left" style="width: 85%;">{{item.name}}</td>
                    <td class="iconContainer" style="display: flex;">
                      <img class="catalog-icon" src="../assets/icons/ic_view.png" [routerLink]="['/inventory/product/', item.id]" placement="top" ngbTooltip="ver" style="width: 43px; height: 43px; margin-top: -2px;" />
                      <img class="catalog-icon" src="../assets/icons/ic_excel.png" (click)="onPrint(item.id);" placement="top" ngbTooltip="imprimir"/>
                    </td>
                    <td style="width: 10%;"></td>
                  </tr>
                  <tr *ngIf="(rows).length === 0">
                    <td colspan="6">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagerChanged($event)">

              <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                  <a (click)="p.previous()" *ngIf="!p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

              <div *ngFor="let page of p.pages" class="page-number" [class.current]="p.getCurrent() === page.value">
                  <a (click)="p.setCurrent(page.value);" *ngIf="p.getCurrent() !== page.value">
                      <span>{{ page.label }}</span>
                  </a>
                  <div *ngIf="p.getCurrent() === page.value">
                      <span>{{ page.label }}</span>
                  </div>
              </div>

              <div class="pagination-next" [class.disabled]="p.isLastPage()">
                  <a (click)="p.next()" *ngIf="!p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

            </pagination-template>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 pager">
            <label>Mostrar <select [(ngModel)]="config.itemsPerPage" (change)='onPagerChanged(1);'>
              <option *ngFor="let c of config.options" [ngValue]="c">{{c}}</option>
            </select> Registros por página</label>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>