import { Component, OnInit, ViewChild, OnChanges, SimpleChange, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MouseEvent } from '@agm/core';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../environments/environment';
import { FormsService } from './../../services/forms/forms.service';
import { UnitsService } from './../../services/units/units.service';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.css']
})
export class UnitsComponent implements OnInit, OnChanges {
  @ViewChild("table", { static: true }) table: any;
  @ViewChild('t') tabs:NgbTabset;
  @Input() product;
  public currentProduct:any = {id:0};

  public responseMessage:String;
  public currentTab:number = 1;
  public Unit: string = "unit";
  public Quality: string = "quality";
  public Tag: string = "tag";
  public Size: string = "size";
  public Presentation: string = "presentation";

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private unitsService: UnitsService, private toastService: ToastrService) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    let log: string[] = [];
    for (let propName in changes) {
      if(propName == 'product'){
        this.currentProduct = this.product;
      }
    }
  }

  public tabClicked(type){
    console.log("clicks!");
    console.log("currentProduct: ", this.currentProduct);
    if(type == this.Unit){
      this.currentTab = 1;
    }else if(type == this.Quality){
      this.currentTab = 2;
    }else if(type == this.Tag){
      this.currentTab = 3;
    }else if(type == this.Size){
      this.currentTab = 4;
    }else if(type == this.Presentation){
      this.currentTab = 5;
    }
  }

  public prevTab(){
    if(this.currentTab > 1){
      this.currentTab--;
      this.tabs.select('tab-selectbyid' + this.currentTab);
    }
  }

  public nextTab(){
    if(this.currentTab < 5){
      this.currentTab++;
      this.tabs.select('tab-selectbyid' + this.currentTab);
    }
  }
  
}