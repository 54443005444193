<section class="container justify-content-center text-center p-0">
  <article class="row justify-content-center">
    <div class="col-11">
      <h4 class="catalog-title font-weight-bold text-dark mb-3">
        <img class="header-image" src="../assets/icons/ic_product_purchases.png"/>
      COMPRA DE PRODUCTOS</h4>
      <div class="container">
        <div class="row mb-5" style="align-items: baseline;">
          <div class="col-lg-6 col-8">
            <div class="search-control">
              <i class="fa fa-search"></i>
              <input type="search" #searchInput [(ngModel)]="config.searchText" (search)="onSearch()" class="form-control input-search text-dark" placeholder="Buscar por orden de compra (O.C.), proveedor, viaje"/>              
            </div>
          </div>
          <div class="col-lg-6 col-4 text-right">
            <div class="catalog-btn" >
              <img src="../assets/icons/ic_plus.png" [routerLink]="['/order']" />
              <label [routerLink]="['/order']">NUEVA COMPRA</label>
            </div>
          </div>
        </div>
        <div class="row" style="width: 120%; left: 0px; margin-left: -10%;">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">O.C.</th>
                    <th scope="col">FECHA DE COMPRA</th>
                    <th scope="col">FECHA DE LLEGADA</th>
                    <th scope="col">PROVEEDOR</th>
                    <th scope="col">VIAJE</th>
                    <th scope="col">LÍNEA DE TRANSPORTE</th>
                    <th scope="col">CHOFER</th>
                    <!--
                    <th scope="col">GASTO LÍNEA TRANSPORTE</th>
                    <th scope="col">GASTOS ADICIONALES</th>
                    !-->
                    <th scope="col">CANTIDAD</th>
                    <th scope="col">IMPORTE</th>
                    <th scope="col">ACCIONES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rows | paginate: config">
                    <td>{{item.id}}</td>
                    <td>{{item.purchaseDate}}</td>
                    <td>{{item.arrivalDate}}</td>
                    <td>{{item.provider? item.provider.businessName: ''}}</td>
                    <td>{{item.trip}}</td>
                    <td>{{item.transportLines? item.transportLines.businessName: ''}}</td>
                    <td>{{item.driver? item.driver.name + ' ' + item.driver.firstSurname: ''}}</td>
                    <!--
                    <td>{{item.transportLinesExpense | currency }}</td>
                    <td>{{item.additionalCosts | currency }}</td>
                    !-->
                    <td>{{item.quantity}}</td>
                    <td>{{item.amount | currency }}</td>
                    <td class="iconContainer justify-content-center" style="display: flex;">
                      <img class="catalog-icon" src="../assets/icons/ic_edit.png" [routerLink]="[item.id]" placement="top" ngbTooltip="editar"/>
                      <!--
                      <img *ngIf="item.enabled" class="catalog-icon" src="../assets/icons/ic_enabled.png" (click)="active(item, false);" placement="top" ngbTooltip="deshabilitar"/>
                      <img *ngIf="!item.enabled" class="catalog-icon" src="../assets/icons/ic_disabled.png" (click)="active(item, true);" placement="top" ngbTooltip="habilitar"/>
                      !-->
                      <img *ngIf="!item.enabled" class="catalog-icon" src="../assets/icons/ic_delete.png" (click)="delete(item);" placement="top" ngbTooltip="eliminar"/>
                    </td>
                  </tr>
                  <tr *ngIf="(rows).length === 0">
                    <td colspan="6">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagerChanged($event)">

              <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                  <a (click)="p.previous()" *ngIf="!p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

              <div *ngFor="let page of p.pages" class="page-number" [class.current]="p.getCurrent() === page.value">
                  <a (click)="p.setCurrent(page.value);" *ngIf="p.getCurrent() !== page.value">
                      <span>{{ page.label }}</span>
                  </a>
                  <div *ngIf="p.getCurrent() === page.value">
                      <span>{{ page.label }}</span>
                  </div>
              </div>

              <div class="pagination-next" [class.disabled]="p.isLastPage()">
                  <a (click)="p.next()" *ngIf="!p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

            </pagination-template>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 pager">
            <label>Mostrar <select [(ngModel)]="config.itemsPerPage" (change)='onPagerChanged(1);'>
              <option *ngFor="let c of config.options" [ngValue]="c">{{c}}</option>
            </select> Registros por página</label>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>

<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div *ngIf="successModal.hasData()" class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">{{ successModal.getData().title }}</h1>
        <p class="h6" style="font-size: 0.9rem;">{{ successModal.getData().subtitle }} </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #deleteModal identifier="deleteModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_warning.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¿Desea eliminar este registro?</h1>
        <p class="h6" style="font-size: 0.9rem;">Recuerda que será eliminado de la base de datos</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <button type="submit" (click)="deletePurchase(itemSelected.id)" class="btn btn-primary btn-user btn-block shadow-sm">SÍ, ELIMINAR<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
      <div class="col-4">
        <button type="cancel" (click)="deleteModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
      </div>
    </div>
  </div>
</ngx-smart-modal>