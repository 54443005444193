import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class PurchasesService {
	private PATH_PURCHASES:String = "/purchaseProducts/";
  private PATH_PRODUCTS:String = "/productsOrder/";
  private PATH_OBSERVATION:String = "/observation/";
  private PATH_TRANSPORT_COSTS:String = "/expenseRecordsTransportLine/";
  private PATH_ADDITIONAL_COSTS:String = "/expenseRecordsAdditional/";

  constructor(private http: HttpClient) {}

  getPurchases() {
      return this.http.get<any>(environment.apiUrl + this.PATH_PURCHASES, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PURCHASES, data),
          error => throwError(error)
        )
      );
  }

  searchPurchases(config) {
    var search = JSON.stringify(
      {"$or": [
        {"id": {"$cont": config.searchText}},
        /*{"product.name": {"$cont": config.searchText}},*/
        {"provider.businessName": {"$cont": config.searchText}},
        {"trip": {"$cont": config.searchText}},
      ]}
    );

    var sort = "&sort=id,DESC";

    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));
    
    return this.http.get<any>(environment.apiUrl + this.PATH_PURCHASES + '?join=provider&join=transportLines.drivers&page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search + sort, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PURCHASES, data),
          error => throwError(error)
        )
      );
  }

  addPurchase(obj) {
      return this.http.post<any>(environment.apiUrl + this.PATH_PURCHASES, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PURCHASES, data),
          error => throwError(error)
        )
      );
  }

  updatePurchase(id, obj) {
    return this.http.patch<any>(environment.apiUrl + this.PATH_PURCHASES + id, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PURCHASES, data),
          error => throwError(error)
        )
      );
  }

  getPurchase(id) {
      return this.http.get<any>(environment.apiUrl + this.PATH_PURCHASES + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PURCHASES, data),
          error => throwError(error)
        )
      );
  }

  deletePurchase(id) {
    return this.http.delete<any>(environment.apiUrl + this.PATH_PURCHASES + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PURCHASES, data),
          error => throwError(error)
        )
      );
  }


  /* Products */
  getProducts() {
      return this.http.get<any>(environment.apiUrl + this.PATH_PRODUCTS, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PRODUCTS, data),
          error => throwError(error)
        )
      );
  }

  searchProducts(purchaseId, config) {
    var search = JSON.stringify(
      {"$and": [
        {"$or": [
          {"id": {"$cont": config.searchText}},
          {"product.description": {"$cont": config.searchText}},
          {"product.name": {"$cont": config.searchText}},
        ]},
        {"purchaseProducts.id": {"$eq": purchaseId}}
      ]}
    );

    var sort = "&sort=id,DESC";

    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));
    
    return this.http.get<any>(environment.apiUrl + this.PATH_PRODUCTS + '?join=product&page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search + sort, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PRODUCTS, data),
          error => throwError(error)
        )
      );
  }

  addProduct(obj) {
      return this.http.post<any>(environment.apiUrl + this.PATH_PRODUCTS, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PRODUCTS, data),
          error => throwError(error)
        )
      );
  }

  updateProduct(id, obj) {
    return this.http.patch<any>(environment.apiUrl + this.PATH_PRODUCTS + id, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PRODUCTS, data),
          error => throwError(error)
        )
      );
  }

  getProduct(id) {
      return this.http.get<any>(environment.apiUrl + this.PATH_PRODUCTS + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PRODUCTS, data),
          error => throwError(error)
        )
      );
  }

  deleteProduct(id) {
    return this.http.delete<any>(environment.apiUrl + this.PATH_PRODUCTS + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PRODUCTS, data),
          error => throwError(error)
        )
      );
  }


  /* Products */
  getObservations() {
      return this.http.get<any>(environment.apiUrl + this.PATH_OBSERVATION, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  searchObservations(purchaseId, config) {
    var search = JSON.stringify(
      {"$and": [
        {"$or": [
          {"id": {"$cont": config.searchText}},
          {"observation": {"$cont": config.searchText}},
        ]},
        {"purchaseProducts.id": {"$eq": purchaseId}}
      ]}
    );

    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));
    
    return this.http.get<any>(environment.apiUrl + this.PATH_OBSERVATION + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  updateObservation(id, obj) {
    return this.http.patch<any>(environment.apiUrl + this.PATH_OBSERVATION + id, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  getObservation(id) {
      return this.http.get<any>(environment.apiUrl + this.PATH_OBSERVATION + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  deleteObservation(id) {
    return this.http.delete<any>(environment.apiUrl + this.PATH_OBSERVATION + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  addObservation(obj) {
      return this.http.post<any>(environment.apiUrl + this.PATH_OBSERVATION, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }


  /* Transport Costs */
  getTransportCosts(purchaseId) {
    var search = JSON.stringify(
      {"purchaseProducts.id": {"$eq": purchaseId}}
    );

    return this.http.get<any>(environment.apiUrl + this.PATH_TRANSPORT_COSTS + '?s=' + search, httpOptions)
      .pipe(tap( // Log the result or error
        data => console.log(this.PATH_TRANSPORT_COSTS, data),
        error => throwError(error)
      )
    );
  }

  searchTransportCosts(purchaseId, config) {
    var search = JSON.stringify(
      {"$and": [
        {"$or": [
          {"id": {"$cont": config.searchText}},
          {"observation": {"$cont": config.searchText}},
        ]},
        {"purchaseProducts.id": {"$eq": purchaseId}}
      ]}
    );

    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));
    
    return this.http.get<any>(environment.apiUrl + this.PATH_TRANSPORT_COSTS + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_TRANSPORT_COSTS, data),
          error => throwError(error)
        )
      );
  }

  updateTransportCost(id, obj) {
    return this.http.patch<any>(environment.apiUrl + this.PATH_TRANSPORT_COSTS + id, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_TRANSPORT_COSTS, data),
          error => throwError(error)
        )
      );
  }

  getTransportCost(id) {
      return this.http.get<any>(environment.apiUrl + this.PATH_TRANSPORT_COSTS + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_TRANSPORT_COSTS, data),
          error => throwError(error)
        )
      );
  }

  deleteTransportCost(id) {
    return this.http.delete<any>(environment.apiUrl + this.PATH_TRANSPORT_COSTS + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_TRANSPORT_COSTS, data),
          error => throwError(error)
        )
      );
  }

  addTransportCost(obj) {
      return this.http.post<any>(environment.apiUrl + this.PATH_TRANSPORT_COSTS, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_TRANSPORT_COSTS, data),
          error => throwError(error)
        )
      );
  }


  /* Additional Costs */
  getAdditionalCosts(purchaseId) {
    var search = JSON.stringify(
      {"purchaseProducts.id": {"$eq": purchaseId}}
    );
      return this.http.get<any>(environment.apiUrl + this.PATH_ADDITIONAL_COSTS + '?s=' + search, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_ADDITIONAL_COSTS, data),
          error => throwError(error)
        )
      );
  }

  searchAdditionalCosts(purchaseId, config) {
    var search = JSON.stringify(
      {"$and": [
        {"$or": [
          {"id": {"$cont": config.searchText}},
          {"observation": {"$cont": config.searchText}},
        ]},
        {"purchaseProducts.id": {"$eq": purchaseId}}
      ]}
    );

    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));
    
    return this.http.get<any>(environment.apiUrl + this.PATH_ADDITIONAL_COSTS + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_ADDITIONAL_COSTS, data),
          error => throwError(error)
        )
      );
  }

  updateAdditionalCost(id, obj) {
    return this.http.patch<any>(environment.apiUrl + this.PATH_ADDITIONAL_COSTS + id, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_ADDITIONAL_COSTS, data),
          error => throwError(error)
        )
      );
  }

  getAdditionalCost(id) {
      return this.http.get<any>(environment.apiUrl + this.PATH_ADDITIONAL_COSTS + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_ADDITIONAL_COSTS, data),
          error => throwError(error)
        )
      );
  }

  deleteAdditionalCost(id) {
    return this.http.delete<any>(environment.apiUrl + this.PATH_ADDITIONAL_COSTS + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_ADDITIONAL_COSTS, data),
          error => throwError(error)
        )
      );
  }

  addAdditionalCost(obj) {
      return this.http.post<any>(environment.apiUrl + this.PATH_ADDITIONAL_COSTS, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_ADDITIONAL_COSTS, data),
          error => throwError(error)
        )
      );
  }

}