import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { ReportsService } from './../../../../services/reports/reports.service';
import { ExcelsService } from './../../../../services/excels/excels.service';
import { AdminService } from './../../../../services/admin/admin.service';
import locale from '../../../../../assets/json/locale.json';
declare var $: any;

@Component({
  selector: 'app-graph-discounts-details',
  templateUrl: './graph-discounts-details.component.html',
  styleUrls: ['./graph-discounts-details.component.css']
})
export class GraphDiscountsDetailsComponent implements OnInit {
  public config = {
    id: 'discounts-details',
    searchText: '',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    startDate: null,
    endDate: null,
    clientId: 0,
    options: [10, 20, 50, 100],
    order: {
      field: 'id',
      reverse: false
    }
  };
  public rows: [] = [];
  public clients: any[] = [];
  public summary = {
    totalAmount: 0
  }

	constructor(private datePipe: DatePipe, private spinner: NgxSpinnerService, 
    private filterPipe: FilterPipe, private reportsService: ReportsService,
    private adminService: AdminService, private excelsService: ExcelsService ) { 
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.config.startDate = firstDay;
      this.config.endDate = lastDay;
  }

	ngOnInit(): void {
    $("input[name='range-discounts-details']").daterangepicker({
      showDropdowns: true,
      "locale": locale,
      startDate: this.datePipe.transform(this.config.startDate, 'dd/MM/yyyy'),
      endDate: this.datePipe.transform(this.config.endDate, 'dd/MM/yyyy'),
    }, function(start, end, label) {
      console.log("A new date selection was made: " + this.datePipe.transform(start, 'dd/MM/yyyy') + ' to ' + this.datePipe.transform(end, 'dd/MM/yyyy'));
      this.config.startDate = start;
      this.config.endDate = end;
      this.getReport();
    }.bind(this));
    this.populateClients();
  }

  public populateClients(){
    this.spinner.show();
    this.adminService.getClients().subscribe(
      res => {
        this.clients = res;
        setTimeout(()=>{
          $("#clients-discounts-details").selectpicker("refresh");
        }, 100);
        this.getReport();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public getReport(){
    console.log("getReport-->");
    this.spinner.show();
    this.reportsService.getReporteDescuentosDetalle(this.config).subscribe(
      (res) => {
        console.log("report-res->", res);
        this.rows = res.list;
        this.summary.totalAmount = res.totalAmount;
        this.config.totalItems = res.totalItems;
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public getExcel(){
    console.log("getExcel-->");
    this.spinner.show();
    this.excelsService.getReporteDescuentosPorCliente(this.config).subscribe(
      (res) => {
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public onClientChanged(value){
    console.log("value", value);
    this.config.clientId = value;
    this.config.currentPage = 1;
    this.getReport();
  }

  public onPagerChanged($event){
    if(!isNaN($event)){
      this.config.currentPage = $event;
      this.getReport();
    }
  }
  
}