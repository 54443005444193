import { Component, OnInit, ViewChild, OnChanges, SimpleChange, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MouseEvent } from '@agm/core';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../../environments/environment';
import { FormsService } from './../../../services/forms/forms.service';
import { UnitsService } from './../../../services/units/units.service';

@Component({
  selector: 'app-units-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.css']
})
export class UnitsPresentationComponent implements OnInit, OnChanges {
  @ViewChild("table", { static: true }) table: any;
  @ViewChild('t') tabs:NgbTabset;
  @Input() product;
  public currentProduct:any = {id:0};

  public Unit: string = "presentation";

  public formPresentation:FormGroup;
  public itemSelected: any = {};
  public responseMessage:String;

  //table, pagination
  public config = {
    id: 'employees',
    searchText: '',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100]
  };
  public rows: any = [];
  public unitList: any = [];

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private unitsService: UnitsService, private toastService: ToastrService) { }

  ngOnInit(): void {
    this.formPresentation = this.formBuilder.group({
      name: ['', [Validators.required]],
      shortname: ['', [Validators.required]]
    });
    this.populateUnit();
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    let log: string[] = [];
    for (let propName in changes) {
      if(propName == 'product'){
        this.currentProduct = this.product;
        this.populateUnit();
        this.getUnitsList();
      }
    }
  }

  public populateUnit(){
    this.spinner.show();
    this.unitsService.searchUnitList(this.currentProduct.id, this.Unit, this.config).subscribe(
      res => {
        this.rows = res.data;
        this.config.totalItems = res.total;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public getUnitsList(){
    if(this.currentProduct.id > 0){
      this.spinner.show();
      this.unitsService.getUnitList(this.currentProduct.id, "unit").subscribe(
        res => {
          this.unitList = res;
          this.config.totalItems = res.total;
          this.spinner.hide();
        },
        err => {
          console.log("error ->", err);
          this.spinner.hide();
        }
      );
    }
  }

  public submitUnit(id){
    console.log("ID-->", id);
    if(id === undefined){
      this.addUnit();
    }else{
      this.updateUnit(id);
    }
  }

  public addUnit(){
    console.log("addUnit-->");
    this.config.searchText = "";
    this.config.currentPage = 1;

    if (!this.formPresentation.valid) {
       this.formsService.validateAllFormFields(this.formPresentation);
    }else {
      this.spinner.show();
      this.formPresentation.value['product'] = [ {id: this.currentProduct.id} ];
      this.unitsService.addUnit(this.formPresentation.value, this.Unit).subscribe(
        (res) => {
          this.modalService.getModal('PresentationModal').close();
          this.openSuccessModal("!Presentación agregada!", "Su registro ha sido guardado exitosamente.");
          this.clearForm();
          this.populateUnit();
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public updateUnit(id){
    console.log("updateUnit-->");
    if (!this.formPresentation.valid) {
       this.formsService.validateAllFormFields(this.formPresentation);
    }else {
      this.spinner.show();
      this.unitsService.updateUnit(id, this.formPresentation.value).subscribe(
        (res) => {
          this.modalService.getModal('PresentationModal').close();
          this.openSuccessModal("!Presentación actualizada!", "Su registro ha sido actualizado exitosamente.");
          this.clearForm();
          this.populateUnit();
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public clearForm(){
    this.formPresentation.reset();
    this.itemSelected = {};
  }

  // actions
  edit(item) {
    this.itemSelected = item;
    this.openPresentationModal(item);
  }

  public delete(id){
    console.log("delete-->", id);
    this.spinner.show();
    this.unitsService.deleteUnit(id).subscribe(
      (res) => {
        this.openSuccessModal("!Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.populateUnit();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.responseMessage = err.error.message;
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  active(item, enabled){
    this.itemSelected = item;
    this.openSuccessModal("¡Activado exitosamente!", "");
    /*
    this.jobRequestService
      .updatePersonalinformation({ enabled: true }, item.id)
      .subscribe(
        res => {
          this.toast.success("Activado", "Usuario");
          this.ngOnInit();
        },
        err => {
          console.log("error ->", err);
        }
      );
      */
  }

  changePermissions(item){

  }

  public onSearch(){
    this.config.currentPage = 1;
    this.populateUnit();
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
    this.populateUnit();
  }

  //modals
  public openPresentationModal(item = null){
    this.formPresentation.reset();
    this.itemSelected = {};
    if(item){ //editing
      this.itemSelected = item;
      this.formPresentation.patchValue(item);
    }
    this.modalService.getModal('PresentationModal').open();
  }

  //modals
  public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal');
    this.modalService.setModalData(obj, 'successModal');
    this.modalService.getModal('successModal').open();
  }
  
}