import { Component, OnInit, ViewChild, Input, Inject, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MouseEvent } from '@agm/core';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from './../../../../services/inventory/inventory.service';
import { SalesService } from './../../../../services/sales/sales.service';
declare var $: any;

@Component({
  selector: 'app-inventory-sale',
  templateUrl: './inventory-sale.component.html',
  styleUrls: ['./inventory-sale.component.css']
})
export class InventorySaleComponent implements OnInit {
  @Output() updateSale2 = new EventEmitter<any>();
  @Input() saleSelected;
  public productsSelected:{} = [];

  //table, pagination
  public rows: any = [];

  config = {
    id: 'product-inventory',
    searchText: '',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100],
    filter: { 
      id: '',
      purchaseDate: '',
      trip: '',
      tag: '',
      presentation: '',
      units: ''
    },
    order: {
      field: 'id',
      reverse: false
    }
  };

  constructor(private formBuilder: FormBuilder, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, 
    private inventoryService:InventoryService, private toastService: ToastrService, public route:ActivatedRoute,
    @Inject(DOCUMENT) document, private salesService:SalesService) { }

  ngOnInit(): void {
    this.getProductInventory();
  }

  public getProductInventory(){
    this.spinner.show();
    this.inventoryService.searchSalesInventory(this.saleSelected.id, this.config).subscribe(
      res => {
        this.rows = res;
        var i = 0;
        for (var row of this.rows) {
          if(this.productsSelected[row.uniqueProduct]){
            var product = this.productsSelected[row.uniqueProduct];
            this.rows[i]['customQuantity'] = product.quantity;
            this.rows[i]['customPrice'] = product.price;

          }else if(row['productsSelected']){
            this.rows[i]['customQuantity'] = row.productsSelected.quantity;
            this.rows[i]['customPrice'] = row.productsSelected.price;

            this.productsSelected[row.uniqueProduct] = {
              "size": row.sizeId,
              "uniqueProduct": row.uniqueProduct,
              "quantity": row.productsSelected.quantity,
              "price": row.productsSelected.price,
              "order": this.saleSelected.id
            }
          }else{
            this.rows[i]['customQuantity'] = 0;
            this.rows[i]['customPrice'] = row.unitPrice;
          }
          i++;
        }
        this.spinner.hide();
        setTimeout(()=>{
          try{
            $(".input-spinner").inputSpinner();
          }catch(e){
            console.log("e:", e);
          }
        }, 100);
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public onItemChanged(item){
    var id = item.uniqueProduct;
    this.productsSelected[id] = {
      "size": item.sizeId,
      "uniqueProduct": id,
      "quantity": item.customQuantity,
      "price": item.customPrice,
      "order": this.saleSelected.id
    };
  }

  public submitInventory(){
    const products = Object.entries(this.productsSelected).map(
      ([Label, content]) => (content)
    );

    console.log("submitInventory-->", products);
    this.salesService.updateInventory(products).subscribe(
      (res) => {
        this.updateSale2.next(this.saleSelected);
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  public onSearch(searchText){
    this.spinner.show();
    this.config.searchText = searchText;
    this.config.currentPage = 1;
    setTimeout(()=>{
      try{
        $(".input-spinner:only-child").inputSpinner();
        this.spinner.hide();
      }catch(e){
        console.log("e:", e);
      }
    }, 100);
  }

  public onSort(field){
    this.spinner.show();
    this.config.order.field = field;
    this.config.currentPage = 1;
    setTimeout(()=>{
      try{
        $(".input-spinner:only-child").inputSpinner();
        this.spinner.hide();
      }catch(e){
        console.log("e:", e);
      }
    }, 100);
  }

  public onPagerChanged(page){
    this.spinner.show();
    this.config.currentPage = page;
    setTimeout(()=>{
      try{
        $(".input-spinner:only-child").inputSpinner();
        this.spinner.hide();
      }catch(e){
        console.log("e:", e);
      }
    }, 100);
  }

}