import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { 
  RoleGuardService as RoleGuard 
} from './services/auth/role-guard.service';

import { HomeComponent } from './screens/home/home.component';
import { LoginComponent } from './screens/login/login.component';
import { CompanyComponent } from './screens/company/company.component';
import { EmployeesComponent } from './screens/employees/employees.component';
import { ProvidersComponent } from './screens/providers/providers.component';
import { SeasonsComponent } from './screens/providers/seasons/seasons.component';
import { ClientsComponent } from './screens/clients/clients.component';
import { TransportLinesComponent } from './screens/transportlines/transportlines.component';
import { UnitsComponent } from './screens/units/units.component';
import { ProductsComponent } from './screens/products/products.component';
import { PurchasesComponent } from './screens/purchases/purchases.component';
import { OrderComponent } from './screens/order/order.component';
import { InventoryComponent } from './screens/inventory/inventory.component';
import { ProductInventoryComponent } from './screens/inventory/product/product.component';
import { SalesComponent } from './screens/sales/sales.component';
import { SaleComponent } from './screens/sales/sale/sale.component';
import { LossesComponent } from './screens/losses/losses.component';
import { LostComponent } from './screens/losses/lost/lost.component';
import { ReportsComponent } from './screens/reports/reports.component';
import { InventoryAllComponent } from './screens/inventory/inventory-all/inventory-all.component';

const routes: Routes = [
  { path: 'reports/analitico', component: ReportsComponent,
    canActivate: [RoleGuard], data: { 
      expectedRole: [3]
    }
  },
  { path: 'reports/compras', component: ReportsComponent,
    canActivate: [RoleGuard], data: { 
      expectedRole: [3]
    }
  },
  { path: 'reports/ventas', component: ReportsComponent,
    canActivate: [RoleGuard], data: { 
      expectedRole: [3]
    }
  },
  { path: 'reports/descuentos', component: ReportsComponent,
    canActivate: [RoleGuard], data: { 
      expectedRole: [3]
    }
  },
  { path: 'reports/lineas-transporte', component: ReportsComponent,
    canActivate: [RoleGuard], data: { 
      expectedRole: [3]
    }
  },
  { path: 'reports', component: ReportsComponent,
    canActivate: [RoleGuard], data: { 
      expectedRole: [3]
    }
  },
  { path: 'losses/:id', component: LostComponent},
  { path: 'losses/lost', component: LostComponent},
  { path: 'losses', component: LossesComponent},
  { path: 'inventory-all', component: InventoryAllComponent},
  { path: 'inventory/product/:id', component: ProductInventoryComponent},
  { path: 'inventory', component: InventoryComponent},
  { path: 'order', component: OrderComponent},
  { path: 'sales/:id', component: SaleComponent},
  { path: 'sales/sale', component: SaleComponent},
  { path: 'sales', component: SalesComponent},
  { path: 'purchases/:id', component: OrderComponent},
  { path: 'purchases', component: PurchasesComponent},
  { path: 'admin/products', component: ProductsComponent, 
    canActivate: [RoleGuard], data: { 
      expectedRole: [1, 3]
    } 
  },
  { path: 'admin/units', component: UnitsComponent, 
    canActivate: [RoleGuard], data: { 
      expectedRole: [1, 3]
    }
  },
  { path: 'admin/transport-lines', component: TransportLinesComponent,
    canActivate: [RoleGuard], data: { 
      expectedRole: [1, 3]
    }
  },
  { path: 'admin/clients', component: ClientsComponent,
    canActivate: [RoleGuard], data: { 
      expectedRole: [1, 3]
    }
  },
  { path: 'admin/providers', component: ProvidersComponent,
    canActivate: [RoleGuard], data: { 
      expectedRole: [1, 3]
    }
  },
  { path: 'admin/providers/:providerId/seasons', component: SeasonsComponent,
    canActivate: [RoleGuard], data: { 
      expectedRole: [1, 3]
    }
  },
  { path: 'admin/employees', component: EmployeesComponent, 
    canActivate: [RoleGuard], data: { 
      expectedRole: [1, 3]
    }
  },
  { path: 'admin/company', component: CompanyComponent, 
    canActivate: [RoleGuard], data: { 
      expectedRole: [1, 3]
    }
  },
  { path: 'admin', component: CompanyComponent,
    canActivate: [RoleGuard], data: { 
      expectedRole: [1, 3]
    }
  },
  { path: 'login', component: LoginComponent },
  { path: '', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, 
  {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }