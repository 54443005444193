import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { ReportsService } from './../../../../services/reports/reports.service';
import { ExcelsService } from './../../../../services/excels/excels.service';
import { ChartDataSets, ChartOptions, ChartType, Chart } from 'chart.js';
import { Label } from 'ng2-charts';
import locale from '../../../../../assets/json/locale.json';

declare var $: any;

@Component({
  selector: 'app-graph-orders-monthly',
  templateUrl: './graph-orders-monthly.component.html',
  styleUrls: ['./graph-orders-monthly.component.css']
})
export class GraphOrdersMonthlyComponent implements OnInit {
  public chart: any = null;
  public config = {
    id: 'orders-montly',
    searchText: '',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    startDate: null,
    endDate: null,
    options: [10, 20, 50, 100],
    order: {
      field: 'id',
      reverse: false
    }
  };
  public summary = {
    totalAmount: 0
  }
  
	constructor(private datePipe: DatePipe, private spinner: NgxSpinnerService, 
    private filterPipe: FilterPipe, private reportsService: ReportsService, private excelsService: ExcelsService) { 
    var date = new Date();
    var firstDay = new Date(date.getFullYear() - 1, date.getMonth(), date.getDay());
    var lastDay = new Date();
    this.config.startDate = firstDay;
    this.config.endDate = lastDay;
  }

	ngOnInit(): void {
    $("input[name='range-orders-montly']").daterangepicker({
      showDropdowns: true,
      "locale": locale,
      startDate: this.datePipe.transform(this.config.startDate, 'dd/MM/yyyy'),
      endDate: this.datePipe.transform(this.config.endDate, 'dd/MM/yyyy'),
    }, function(start, end, label) {
      console.log("A new date selection was made: " + this.datePipe.transform(start, 'dd/MM/yyyy') + ' to ' + this.datePipe.transform(end, 'dd/MM/yyyy'));
      this.config.startDate = start;
      this.config.endDate = end;
      this.getGraph();
    }.bind(this));
    this.getGraph();
  }

  public getGraph(){
    console.log("getGraph-->");
    this.spinner.show();
    this.reportsService.getGraphCompras(this.config).subscribe(
      (res) => {
        console.log("graph-res->", res);
        this.summary.totalAmount = res.totalAmount;
        var data = res.values.map(function(value) {
            return value.data.toFixed(2);
        });
        this.setGraph(res.labels, data);
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public setGraph(labels, data){
    if(this.chart){
      this.chart.data.labels = labels;
      this.chart.data.datasets = [{
        label: 'Compras',
        data: data,
        backgroundColor: "#8bde5e"
      }];
      this.chart.update();
      this.saveImage();
      return;
    }
    this.chart = new Chart('baseChart', {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'Compras',
          data: data,
          backgroundColor: "#8bde5e"
        }]
      },
      options: {
        legend: { display: false },
        scales:  { xAxes: [{}], yAxes: [{ ticks: { 
            beginAtZero: true,
            callback: function(value:any, index, values) {
              if(parseInt(value) >= 1000){
                return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return '$' + value;
              }
            }
        } }] },
        tooltips: {
          callbacks: {
            label: function(tooltipItem:any, data) {
                if(parseInt(tooltipItem.yLabel) >= 1000){
                  return '$' + tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return '$' + tooltipItem.yLabel;
                }
            }
          }
        }
      }
    });
    setTimeout(()=>{
      this.saveImage();
    }, 100);
  }

  public saveImage(){
    var canvas = $("#baseChart").get(0);
    var dataURL = canvas.toDataURL();
    $("#fakeChart").attr("src", dataURL);
  }

  public getExcel(){
    console.log("getExcel-->");
    this.spinner.show();
    this.excelsService.getGraphCompras(this.config).subscribe(
      (res) => {
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
  }
  
}