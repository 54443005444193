import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MouseEvent } from '@agm/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../environments/environment';
import { FormsService } from './../../services/forms/forms.service';
import { LossesService } from './../../services/losses/losses.service';
import { Lost } from './Lost';

@Component({
  selector: 'app-losses',
  templateUrl: './losses.component.html',
  styleUrls: ['./losses.component.css']
})
export class LossesComponent implements OnInit {  
  public itemSelected: any = {};

  //table, pagination
  public config = {
    id: 'losses',
    searchText: '',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100]
  };
  public rows: any = [];
  public responseMessage:String;

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, private router: Router,
    public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, 
    private filterPipe: FilterPipe, private lossesService: LossesService, private toastService: ToastrService) {
  }

  ngOnInit(): void {
    this.populateLosses();
  }

  public populateLosses(){
    this.spinner.show();
    this.lossesService.searchLosses(this.config).subscribe(
      res => {
        this.rows = res.data;
        this.config.totalItems = res.total;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  delete(item) {
    this.itemSelected = item;
    this.modalService.open("deleteModal");
  }

  public deleteLost(id){
    console.log("deleteLost-->", id);
    this.spinner.show();
    this.lossesService.deleteLost(id).subscribe(
      (res) => {
        this.modalService.getModal('deleteModal').close();
        this.openSuccessModal("¡Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.populateLosses();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.responseMessage = err.error.message;
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  public createLost(){
    this.spinner.show();
    let emptyLost = new Lost();
   
    this.lossesService.addLost(emptyLost).subscribe(
      (res) => {
        this.router.navigate(['losses', res.id]);
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
        this.toastService.warning(err.error.message);
      }
    );
  }

  public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal');
    this.modalService.setModalData(obj, 'successModal');
    this.modalService.getModal('successModal').open();
  }

  public onSearch(){
    this.config.currentPage = 1;
    this.populateLosses();
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
    this.populateLosses();
  }

}