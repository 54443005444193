import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from './../../../app.component';
import { environment } from './../../../../environments/environment';
import { FormsService } from './../../../services/forms/forms.service';
import { AdminService } from './../../../services/admin/admin.service';
import { PurchasesService } from './../../../services/purchases/purchases.service';
declare var $: any;

@Component({
  selector: 'app-provider-order',
  templateUrl: './provider-order.component.html',
  styleUrls: ['./provider-order.component.css']
})
export class ProviderOrderComponent implements OnInit, OnChanges {
  @Output() continue = new EventEmitter<string>();
  @Output() createOrder = new EventEmitter<string>();
  @Input() orderSelected;
  public currentSeason: any = {};

  public formProviderInfo:FormGroup;
  public providers: any[] = [];

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService,
    private spinner: NgxSpinnerService, private toastService: ToastrService, private adminService: AdminService ,
    private orderService: PurchasesService, private router: Router) {
    this.formProviderInfo = this.formBuilder.group({
      provider: ['', [Validators.required]],
      trip: ['', [Validators.required]],
      purchaseDate: ['', [Validators.required]],
      arrivalDate: ['', [Validators.required]],
    });
    this.formProviderInfo.controls['trip'].disable();
  }

  ngOnInit(): void {
  	this.populateProviders();
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    let log: string[] = [];
    for (let propName in changes) {
      if(propName == 'orderSelected'){
        console.log("orderSelected --> ", this.orderSelected);
        this.formProviderInfo.patchValue(this.orderSelected);
        if(this.orderSelected.id){
          this.formProviderInfo.controls['provider'].disable();
        }
      }
    }
  }

  public populateProviders(){
    this.spinner.show();
    this.adminService.getProviders().subscribe(
      res => {
        this.providers = res;
        setTimeout(()=>{
          this.formProviderInfo.controls['provider'].patchValue(this.orderSelected.provider.id);
          $("#provider").val(this.orderSelected.provider.id).selectpicker("refresh");
        }, 100);
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public submitProvider(){
    if (!this.formProviderInfo.valid) {
       this.formsService.validateAllFormFields(this.formProviderInfo);
    }else {
      if(this.orderSelected.id > 0){
        this.updateOrder();
      }else{
        console.log("form: ", this.formProviderInfo.value);
        console.log("form-->: ", this.formProviderInfo.controls['trip'].value);
        this.formProviderInfo.value['trip'] = this.formProviderInfo.controls['trip'].value;
        this.createOrder.next(this.formProviderInfo.value);
      }
    }
  }

  public updateOrder(){
    this.spinner.show();
    this.orderService.updatePurchase(this.orderSelected.id, this.formProviderInfo.value).subscribe(
      (res) => {
        this.orderSelected = res;
        this.spinner.hide();
        this.continue.next();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
        this.toastService.warning(err.error.message);
      }
    );
  }

  public onProviderChanged(value){
    console.log("value", value);
    this.orderSelected.provider = value;
    if(value){
      this.getCurrentSeason(value);
    }
  }

  public getCurrentSeason(providerId){
    this.spinner.show();
    this.adminService.getCurrentSeason(providerId).subscribe(
      (res) => {
        this.currentSeason = res;
        var trip = res.tradename + (res.seasonActive.currentCounter).toString().padStart(3, "0");
        this.orderSelected.trip = trip;
        this.formProviderInfo.patchValue(this.orderSelected);
        this.formProviderInfo.controls['trip'].patchValue(trip);
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.toastService.warning(err.error.message);
        this.spinner.hide();
      }
    );
  }

  public editSeason(){
    if(this.orderSelected.provider){
      this.router.navigate([
        'admin/providers/',
        this.orderSelected.provider,
        'seasons'
      ]);
    }
  }

}