<div class="container report-box-container p-3">
  <div class="row mb-3">
    <div class="col-6 text-left">
      <span class="primary"><b>Descuentos por mes</b></span>
    </div>
    <div class="col-4 text-right">
      <div class="date_range_form">
        <img class="icon_date" src="../assets/icons/ic_tabs_date.png">
        <input class="form-control range" name="range-discounts-montly" type="text" >
      </div>
    </div>
    <div class="col-2 text-right">
      <button class="form-control btn-export" (click)="getExcel();">
        <i class="fas fa-file"></i> Exportar
      </button>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-6 text-left">
      <div class="green-point"></div>Descuentos
    </div>
    <div class="col-6 text-right">
      <span>Monto total de descuentos</span>
      <span style="display: block; color: #8bde5e;">
        <b>{{summary.totalAmount | currency}}</b>
      </span>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <canvas id="baseChart"></canvas>
      <img id="fakeChart" />
    </div>
  </div>
</div>