<div class="container mt-3">
  <!-- Outer Row -->
  <div class="row">
    <div class="col-12">
      <div class="container">
        <div class="row">

          <div class="col-lg-7 col-md-12 col-sm-12 m-3 p-3 card-bordered">
            <form [formGroup]="clientInfoForm">
              <div class="row mt-3">
                <div class="col-12">
                  <h6 class="font-weight-bold text-dark">Selecciona el cliente para la venta</h6>
                </div>
              </div>
              <div class="row text-left">
                <div class="col-8">
                  <div class="form-group mb-0 label-animate">
                    <label for="client" class="dark" style="top: 5px !important;"><b class="red"></b>Selecciona el cliente para la venta</label>
                    <select class="form-control no-border" data-live-search="true" name="client" id="client" formControlName="client" value="{{saleSelected.client? saleSelected.client.id: 0}}">
                      <option value="">--</option>
                      <option *ngFor="let p of clients" value="{{p.id}}"  attr.data-tokens="p.businessName">{{p.businessName}}</option>
                    </select>
                    <div class="border-control"></div>
                    <div *ngIf="clientInfoForm.controls['client'].invalid && (clientInfoForm.controls['client'].dirty || clientInfoForm.controls['client'].touched)" class="inputAlert">
                      <div *ngIf="clientInfoForm.controls['client'].errors && clientInfoForm.controls['client'].errors.required">
                          Razón social del proveedor obligatoria
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="modal-catalog-btn mt-3">
                    <img (click)="submitClient();" src="../assets/icons/ic_plus.png" />
                    <label (click)="submitClient();">AGREGAR CLIENTE</label>
                  </div>
                </div>
              </div>
            </form>
            <div class="mt-4" *ngIf="saleSelected.client">
              <div class="row text-left gray-area m-1 p-1">
                <div class="col-12">
                  <a (click)="deleteClient();" class="btn-close">x</a>
                </div>
                <div class="col-3">
                  <h1><b class="primary">{{saleSelected.client.businessName}}</b></h1>
                  <p>Cliente</p>
                </div>
                <div class="col-5">
                  <p><span class="primary">Tel:</span> {{saleSelected.client.phone}}</p>
                  <p><span class="primary">E-mail:</span> {{saleSelected.client.email}}</p>
                </div>
                <div class="col-4">
                  <p><span class="primary">Dirección:</span> {{saleSelected.client.address}}. {{saleSelected.client.city}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 p-3 m-3 card-shadowed">
            <form [formGroup]="purchaseDateForm">
              <div class="row mt-3">
                <div class="col-12">
                  <h6 class="font-weight-bold text-dark">Agrega fecha</h6>
                </div>
              </div>
              <div class="row text-left " style="margin-top: -1rem;">
                <div class="col-12">
                  <div class="mb-0">
                    <label for="purchaseDate" class="dark" style="color: #afb1cc; top: 14px; left: 5px; font-size: 0.75em;position: relative; padding: 0px 5px 0px 5px; "><b class="red">*</b>Selecciona fecha de venta</label>
                    <input type="date" class="form-control no-border" name="purchaseDate" id="purchaseDate" formControlName="purchaseDate" value="{{saleSelected.purchaseDate}}" (change)='onPurchaseDateChanged($event.target.value);'>
                    <div class="border-control"></div>
                    <div *ngIf="purchaseDateForm.controls['purchaseDate'].invalid && (purchaseDateForm.controls['purchaseDate'].dirty || purchaseDateForm.controls['purchaseDate'].touched)" class="inputAlert">
                      <div *ngIf="purchaseDateForm.controls['purchaseDate'].errors && purchaseDateForm.controls['purchaseDate'].errors.required">Fecha de venta obligatoria
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row text-left mt-3">
                <div class="col-12">
                  <div class="{{saleSelected.invoice? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                    <label for="invoice" class="dark"><b class="red">*</b>Ingrese un número de factura</label>
                    <input type="text" class="form-control no-border" name="invoice" id="invoice" formControlName="invoice" value="{{saleSelected.invoice}}" (change)='onInvoiceChanged($event.target.value);'>
                    <div *ngIf="purchaseDateForm.controls['invoice'].invalid && (purchaseDateForm.controls['invoice'].dirty || purchaseDateForm.controls['invoice'].touched)" class="inputAlert">
                      <div *ngIf="purchaseDateForm.controls['invoice'].errors && purchaseDateForm.controls['invoice'].errors.required">Factura obligatoria
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row justify-content-center mtr-2">
          <div class="col-lg-3 col-md-5 col-md-5">
            <button type="button" (click)="next();" class="btn btn-primary btn-user btn-block shadow-sm">CONTINUAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
            </button>
          </div>
          <div class="col-lg-2 col-md-4 col-md-4">
             <button type="cancel" [routerLink]="['/sales']" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>