<div class="container">
  <!-- Outer Row -->
  <div class="row justify-content-center mtr-2 mb-5">
    <div class="col-11">
      <form [formGroup]="formTransportLine" class="text-left">
        <div class="row">
          <div class="col-12">
            <h6 class="font-weight-bold text-dark" style="width: 30%; float: left;">Información de línea de transporte</h6>
            <div class="long-line" style="width: 70%;"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5">
            <div class="form-group mb-0 label-animate}}">
              <label for="transportLines" class="dark" style="top: 5px !important;"><b class="red">*</b>Selecciona razón social del proveedor</label>
              <select class="form-control no-border" data-live-search="true" name="transportLines" id="transportLines" (change)='onTransportLinesChanged($event.target.value);' formControlName="transportLines" value="{{orderSelected.transportLines? orderSelected.transportLines.id: 0}}">
                <option value="" disabled="disabled">--</option>
                <option *ngFor="let t of transportLines" value="{{t.id}}" attr.data-tokens="t.businessName">{{t.businessName}}</option>
              </select>
              <div class="border-control"></div>
              <div *ngIf="formTransportLine.controls['transportLines'].invalid && (formTransportLine.controls['transportLines'].dirty || formTransportLine.controls['transportLines'].touched)" class="inputAlert">
                <div *ngIf="formTransportLine.controls['transportLines'].errors && formTransportLine.controls['transportLines'].errors.required">
                    Razón social del proveedor obligatoria
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="form-group mb-0 label-animate">
              <label for="driver" class="dark" style="top: 5px !important;"><b class="red">*</b>Selecciona el chofer asociado a este viaje</label>
              <select class="form-control no-border" data-live-search="true" name="driver" id="driver" (change)='onDriverChanged($event.target.value);' formControlName="driver" value="{{orderSelected.driver? orderSelected.driver.id: 0}}">
                <option value="" disabled="disabled">--</option>
                <option *ngFor="let d of drivers" value="{{d.id}}" attr.data-tokens="d.name + ' ' + d.firstSurname">{{d.name + ' ' + d.firstSurname}}</option>
              </select>
              <div class="border-control"></div>
              <div *ngIf="formTransportLine.controls['driver'].invalid && (formTransportLine.controls['driver'].dirty || formTransportLine.controls['driver'].touched)" class="inputAlert">
                <div *ngIf="formTransportLine.controls['driver'].errors && formTransportLine.controls['driver'].errors.required">
                    Chofer asociado obligatorio
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="modal-catalog-btn mt-3">
              <img (click)="submitTransportLine();" src="../assets/icons/ic_plus.png" />
              <label (click)="submitTransportLine();">AGREGAR</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="orderSelected.transportLines" class="row text-left gray-area m-1 p-1">
    <div class="col-12">
      <div class="container mb-0">
        <div class="row text-left gray-area m-1 p-1">
          <div class="col-3">
            <h1><b class="primary">{{orderSelected.transportLines.businessName}}</b></h1>
            <p>Línea de Transporte</p>
          </div>
          <div class="col-3">
            <p><span class="primary">Tel:</span> {{orderSelected.driver.phone}}</p>
            <p><span class="primary">E-mail:</span> {{orderSelected.driver.email}}</p>
          </div>
          <div class="col-4">
            <p><span class="primary">Nombre:</span> {{orderSelected.driver.name}}</p>
            <p><span class="primary">Apellido Paterno:</span> {{orderSelected.driver.firstSurname}}</p>
            <p><span class="primary">Apellido Materno:</span> {{orderSelected.driver.secondSurname}}</p>
          </div>
          <div class="col-1">
            <img class="catalog-icon" src="../assets/icons/ic_edit.png" (click)="edit();" placement="top" ngbTooltip="editar"/>
          </div>
          <div class="col-1">
            <img class="catalog-icon" src="../assets/icons/ic_delete.png" (click)="deleteTransportLine();" placement="top" ngbTooltip="eliminar"/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center mtr-2">
    <div class="col-3">
      <button type="button" (click)="submitDriver()" class="btn btn-primary btn-user btn-block shadow-sm">CONTINUAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
      </button>
    </div>
    <div class="col-2">
       <button type="cancel" [routerLink]="['/purchases']" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
    </div>
  </div>

</div>

<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div *ngIf="successModal.hasData()" class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-11">
        <h1 class="h5 text-dark">{{ successModal.getData().title }}</h1>
        <p class="h6" style="font-size: 0.9rem;">{{ successModal.getData().subtitle }} </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #deleteModal identifier="deleteModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_warning.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¿Desea eliminar este registro?</h1>
        <p class="h6" style="font-size: 0.9rem;">Recuerda que será eliminado de la base de datos</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <button type="submit" (click)="deleteTransportLine()" class="btn btn-primary btn-user btn-block shadow-sm">SÍ, ELIMINAR<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
      <div class="col-4">
        <button type="cancel" (click)="deleteModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
      </div>
    </div>
  </div>
</ngx-smart-modal>