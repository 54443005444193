import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DOCUMENT, DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from './../../../../app.component';
import { environment } from './../../../../../environments/environment';
import { FormsService } from './../../../../services/forms/forms.service';
import { AdminService } from './../../../../services/admin/admin.service';
declare var $: any;

@Component({
  selector: 'app-client-sale',
  templateUrl: './client-sale.component.html',
  styleUrls: ['./client-sale.component.css']
})
export class ClientSaleComponent implements OnInit, OnChanges {
  @Output() continue = new EventEmitter<string>();
  @Output() updateSale = new EventEmitter<any>();
  @Input() saleSelected;

  public currentSeason: any = {};
  public clientInfoForm:FormGroup;
  public purchaseDateForm:FormGroup;
  
  public clients: any[] = [];

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService,
    private spinner: NgxSpinnerService, private toastService: ToastrService, private adminService: AdminService ,
    private router: Router, @Inject(DOCUMENT) document, private datePipe: DatePipe, ) {
    this.clientInfoForm = this.formBuilder.group({
      client: ['', [Validators.required]],
    });
    this.purchaseDateForm = this.formBuilder.group({
      purchaseDate: ['', [Validators.required]],
      invoice: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.populateClients();
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    let log: string[] = [];
    for (let propName in changes) {
      if(propName == 'saleSelected'){
        if(!this.saleSelected.purchaseDate){
          this.saleSelected.purchaseDate = this.formatDate(new Date());
        }
        
        this.clientInfoForm.patchValue(this.saleSelected);
        this.purchaseDateForm.patchValue(this.saleSelected);
        this.purchaseDateForm.controls['purchaseDate'].patchValue(this.saleSelected.purchaseDate);
      }
    }
  }

  public populateClients(){
    this.spinner.show();
    this.adminService.getClients().subscribe(
      res => {
        this.clients = res;        
        setTimeout(()=>{
          if(this.saleSelected.client){
            this.clientInfoForm.controls['client'].patchValue(this.saleSelected.client.id);
            $("#client").val(this.saleSelected.client.id).selectpicker("refresh");  
          }else{
            $("#client").val('default').selectpicker("refresh");  
          }

        }, 100);
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public submitClient(){
    if (!this.clientInfoForm.valid) {
      this.formsService.validateAllFormFields(this.clientInfoForm);
    }else {
      this.saleSelected.client = this.clientInfoForm.value['client'];
      this.updateSale.next(this.saleSelected);
    }
  }

  public deleteClient(){
    this.saleSelected.client = null;
    this.updateSale.next(this.saleSelected);
  }

  public onPurchaseDateChanged(value){
    if(value){
      this.saleSelected.purchaseDate = value;
      this.updateSale.next(this.saleSelected);
    }
  }

  public onInvoiceChanged(value){
    console.log("value", value);
    if(value){
      this.saleSelected.invoice = value;
      this.updateSale.next(this.saleSelected);
    }
  }

  public next(){
    this.clientInfoForm.patchValue(this.saleSelected);
    this.purchaseDateForm.patchValue(this.saleSelected);
    if (!this.clientInfoForm.valid) {
      this.formsService.validateAllFormFields(this.clientInfoForm);
    }else if(!this.purchaseDateForm.valid){
      this.formsService.validateAllFormFields(this.purchaseDateForm);  
    }else{
      this.continue.next();
    }
  }

  public formatDate(d){
    return d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate();
  }

}