import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgxSmartModalService, NgxSmartModalComponent, } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../../../environments/environment';
import { FormsService } from './../../../../services/forms/forms.service';
import { AdminService } from './../../../../services/admin/admin.service';
import { DriversComponent } from './../../../../screens/drivers/drivers.component';
import { SalesService } from './../../../../services/sales/sales.service';

@Component({
  selector: 'app-observations-sale',
  templateUrl: './observations-sale.component.html',
  styleUrls: ['./observations-sale.component.css']
})
export class ObservationsSaleComponent implements OnInit {
  @Output() continue = new EventEmitter<any>();
  @Output() updateSale = new EventEmitter<any>();
  @Input() saleSelected;

  public observationForm:FormGroup;
  public observationSelected:any = {

  };

  //table, pagination
  public config = {
    id: 'observations',
    searchText: '',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100]
  };
  public rows: any = [];

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private adminService: AdminService, private toastService: ToastrService, public salesService:SalesService) { }

  ngOnInit(): void {
    this.observationForm = this.formBuilder.group({
      observation: ['', [Validators.required]],
    });
    this.populateObservations();
  }

  public populateObservations(){
    this.spinner.show();
    this.salesService.searchObservations(this.saleSelected.id, this.config).subscribe(
      res => {
        this.rows = res.data;
        this.config.totalItems = res.total;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public submitObservation(id){
    console.log("id->", id);
    if(id === undefined){
      this.addObservation();
    }else{
      this.updateObservation(id);
    }
  }

  public addObservation() {
    if (!this.observationForm.valid) {
       this.formsService.validateAllFormFields(this.observationForm);
    }else {
      var fecha = "";
      var date = new Date();
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();

      if(month < 10){
        fecha = `${year}-0${month}-${day}`;
      }else{
        fecha = `${year}-${month}-${day}`;
      }

      this.observationForm.value['registrationDate'] = fecha;
      this.observationForm.value['saleProducts'] = this.saleSelected.id;
      this.salesService.addObservation(this.observationForm.value).subscribe(
        (res) => {
          this.populateObservations();
          this.observationForm.reset();
          this.observationSelected = {};
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
      this.populateObservations();
    }
  }

  public updateObservation(id){
    if (!this.observationForm.valid) {
       this.formsService.validateAllFormFields(this.observationForm);
    }else {
      this.salesService.updateObservation(this.observationSelected.id, this.observationForm.value).subscribe(
        (res) => {
          this.populateObservations();
          this.observationForm.reset();
          this.observationSelected = {};
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
      this.populateObservations();
    }
  }

  public deleteObservation(id){
    console.log("deleteObservation-->", id);
    this.spinner.show();
    this.salesService.deleteObservation(id).subscribe(
      (res) => {
        this.modalService.getModal('deleteModal').close();
        this.openSuccessModal("¡Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.populateObservations();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  // actions
  edit(item) {
    this.observationSelected = item;
    this.observationForm.patchValue(item);
  }

  active(item, enabled){
    this.observationSelected = item;
    this.openSuccessModal("¡Activado exitosamente!", "");
    /*
    this.jobRequestService
      .updatePersonalinformation({ enabled: true }, item.id)
      .subscribe(
        res => {
          this.toast.success("Activado", "Usuario");
          this.ngOnInit();
        },
        err => {
          console.log("error ->", err);
        }
      );
      */
  }

  delete(item) {
    this.observationSelected = item;
    this.modalService.open("deleteModal");
  }

  public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal');
    this.modalService.setModalData(obj, 'successModal');
    this.modalService.getModal('successModal').open();
  }

  public onSearch(){
    this.config.currentPage = 1;
    this.populateObservations();
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
    this.populateObservations();
  }

  public next(){
    this.continue.next();  
  }

}