import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MouseEvent } from '@agm/core';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../../environments/environment';
import { FormsService } from './../../../services/forms/forms.service';
import { AdminService } from './../../../services/admin/admin.service';
import { InventoryService } from './../../../services/inventory/inventory.service';
import { ReportsService } from './../../../services/reports/reports.service';
import { ExcelsService } from './../../../services/excels/excels.service';

@Component({
  selector: 'app-inventory-all',
  templateUrl: './inventory-all.component.html',
  styleUrls: ['./inventory-all.component.css']
})
export class InventoryAllComponent implements OnInit {
  public environment = environment;
  //table, pagination
  public config = {
    id: 'inventory-all',
    searchText: '',
    itemsPerPage: 5,
    currentPage: 1,
    totalItems: 0,
    options: [5, 10, 20]
  };
  public rows: any = [];

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private adminService:AdminService, private inventoryService:InventoryService, private toastService: ToastrService, private reportsService: ReportsService, private excelsService: ExcelsService) { }

  ngOnInit(): void {
    this.populateInventory();
  }

  public populateInventory(){
    this.spinner.show();
    this.adminService.searchProducts(this.config).subscribe(
      res => {
        this.rows = res.data;
        this.config.totalItems = res.total;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public onSearch(){
    this.config.currentPage = 1;
    this.populateInventory();
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
    this.populateInventory();
  }


  public onPrint(id){
    this.spinner.show();
    this.excelsService.getReporteProductInventory(id).subscribe(
      res => {
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

}