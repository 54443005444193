import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRoles:[] = route.data.expectedRole;
  	const profile:any = this.auth.getCurrentProfile();
  	var itemIndex = -1;
  	var itemIndex = expectedRoles.findIndex((
  		role => role == profile.rol.id
  	));
  	console.log("itemIndex", itemIndex);
  	if (itemIndex == -1) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
  
}