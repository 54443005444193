<div class="container" style="margin-bottom: 0px;">
  <!-- Outer Row -->
  <div class="row justify-content-center mtr-2">
    <div class="col-12">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-4"></div>
          <div class="col-8 text-left">
            <span style="display: flex;">
              <img class="m-3" width="50px" height="50px" src="../assets/icons/ic_trunk_2.png">
              <div style="color: #063865;">
                <h5 style="margin-left: 80px;">
                  <b><!--#003865!--></b>
                </h5>
                <h5><b>Reporte líneas de transporte</b></h5>
                <p style="font-size: 12px;">Este reporte presenta la información de transportes, totales y transportistas o choferes.</p>
              </div>
            </span>
          </div>
        </div>
        <div class="row justify-content-center mtr-2">
          <div class="col-10"></div>
          <div class="col-2">
            <button type="submit" class="btn btn-primary btn-user btn-block shadow-sm" printSectionId="print-section" [useExistingCss]="true" ngxPrint><img class="btn-icon-print" src="../assets/icons/ic_print_white.png"/> Imprimir </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container bordered" id="print-section" style="background: white;">
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="container mb-2">
        <div class="row justify-content-center">
          <div class="col-6">
            <div class="company-container">
              <img id="logo" [src]="environment.imagesUrl + company.logo" style="height: 120px;" />
              <div class="datos">
                <span class="title">{{company.name}}</span>
                <span>{{company.representative}}</span>
                <span>R.F.C. {{company.rfc}}</span>
                <span>{{company.state}}, {{company.city}} C.P. {{company.postalCode}}</span>
                <span>{{company.address}}</span>
                <span>TELS. {{company.phone}}</span>
              </div>
            </div>
          </div>
          <div class="col-6 text-right mt-3">
            <h6><b style="color: #063865;">Reporte de líneas de transporte</b></h6>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-9"></div>
          <div class="col-3">
            <div class="date_range_form">
              <img class="icon_date" src="../assets/icons/ic_tabs_date.png">
              <input class="form-control range" name="range-lineas-transporte" type="text">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div  class="col-12">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-3">
            <div class="report-box-container">
              <div class="row">
                <div class="col-8 report-indicator">
                  <span class="title">Líneas de transporte</span>
                  <span class="subtitle">{{summary.transportLine}}</span>
                </div>
                <div class="col-4">
                  <div class="blue-circle">
                    <img src="../assets/icons/ic_sales.png"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="report-box-container" style="width: 80%;margin: 10px auto;">
              <div class="row">
                <div class="col-9 report-indicator">
                  <span class="title">Monto total gastos de transporte</span>
                  <span class="subtitle">{{summary.amount | currency}}</span>
                </div>
                <div class="col-3">
                  <div class="blue-circle">
                    <img src="../assets/icons/ic_currency.png"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="report-box-container">
              <div class="row">
                <div class="col-8 report-indicator">
                  <span class="title">Total transportistas</span>
                  <span class="subtitle">{{summary.totalCarriers}}</span>
                </div>
                <div class="col-4">
                  <div class="blue-circle">
                    <img src="../assets/icons/ic_users.png"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mtr-2">
          <div class="col-12">
            <app-graph-expenses-transport></app-graph-expenses-transport>
          </div>
        </div>

        <div class="row justify-content-center mtr-2">
          <div class="col-12">
            <app-graph-expenses-lines></app-graph-expenses-lines>
          </div>
        </div>

        <div class="row justify-content-center mtr-2">
          <div class="col-12">
            <app-graph-expenses-sales></app-graph-expenses-sales>
          </div>
        </div>
        <div class="row justify-content-center mtr-2">
          <div class="col-12">
            <app-graph-expenses-orders></app-graph-expenses-orders>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>