import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class UnitsService {
	private PATH_UNIT:String = "/units/";

  constructor(private http: HttpClient) {}

  getUnitList(productId, type) {
    var search = JSON.stringify(
      {"$and":[
        {"type": type},
        {"product.id": {"$eq": productId}},
      ]}
    );
    console.log("search-->", search);

    return this.http.get<any>(environment.apiUrl + this.PATH_UNIT + '?join=product&s=' + search, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_UNIT, data),
          error => throwError(error)
        )
      );
  }

  searchUnitList(productId, type, config) {
    var search = JSON.stringify(
      {"$and":[
        {"type": type},
        {"product.id": {"$eq": productId}},
        {"$or":[
          {"name": {"$cont": config.searchText}},
          {"shortname": {"$cont": config.searchText}}
        ]}
      ]}
    );

    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));

    return this.http.get<any>(environment.apiUrl + this.PATH_UNIT + '?join=product&page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_UNIT, data),
          error => throwError(error)
        )
      );
  }

  addUnit(obj, type) {
    obj['type'] = type;
    return this.http.post<any>(environment.apiUrl + this.PATH_UNIT, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_UNIT, data),
          error => throwError(error)
        )
      );
  }

  updateUnit(id, obj) {
    return this.http.patch<any>(environment.apiUrl + this.PATH_UNIT + id, obj, httpOptions)
      .pipe(tap( // Log the result or error
        data => console.log(this.PATH_UNIT, data),
        error => throwError(error)
      ));
  }
  

  deleteUnit(id) {
    return this.http.delete<any>(environment.apiUrl + this.PATH_UNIT + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_UNIT, data),
          error => throwError(error)
        )
      );
  }

}