<ngx-spinner name = "dashboard" bdColor = "rgba(0, 0, 0, 0.6)" size = "medium" color = "rgb(188, 228, 197)" type = "ball-atom" [fullScreen] = "false"><p style="color: white" > Cargando... </p></ngx-spinner>
<div *ngIf="listPanels">

  <div class="row" style="padding: 1rem 4rem;">
    <div class="col-12">
      <h5 class="h5 text-primary mb-2">Datos Generales</h5>
    </div>
  </div>

  <div id="panelsContainer" class="row">
    <!-- Content Row -->
    <div *ngFor="let panel of listPanels; let a = index;" class="col-xl-4 col-md-4 mb-4">

        <div [routerLink]="[panel.route]" class="card h-100 py-2" style="box-shadow: 0 0.5rem 0.75rem 0 {{panel.color}}44, inset 0px 1.5rem 0.5rem -13px {{panel.color}}44; border-width: 12px 0px 0px; border-style: solid; border-color: {{panel.color}}; border-top: 1rem solid {{panel.color}}; cursor: pointer; border-radius: 0 0 0.35rem 0.35rem;">
          <div class="card-body" style="padding: 0.5rem 1rem 0.5rem 1rem;">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 mt-2">
                <div class="h4 font-weight-bold text-uppercase mb-0" style="color: {{panel.color}}">{{panel.total}}</div>
                <div class="h6 mb-0 font-weight-regular text-dark">{{panel.name}}</div>
              </div>
              <div class="col-auto">
                <img class="panel-icon" src="{{imagesUrl + panel.icon}}"/>
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>

  <div *ngIf="profile.rol && profile.rol.id == 3" class="row mt-3" style="padding: 1rem 4rem;">
    <div class="col-12">
      <div class="container report-box-container p-3">
        <div class="row mb-3">
          <div class="col-6 text-left">
            <span class="primary"><b>Compras por mes</b></span>
          </div>
          <div class="col-4 text-right">
            <div class="date_range_form">
              <img class="icon_date" src="../assets/icons/ic_tabs_date.png">
              <input class="form-control range" name="range-all" type="text" >
            </div>
          </div>
          <div class="col-2 text-right">
            <button class="form-control btn-export" (click)="getExcel();">
              <i class="fas fa-file"></i> Exportar
            </button>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-2 text-left">
            <div class="green-point"></div>Compras
          </div>
          <div class="col-2 text-left">
             <div class="green-point" style="background: #a3a1fb"></div>Ventas
          </div>
          <div class="col-2 text-left">
             <div class="green-point" style="background: #f9db83"></div>Mermas
          </div>
          <div class="col-2 text-left">
             <div class="green-point" style="background: #1c62ff"></div>Utilidad neta
          </div>
          <div class="col-4 text-right"></div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <canvas id="baseChart"></canvas>
            <img id="fakeChart" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>