<section class="container justify-content-center text-center p-0">
  <article class="row justify-content-center">
    <div class="col-12">
      <h4 class="catalog-title font-weight-bold text-dark mb-3">
        <img class="header-image" src="../assets/icons/ic_providers.png"/>
      PROVEEDORES</h4>
      <div class="container">
        <div class="row mb-5" style="align-items: baseline;">
          <div class="col-lg-6 col-8">
            <div class="search-control">
              <i class="fa fa-search"></i>
              <input type="search" #searchInput [(ngModel)]="config.searchText" (search)="onSearch()" class="form-control input-search text-dark" placeholder="Buscar por RFC, Razón social, Nombre comercial, Ciudad"/>
            </div>
          </div>
          <div class="col-lg-6 col-4 text-right">
            <div class="catalog-btn">
              <img src="../assets/icons/ic_plus.png" (click)="openProviderModal();" />
              <label (click)="openProviderModal();">NUEVO PROVEEDOR</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">RFC</th>
                    <th scope="col">RAZÓN SOCIAL</th>
                    <th scope="col">NOMBRE COMERCIAL</th>
                    <th scope="col">TELÉFONO</th>
                    <th scope="col">CORREO</th>
                    <th scope="col">CIUDAD</th>
                    <th scope="col">DIRECCIÓN</th>
                    <th scope="col">ACCIONES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rows | paginate: config">
                    <td>{{item.id}}</td>
                    <td>{{item.rfc | mask: 'AAAA-AAAAAA-AAA'}}</td>
                    <td>{{item.businessName}}</td>
                    <td>{{item.tradename}}</td>
                    <td>{{item.phone | mask: '(000)000-00-00'}}</td>
                    <td>{{item.email}}</td>
                    <td>{{item.city}}</td>
                    <td>{{item.address}}</td>
                    <td class="iconContainer" style="display: flex;">
                      <img class="catalog-icon" src="../assets/icons/ic_edit.png" (click)="openProviderModal(item);" placement="top" ngbTooltip="editar"/>
                      <img *ngIf="item.status == 1" class="catalog-icon" src="../assets/icons/ic_enabled.png" (click)="disable(item);" placement="top" ngbTooltip="deshabilitar"/>
                      <img *ngIf="item.status == 0" class="catalog-icon" src="../assets/icons/ic_disabled.png" (click)="enable(item);" placement="top" ngbTooltip="habilitar"/>
                      <img class="catalog-icon" src="../assets/icons/ic_seasons.png" [routerLink]="[item.id, 'seasons']" placement="top" ngbTooltip="temporadas"/>
                      <img *ngIf="!item.enabled" class="catalog-icon" src="../assets/icons/ic_delete.png" (click)="delete(item);" placement="top" ngbTooltip="eliminar"/>
                    </td>
                  </tr>
                  <tr *ngIf="(rows).length === 0">
                    <td colspan="6">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagerChanged($event)">

              <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                  <a (click)="p.previous()" *ngIf="!p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

              <div *ngFor="let page of p.pages" class="page-number" [class.current]="p.getCurrent() === page.value">
                  <a (click)="p.setCurrent(page.value);" *ngIf="p.getCurrent() !== page.value">
                      <span>{{ page.label }}</span>
                  </a>
                  <div *ngIf="p.getCurrent() === page.value">
                      <span>{{ page.label }}</span>
                  </div>
              </div>

              <div class="pagination-next" [class.disabled]="p.isLastPage()">
                  <a (click)="p.next()" *ngIf="!p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

            </pagination-template>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 pager">
            <label>Mostrar <select [(ngModel)]="config.itemsPerPage" (change)='onPagerChanged(1);'>
              <option *ngFor="let c of config.options" [ngValue]="c">{{c}}</option>
            </select> Registros por página</label>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>

<ngx-smart-modal customClass="xl-dialog closable" #providerModal identifier="providerModal" [closable]="true">
  <button class="btn-back-modal" (click)="providerModal.close()">
    <i class="fas fa-long-arrow-alt-left"></i>
  </button>
  <div class="container justify-content-center text-left">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="row justify-content-center mb--4">
          <div class="col-6 text-center">
            <h1 class="h6 font-weight-bold text-dark mb-0 ml-3">
              <p *ngIf="!itemSelected.id">AGREGAR PROVEEDOR</p>
              <p *ngIf="itemSelected.id">EDITAR PROVEEDOR</p>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container justify-content-center text-left">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <form [formGroup]="formProvider">
            <div class="{{itemSelected.rfc? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
              <label for="rfc" class="dark"><b class="red">*</b>RFC</label>
              <input type="text" class="form-control no-border" name="rfc" id="rfc" formControlName="rfc" value="{{itemSelected.rfc | mask: 'AAAA-AAAAAA-AAA'}}" [dropSpecialCharacters]="false" mask="AAAA-AAAAAA-AAA" />
              <div class="border-control"></div>
              <div *ngIf="formProvider.controls['rfc'].invalid && (formProvider.controls['rfc'].dirty || formProvider.controls['rfc'].touched)" class="inputAlert">
                <div *ngIf="formProvider.controls['rfc'].errors && formProvider.controls['rfc'].errors.required">
                    RFC obligatorio
                </div>
              </div>
            </div>
            <div class="{{itemSelected.businessName? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
              <label for="businessName" class="dark"><b class="red">*</b>Razón Social</label>
              <input type="text" class="form-control no-border" name="businessName" id="businessName" formControlName="businessName" value="{{itemSelected.businessName}}">
              <div class="border-control"></div>
              <div *ngIf="formProvider.controls['businessName'].invalid && (formProvider.controls['businessName'].dirty || formProvider.controls['businessName'].touched)" class="inputAlert">
                <div *ngIf="formProvider.controls['businessName'].errors && formProvider.controls['businessName'].errors.required">
                    Razón social obligatoria
                </div>
              </div>
            </div>
            <div class="{{itemSelected.tradename? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                <label for="tradename" class="dark"><b class="red">*</b>Nombre Comercial</label>
                <input type="text" class="form-control no-border" name="tradename" id="tradename" formControlName="tradename" value="{{itemSelected.tradename}}">
                <div class="border-control"></div>
                <div *ngIf="formProvider.controls['tradename'].invalid && (formProvider.controls['tradename'].dirty || formProvider.controls['tradename'].touched)" class="inputAlert">
                  <div *ngIf="formProvider.controls['tradename'].errors && formProvider.controls['tradename'].errors.required">
                      Nombre comercial obligatorio
                  </div>
                </div>
              </div>
              <div class="{{itemSelected.phone? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                <label for="phone" class="dark"><b class="red">*</b>Teléfono</label>
                <input type="text" class="form-control no-border" name="phone" id="phone" formControlName="phone" value="{{itemSelected.phone | mask: '(000)000-00-00'}}" [dropSpecialCharacters]="false" mask="(000)000-00-00">
                <div class="border-control"></div>
                <div *ngIf="formProvider.controls['phone'].invalid && (formProvider.controls['phone'].dirty || formProvider.controls['phone'].touched)" class="inputAlert">
                  <div *ngIf="formProvider.controls['phone'].errors && formProvider.controls['phone'].errors.required">
                      Teléfonos obligatorios
                  </div>
                </div>
              </div>
              <div class="{{itemSelected.email? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                <label for="email" class="dark"><b class="red">*</b>Correo electrónico</label>
                <input type="text" class="form-control no-border" name="email" id="email" formControlName="email" value="{{itemSelected.email}}">
                <div class="border-control"></div>
                <div *ngIf="formProvider.controls['email'].invalid && (formProvider.controls['email'].dirty || formProvider.controls['email'].touched)" class="inputAlert">
                  <div *ngIf="formProvider.controls['email'].errors && formProvider.controls['email'].errors.required">
                      Correo electrónico obligatorio
                  </div>
                </div>
              </div>
              <div class="{{itemSelected.city? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                <label for="city" class="dark"><b class="red">*</b>Ciudad</label>
                <input type="text" class="form-control no-border" name="city" id="city" formControlName="city" value="{{itemSelected.city}}">
                <div class="border-control"></div>
                <div *ngIf="formProvider.controls['city'].invalid && (formProvider.controls['city'].dirty || formProvider.controls['city'].touched)" class="inputAlert">
                  <div *ngIf="formProvider.controls['city'].errors && formProvider.controls['city'].errors.required">
                      Ciudad obligatoria
                  </div>
                </div>
              </div>
              <div class="{{itemSelected.address? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
                <label for="address" class="dark"><b class="red">*</b>Dirección</label>
                <input type="text" class="form-control no-border" name="address" id="address" formControlName="address" value="{{itemSelected.address}}">
                <div class="border-control"></div>
                <div *ngIf="formProvider.controls['address'].invalid && (formProvider.controls['address'].dirty || formProvider.controls['address'].touched)" class="inputAlert">
                  <div *ngIf="formProvider.controls['address'].errors && formProvider.controls['address'].errors.required">
                      Ciudad obligatoria
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-lg-5">
                  <button type="submit" (click)="submitProvider(itemSelected.id)" class="btn btn-primary btn-user btn-block shadow-sm">
                    <span *ngIf="!itemSelected.id">AGREGAR PROVEEDOR</span>
                    <span *ngIf="itemSelected.id">GUARDAR</span>
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </button>
                </div>
                <div class="col-lg-3">
                  <button type="cancel" (click)="providerModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div *ngIf="successModal.hasData()" class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">{{ successModal.getData().title }}</h1>
        <p class="h6" style="font-size: 0.9rem;">{{ successModal.getData().subtitle }} </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #deleteModal identifier="deleteModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_warning.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¿Desea eliminar este registro?</h1>
        <p class="h6" style="font-size: 0.9rem;">Recuerda que será eliminado de la base de datos</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <button type="submit" (click)="deleteProvider(itemSelected.id)" class="btn btn-primary btn-user btn-block shadow-sm">SÍ, ELIMINAR<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
      <div class="col-4">
        <button type="cancel" (click)="deleteModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
      </div>
    </div>
  </div>
</ngx-smart-modal>