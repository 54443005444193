<section class="container justify-content-center text-center p-0">
  <article class="row justify-content-center">
    <div class="col-12">
      <h4 class="catalog-title font-weight-bold text-dark mb-3">
        MERMA DE PRODUCTOS
      </h4>
      <ngb-tabset type="pills" justify="center" #t="ngbTabset" (tabChange)="beforeChange($event)">
        <!-- PRODUCTOS !-->
        <ngb-tab class="tab-wizard" id="tab-selectbyid1">
          <ng-template ngbTabTitle>
            <div id="img-add-products" class="circle"></div>
            <span class="title">AGREGAR PRODUCTOS</span>
            <!--<div class="line-tab" ></div>!-->
          </ng-template>
          <ng-template ngbTabContent>
            <app-products-lost (continue)="continue($event);" (updateLost)="updateLost($event);" [lostSelected]="lostSelected"></app-products-lost>
          </ng-template>
        </ngb-tab>
        <!-- OBSERVACIONES !-->
        <ngb-tab class="tab-wizard" id="tab-selectbyid2">
          <ng-template ngbTabTitle>
            <div id="img-observations" class="circle"></div>
            <span class="title">OBSERVACIONES</span>
            <div class="line-tab"></div>
          </ng-template>
          <ng-template ngbTabContent>
            <app-observations-lost (continue)="continue($event);" (updateLost)="updateLost($event);" [lostSelected]="lostSelected"></app-observations-lost>
          </ng-template>
        </ngb-tab>
        <!-- REGISTRAR E IMPRIMIR !-->
        <ngb-tab class="tab-wizard" id="tab-selectbyid3">
          <ng-template ngbTabTitle>
            <div id="img-print" class="circle"></div>
            <span class="title">REGISTRAR E IMPRIMIR</span>
            <div class="line-tab"></div>
          </ng-template>
          <ng-template ngbTabContent>
            <app-register-lost (continue)="continue($event);" (updateLost)="updateLost($event);" [lostSelected]="lostSelected"></app-register-lost>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </article>
</section>

<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div *ngIf="successModal.hasData()" class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">{{ successModal.getData().title }}</h1>
        <p class="h6" style="font-size: 0.9rem;">{{ successModal.getData().subtitle }} </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>