<div class="container">
  <!-- Outer Row -->
  <div class="row justify-content-center mtr-2">
    <div class="col-12">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-4"></div>
          <div class="col-8 text-left">
            <span style="display: flex;">
              <img class="m-3" width="50px" height="50px" src="../assets/icons/icon_reporte_analitico.png">
              <div style="color: #063865;">
                <h5 style="margin-left: 80px;">
                  <b><!--#003865!--></b>
                </h5>
                <h5><b>Reporte analítico</b></h5>
                <p style="font-size: 12px;line-height: 6px;">Este reporte presenta la liquidación analítica y utilidad neta a partir de un viaje seleccionado</p>
              </div>
            </span>
          </div>
        </div>
        <div class="row justify-content-center mtr-2">
          <div class="col-6 text-left">
            <a (click)="onSearch();" *ngIf="orderSelected.id" style="cursor: pointer; color: #640074 !important;line-height: 3;">
              <i class="fa fa-angle-double-left" aria-hidden="true"></i> Regresar al listado
            </a>
          </div>
          <div class="{{orderSelected.id? 'col-4': 'col-6'}}">
            <div class="search-control">
              <img class="btn-settings" src="../assets/icons/settings.png">
              <input type="search" [(ngModel)]="config.searchText" (search)="onSearch()" class="form-control input-search text-dark" placeholder="Buscar por proveedor, viaje, orden de compra."/>              
            </div>
          </div>
          <div class="col-2" *ngIf="orderSelected.id">
            <button type="submit" class="btn btn-primary btn-user btn-block shadow-sm" printSectionId="print-section" [useExistingCss]="true" ngxPrint><img class="btn-icon-print" src="../assets/icons/ic_print_white.png"/> Imprimir </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!orderSelected.id" class="col-lg-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">O.C.</th>
              <th scope="col">FECHA DE COMPRA</th>
              <th scope="col">FECHA DE LLEGADA</th>
              <th scope="col">PROVEEDOR</th>
              <th scope="col">VIAJE</th>
              <th scope="col">CANTIDAD</th>
              <th scope="col">IMPORTE COMPRAS</th>
              <th scope="col">IMPORTE VENTAS</th>
              <th scope="col">GASTOS DE COMPRAS</th>
              <th scope="col">GASTOS DE VENTAS</th>
              <th scope="col">DESCUENTOS / EXTRAS</th>
              <th scope="col">UTILIDAD NETA</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of rows | paginate: config" (click)="getReport(item.id);" style="cursor: pointer;">
              <td>{{item.id}}</td>
              <td>
                <div style="width: 100px;">
                  {{item.purchaseDate}}
                </div>
              </td>
              <td>
                <div style="width: 100px;">
                  {{item.arrivalDate}}
                </div>
              </td>
              <td>
                <div style="width: 150px;">
                {{item.provider? item.provider.businessName: ''}}
                </div>
              </td>
              <td>{{item.trip}}</td>
              <td>{{item.quantity}}</td>
              <td>{{item.amount | currency }}</td>
              <td>{{item.saleAmount | currency }}</td>
              <td>{{item.expensePurchaseProducts | currency }}</td>
              <td>{{item.expenseSaleProducts | currency }}</td>
              <td>{{item.extrasCost | currency }}</td>
              <td>
                <div style="width: 120px;text-align: right;">
                  {{item.netAmount | currency }}<span class="{{item.netAmount > 0 ? 'netUp': 'nepDown'}}"></span>
                </div>
              </td>
            </tr>
            <tr *ngIf="(rows).length === 0">
              <td colspan="6">No se encontraron elementos</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div *ngIf="!orderSelected.id" class="col-12">
    <div class="row">
      <div class="col-lg-12">
        <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagerChanged($event)">

          <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
              <a (click)="p.previous()" *ngIf="!p.isFirstPage();">
                <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
              </a>
              <a *ngIf="p.isFirstPage();">
                <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
              </a>
          </div>

          <div *ngFor="let page of p.pages" class="page-number" [class.current]="p.getCurrent() === page.value">
              <a (click)="p.setCurrent(page.value);" *ngIf="p.getCurrent() !== page.value">
                  <span>{{ page.label }}</span>
              </a>
              <div *ngIf="p.getCurrent() === page.value">
                  <span>{{ page.label }}</span>
              </div>
          </div>

          <div class="pagination-next" [class.disabled]="p.isLastPage()">
              <a (click)="p.next()" *ngIf="!p.isLastPage();">
                <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
              </a>
              <a *ngIf="p.isLastPage();">
                <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
              </a>
          </div>

        </pagination-template>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 pager">
        <label>Mostrar <select [(ngModel)]="config.itemsPerPage" (change)='onPagerChanged(1);'>
          <option *ngFor="let c of config.options" [ngValue]="c">{{c}}</option>
        </select> Registros por página</label>
      </div>
    </div>
  </div>

    <div *ngIf="orderSelected.id" class="col-12 bordered" id="print-section">
      <table class="table table-responsive main-table">
        <thead>
          <tr>
            <th colspan="3" scope="col" style="vertical-align: top;">
              <div class="company-container">
                <img id="logo" [src]="environment.imagesUrl + company.logo" style="height: 120px;" />
                <div class="datos">
                  <span class="title">{{company.name}}</span>
                  <span>{{company.representative}}</span>
                  <span>R.F.C. {{company.rfc}}</span>
                  <span>{{company.state}}, {{company.city}} C.P. {{company.postalCode}}</span>
                  <span>{{company.address}}</span>
                  <span>TELS. {{company.phone}}</span>
                </div>
              </div>
            </th>
            <th colspan="1" scope="col"></th>
            <th colspan="2" scope="col" style="vertical-align: top;">
              <div class="m-1 pl-3 pr-3 pt-1 pb-1">
                <h6>Liquidación analítica por viaje</h6>
              </div>
            </th>
          </tr>
          
          <tr>
            <th colspan="8">
              <span style="width: 200px;float: right;color: #063865;">
                <span>Utilidad neta</span>
                <div>
                  <b style="font-size: 25px;font-family: poppins-bold !important;">{{orderSelected.amount | currency }}
                  <span style="margin: 8px 20px 0px 0px !important;" class="{{orderSelected.amount > 0 ? 'netUp': 'nepDown'}}"></span>
                  </b>
                </div>
              </span>
              <div *ngIf="orderSelected.transportLines" class="row text-left gray-area m-1 p-1">
                <div class="col-12">
                  <div class="container mb-0">
                    <div class="row text-left gray-area m-1 p-1" style="align-items: center;">
                      <div class="col-3">
                        <h1><b class="primary">{{orderSelected.client.businessName}}</b></h1>
                        <p class="m-0">Cliente</p>
                      </div>
                      <div class="col-3">
                        <p class="m-0"><span class="primary">Tel:</span> {{orderSelected.client.phone}}</p>
                        <p><span class="primary">E-mail:</span> {{orderSelected.client.email}}</p>
                      </div>
                      <div class="col-3">
                        <p><span class="primary">Dirección:</span> {{orderSelected.client.address}}. {{orderSelected.client.city}}</p>
                      </div>
                      <div class="col-3">
                        <p class="m-0">Fecha de Expedición</p>
                        <h1><b class="primary">{{orderSelected.purchaseDate}}</b></h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <td colspan="7" scope="col">
              <h6 class="report-table-title">
                <b>Orden de Compra</b>
              </h6>
              <table class="table table-responsive report-table">
                <thead class="gray-area">
                  <tr>
                    <th scope="col">FECHA</th>
                    <th scope="col">Nº</th>
                    <th scope="col">PROVEEDOR</th>
                    <th scope="col">VIAJE</th>
                    <th scope="col">CANTIDAD</th>
                    <th scope="col">UNIDAD</th>
                    <th scope="col">PRODUCTO</th>
                    <th scope="col">CALIDAD</th>
                    <th scope="col">PRESENTACIÓN</th>
                    <th scope="col">ETIQUETA</th>
                    <th scope="col">TAMAÑO</th>
                    <th scope="col">COSTO</th>
                    <th scope="col">IMPORTE</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="orderSelected">
                    <tr *ngFor="let item of orderSelected.purchaseProducts.list">
                      <td scope="col">{{item.purchaseDate}}</td>
                      <td scope="col">{{item.id}}</td>
                      <td scope="col">{{item.businessName}}</td>
                      <td scope="col">{{item.trip}}</td>
                      <td scope="col">{{item.quantity}}</td>
                      <td scope="col">{{item.units}}</td>
                      <td scope="col">{{item.name}}</td>
                      <td scope="col">{{item.quality}}</td>
                      <td scope="col">{{item.presentation}}</td>
                      <td scope="col">{{item.tag}}</td>
                      <td scope="col">{{item.size}}</td>
                      <td scope="col">{{item.unitPrice | currency}}</td>
                      <td scope="col">{{(item.unitPrice * item.quantity) | currency }}</td>
                    </tr>
                    <tr *ngIf="(orderSelected.purchaseProducts.list).length === 0">
                      <td colspan="13">No se encontraron elementos</td>
                    </tr>
                    <tr>
                      <td scope="col">
                        <b class="total">TOTALES</b>
                      </td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col">
                        <b class="total">{{orderSelected.purchaseProducts.totals.cantidad}}</b>
                      </td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col">
                        <b class="total">{{orderSelected.purchaseProducts.totals.price | currency}}</b>
                      </td>
                      <td scope="col">
                        <b class="total">{{orderSelected.purchaseProducts.totals.importe | currency }}</b>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>

            </td>
          </tr>

          <tr>
            <td colspan="7" scope="col">
              <h6 class="report-table-title">
                <b>Venta</b>
              </h6>
              <table class="table table-responsive report-table">
                <thead class="gray-area">
                  <tr>
                    <th scope="col">FECHA</th>
                    <th scope="col">REMISIÓN</th>
                    <th scope="col">FACTURA</th>
                    <th scope="col">CLIENTE</th>
                    <th scope="col">CANTIDAD</th>
                    <th scope="col">UNIDAD</th>
                    <th scope="col">PRODUCTO</th>
                    <th scope="col">CALIDAD</th>
                    <th scope="col">PRESENTACIÓN</th>
                    <th scope="col">ETIQUETA</th>
                    <th scope="col">TAMAÑO</th>
                    <th scope="col">PRECIO</th>
                    <th scope="col">IMPORTE</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="orderSelected">
                    <tr *ngFor="let item of orderSelected.saleProducts.list">
                      <td scope="col">{{item.saleDate}}</td>
                      <td scope="col">{{item.id}}</td>
                      <td scope="col">{{item.invoice}}</td>
                      <td scope="col">{{item.clientName}}</td>
                      <td scope="col">{{item.quantity}}</td>
                      <td scope="col">{{item.units}}</td>
                      <td scope="col">{{item.productName}}</td>
                      <td scope="col">{{item.quality}}</td>
                      <td scope="col">{{item.presentation}}</td>
                      <td scope="col">{{item.tag}}</td>
                      <td scope="col">{{item.size}}</td>
                      <td scope="col">{{item.price | currency}}</td>
                      <td scope="col">{{(item.price * item.quantity) | currency }}</td>
                    </tr>
                    <tr *ngIf="(orderSelected.saleProducts.list).length === 0">
                      <td colspan="13">No se encontraron elementos</td>
                    </tr>
                    <tr>
                      <td scope="col">
                        <b class="total">TOTALES</b>
                      </td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col">
                        <b class="total">{{orderSelected.saleProducts.totals.cantidad}}</b>
                      </td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col">
                        <b class="total">{{orderSelected.saleProducts.totals.price | currency}}</b>
                      </td>
                      <td scope="col">
                        <b class="total">{{orderSelected.saleProducts.totals.importe | currency }}</b>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>

            </td>
          </tr>

          <tr>
            <td colspan="7" scope="col">
              <h6 class="report-table-title">
                <b>Merma</b>
              </h6>
              <table class="table report-table">
                <thead class="gray-area">
                  <tr>
                    <th scope="col">FECHA</th>
                    <th scope="col">Nº</th>
                    <th scope="col">OBSERVACIONES</th>
                    <th scope="col">CANTIDAD</th>
                    <th scope="col">UNIDAD</th>
                    <th scope="col">PRODUCTO</th>
                    <th scope="col">CALIDAD</th>
                    <th scope="col">PRESENTACIÓN</th>
                    <th scope="col">ETIQUETA</th>
                    <th scope="col">TAMAÑO</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="orderSelected">
                    <tr *ngFor="let item of orderSelected.lossesProducts.list">
                      <td scope="col">{{item.purchaseDate}}</td>
                      <td scope="col">{{item.id}}</td>
                      <td scope="col">{{item.observation}}</td>
                      <td scope="col">{{item.quantity}}</td>
                      <td scope="col">{{item.units}}</td>
                      <td scope="col">{{item.name}}</td>
                      <td scope="col">{{item.quality}}</td>
                      <td scope="col">{{item.presentation}}</td>
                      <td scope="col">{{item.tag}}</td>
                      <td scope="col">{{item.size}}</td>
                    </tr>
                    <tr *ngIf="(orderSelected.lossesProducts.list).length === 0">
                      <td colspan="13">No se encontraron elementos</td>
                    </tr>
                    <tr>
                      <td scope="col">
                        <b class="total">TOTALES</b>
                      </td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col">
                        <b class="total">{{orderSelected.lossesProducts.totals.cantidad}}</b>
                      </td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                      <td scope="col"></td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>

            </td>
          </tr>

          <tr>
            <td colspan="1" scope="col"></td>
            <td colspan="5" scope="col">
              <h6 class="report-table-title">
                <b class="small">Gastos de Compra</b>
              </h6>
              <table class="table report-table">
                <thead class="gray-area">
                  <tr>
                    <th scope="col">CONCEPTO</th>
                    <th scope="col">VALOR</th>
                    <th scope="col">IVA</th>
                    <th scope="col">IMPORTE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of orderSelected.expenseRecordsPurchaseProducts">
                    <td colspan="1">{{item.concept}}</td>
                    <td colspan="1">{{item.value | currency}}</td>
                    <td colspan="1">{{(item.iva?(item.value*0.16):0) | currency}}</td>
                    <td colspan="1">{{(item.iva?(item.value*1.16):item.value) | currency}}</td>
                  </tr>
                  <tr *ngIf="(orderSelected.expenseRecordsPurchaseProducts).length === 0">
                    <td colspan="4">No se encontraron elementos</td>
                  </tr>
                  <tr>
                    <td colspan="2" style="border-bottom: none !important; border-left: none !important;"></td>
                    <td colspan="1" style="color: black !important;">GASTOS DE TRANSPORTE:</td>
                    <td colspan="1"><b>{{orderSelected.totalExpenseRecordsPurchaseProducts | currency}}</b></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="1" scope="col"></td>
            <td colspan="5" scope="col">
              <h6 class="report-table-title">
                <b class="small">Gastos de Venta</b>
              </h6>
              <table class="table report-table">
                <thead class="gray-area">
                  <tr>
                    <th scope="col">CONCEPTO</th>
                    <th scope="col">VALOR</th>
                    <th scope="col">IVA</th>
                    <th scope="col">IMPORTE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of orderSelected.expenseRecordsSaleProducts">
                    <td colspan="1">{{item.concept}}</td>
                    <td colspan="1">{{item.value | currency}}</td>
                    <td colspan="1">{{(item.iva?(item.value*0.16):0) | currency}}</td>
                    <td colspan="1">{{(item.iva?(item.value*1.16):item.value) | currency}}</td>
                  </tr>
                  <tr *ngIf="(orderSelected.expenseRecordsSaleProducts).length === 0">
                    <td colspan="7">No se encontraron elementos</td>
                  </tr>
                  <tr>
                    <td colspan="2" style="border-bottom: none !important; border-left: none !important;"></td>
                    <td colspan="1" style="color: black !important;">GASTOS DE TRANSPORTE:</td>
                    <td colspan="1"><b>{{orderSelected.totalexpenseRecordsSaleProducts | currency}}</b></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr style="text-align: left;">
            <h6 class="report-table-title">
              <b class="small">Información adicional</b>
            </h6>
          </tr>
          
          <tr>
            <td colspan="6" scope="col">
              <div class="container">
                <div class="row">
                  <div class="col-6">
                    <div class="bordered shadow" style="width: 100%">
                      <div class="row">
                        <h6 class="primary m-3">Observaciones de compra</h6>
                      </div>
                      <div class="row mb-2 timeline-line" *ngFor="let item of orderSelected.observationsPurchaseProducts; let isLast = last">
                        <div class="col-2">
                          <i class="fas fa-align-right green-circle"></i>
                          <div class="{{isLast? '': 'line'}}"></div>
                        </div>
                        <div class="col-10">
                          <p class="m-0 text-left">{{item.observation}}</p>
                          <p class="m-0 text-left" style="font-size: 12px;">{{item.registrationDate}}, {{item.user? item.user.username: ''}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="bordered shadow" style="width: 100%">
                      <div class="row">
                        <h6 class="primary m-3">Observaciones de venta</h6>
                      </div>
                      <div class="row mb-2 timeline-line" *ngFor="let item of orderSelected.observationsSaleProducts; let isLast = last">
                        <div class="col-2">
                          <i class="fas fa-align-right green-circle"></i>
                          <div class="{{isLast? '': 'line'}}"></div>
                        </div>
                        <div class="col-10">
                          <p class="m-0 text-left">{{item.observation}}</p>
                          <p class="m-0 text-left" style="font-size: 12px;">{{item.registrationDate}}, {{item.user? item.user.username: ''}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="bordered shadow" style="width: 100%">
                      <table class="table report-table">
                        <thead class="gray-area">
                          <tr>
                            <th scope="col">Extras</th>
                            <th scope="col">%</th>
                            <th scope="col">Importe</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngIf="orderSelected">
                            <tr *ngFor="let item of orderSelected.extraCost">
                              <td scope="col">{{item.clientName}}</td>
                              <td scope="col">{{item.percentage}}</td>
                              <td scope="col">{{item.amount | currency }}</td>
                            </tr>
                            <tr *ngIf="(orderSelected.extraCost).length === 0">
                              <td colspan="13">No se encontraron elementos</td>
                            </tr>
                            <tr>
                              <td scope="col"></td>
                              <td scope="col"></td>
                              <td scope="col">
                                <b class="total"></b>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="bordered shadow" style="width: 100%">
                      <span style="width:200px;color:#063865;text-align:left;">
                        <p class="m-0">Utilidad neta</p>
                        <div>
                          <b style="font-size: 25px;font-family: poppins-bold !important;">{{orderSelected.amount | currency }}
                          <span style="display: inline-flex !important; float: none !important;" class="{{orderSelected.amount > 0 ? 'netUp': 'nepDown'}}"></span>
                          </b>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>

        </thead>
        <tbody id="footer" class="footer" style="border: none !important;">
          <tr>
            <td colspan="1" scope="col" style="text-align: center;">PÁG. 1 DE 1</td>
            <td colspan="1" scope="col"></td>
            <td colspan="1" scope="col">Reporte impreso por: </td>
            <td colspan="2" scope="col">{{profile.name? profile.name + ' ' + profile.lastname: ''}} {{profile.email}}</td>
            <td colspan="1" scope="col" style="border-right: 1px solid;">Fecha y hora: {{today | date: 'dd MMM yyyy hh:mm a'}}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>