import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from './../../app.component';
import { environment } from './../../../environments/environment';
import { FormsService } from './../../services/forms/forms.service';
import { AdminService } from './../../services/admin/admin.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

  public formCompany:FormGroup;
  public url: any;
  public files: File[] = [];
  public responseMessage:String;
  public resLogoMessage: any = {
    message : "",
    type: "success"
  }
  public company: any = {
    id: 0,
    rfc: "",
    name: "",
    state: "",
    postalCode: "",
    city: "",
    address: "",
    phone: "",
    representative: "",
    logo: ""
  }

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService,
    private spinner: NgxSpinnerService, private toastService: ToastrService, private adminService: AdminService ) { }

  ngOnInit(): void {
  	this.formCompany = this.formBuilder.group({
      rfc: ['', [Validators.required]],
      name: ['', [Validators.required]],
      state: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      address: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      representative: ['', [Validators.required]],
    });
    this.spinner.show();
    this.adminService.getCompany().subscribe(
      (res:any) => {
        this.company = res;
        this.formCompany.patchValue(res);
        this.url = environment.imagesUrl + res.logo;
        this.adminService.onCompanyChanged(res);
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.responseMessage = err.error.message;
        this.spinner.hide();
      }
    );
  }

  public openLogoModal(){
    this.modalService.getModal('logoModal').open();
    this.files = new Array<File>();
  }

  public onSelect(event) {
    this.files.push(...event.addedFiles);
  }

  public onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  public submitlogo(){
    var file = this.files[0];
    if(file === null){
      this.toastService.warning("Faltan datos obligatorios.", "ADVERTENCIA")
      return;
    }

    if(this.files.length == 0){
      this.toastService.warning("Faltan datos obligatorios.", "ADVERTENCIA")
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    this.spinner.show();
    this.adminService.changeCompanyLogo(this.company.id, formData)
      .subscribe(
        (res) => {
          this.resLogoMessage.message = "Logo de la compañía actualizado con éxito";
          this.resLogoMessage.type = "success";
          this.url = environment.imagesUrl + res.logo;
          this.files = new Array<File>();
          this.company = res;
          this.adminService.onCompanyChanged(res);
          this.spinner.hide();
        },(err) => {
          if(err.error.message === null){
            this.resLogoMessage.message = "Ocurrió un error al cambiar el logo de la compañía";
          }else{
            this.resLogoMessage.message = err.error.message;
          }
          this.resLogoMessage.type = "failure";
          this.spinner.hide();
        }
      );
  }

  public updateCompany() {
    if (!this.formCompany.valid) {
       this.formsService.validateAllFormFields(this.formCompany);
    }else {
      this.spinner.show();
      this.formCompany.controls["rfc"].patchValue(this.formCompany.controls["rfc"].value.replace(/[^a-zA-Z0-9]/g, ''));
      this.formCompany.controls["phone"].patchValue(this.formCompany.controls["phone"].value.replace(/[^a-zA-Z0-9]/g, ''));
      this.adminService.updateCompany(this.company.id, this.formCompany.value).subscribe(
        (res) => {
          this.modalService.getModal('successModal').open();
          this.company = res;
          this.url = environment.imagesUrl + res.logo;
          this.adminService.onCompanyChanged(res);
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.spinner.hide();
        }
      );
    }
  }

}