import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { environment } from './../../../environments/environment';
import { FormsService } from './../../services/forms/forms.service';
import { AdminService } from './../../services/admin/admin.service';
import { AuthService } from './../../services/auth/auth.service';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css']
})
export class EmployeesComponent implements OnInit {
  @ViewChild("table", { static: true }) table: any;
  public profile: any = null;
  public formEmployee:FormGroup;
  public environment = environment;
  public itemSelected: any = {user: {rol: {}}};
  
  //table, pagination
	public config = {
    id: 'employees',
    searchText: '',
    itemsPerPage: 20,
	  currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100]
	};
  public rows: any = [];

  //picture
  public files: File[] = [];
  public tempPhotoUrl: any;
  public responseMessage:String;

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private formsService: FormsService, 
    public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, 
    private adminService: AdminService, private toastService: ToastrService) { }

  ngOnInit(): void {
    this.authService.currentProfile.subscribe(profile => {
        if(profile && !(Object.keys(profile).length === 0)){
        console.log("profile: ", profile);
        this.profile = profile;
      }        
    });
    this.formEmployee = this.formBuilder.group({
      rfc: ['', [Validators.required]],
      name: ['', [Validators.required]],
      firstSurname: ['', [Validators.required]],
      secondSurname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      rol: ['', [Validators.required]]
    });
    this.populateEmployees();
  }

  public populateEmployees(){
    this.spinner.show();
    this.adminService.searchEmployees(this.config).subscribe(
      res => {
        this.rows = res.data;
        this.config.totalItems = res.total;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public submitEmployee(id){
    if(id === undefined){
      this.addEmployee();
    }else{
      this.updateEmployee(id);
    }
  }

  public addEmployee() {
    console.log("addEmployee-->");
    this.config.searchText = "";
    this.config.currentPage = 1;
    var hasImage = false;
    var file = this.files[0];
    if(file != null){
      hasImage = true;
    }

    const formData = new FormData();
    formData.append('file', file);

    if (!this.formEmployee.valid) {
       this.formsService.validateAllFormFields(this.formEmployee);
    }else {
      this.spinner.show();
      this.formEmployee.controls["phone"].patchValue(this.formEmployee.controls["phone"].value.replace(/[^a-zA-Z0-9]/g, ''));
      this.adminService.addEmployee(this.formEmployee.value).subscribe(
        (res) => {
          if(hasImage){
            this.adminService.changeEmployeeLogo(res.id, formData).subscribe(
              (res2) => {
                this.files = new Array<File>();
                this.modalService.getModal('employeeModal').close();
                this.openSuccessModal("¡Empleado agregado!", "Su registro ha sido guardado exitosamente.");
                this.files = new Array<File>();
                this.populateEmployees();
                this.spinner.hide();
              },
              (err) => {
                console.log("err", err);
                this.responseMessage = err.error.message;
                this.spinner.hide();
              }
            )            
          }else{
            this.modalService.getModal('employeeModal').close();
            this.openSuccessModal("¡Empleado agregado!", "Su registro ha sido guardado exitosamente.");
            this.populateEmployees();
            this.spinner.hide();
          }
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public updateEmployee(id){
    console.log("updateEmployee-->");
    var hasImage = false;
    var file = this.files[0];
    if(file != null){
      hasImage = true;
    }

    const formData = new FormData();
    formData.append('file', file);

    console.log("updateEmployee-->", id);
    if (!this.formEmployee.valid) {
       this.formsService.validateAllFormFields(this.formEmployee);
    }else {
      this.spinner.show();
      this.formEmployee.controls["phone"].patchValue(this.formEmployee.controls["phone"].value.replace(/[^a-zA-Z0-9]/g, ''));
      this.adminService.updateEmployee(id, this.formEmployee.value).subscribe(
        (res) => {
          if(hasImage){
            this.adminService.changeEmployeeLogo(id, formData).subscribe(
              (res2) => {
                this.files = new Array<File>();
                this.modalService.getModal('employeeModal').close();
                this.openSuccessModal("¡Empleado actualizado!", "Su registro ha sido modificado exitosamente.");
                this.files = new Array<File>();
                this.populateEmployees();
                this.spinner.hide();
              },
              (err) => {
                console.log("err", err);
                this.responseMessage = err.error.message;
                this.spinner.hide();
              }
            )
          }else{
            this.modalService.getModal('employeeModal').close();
            this.openSuccessModal("¡Empleado actualizado!", "Su registro ha sido modificado exitosamente.");
            this.populateEmployees();
            this.spinner.hide();
          }
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public deleteEmployee(id){
    console.log("deleteEmployee-->", id);
    this.spinner.show();
    this.adminService.deleteEmployee(id).subscribe(
      (res) => {
        this.modalService.getModal('deleteModal').close();
        this.openSuccessModal("¡Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.populateEmployees();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.responseMessage = err.error.message;
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  // actions
  edit(item) {
    this.itemSelected = item;
    this.tempPhotoUrl = null;
    this.openEmployeeModal(item);
  }

  enable(item){
    this.activeEmploye(item, 1);
  }

  disable(item){
    this.activeEmploye(item, 0);
  }

  activeEmploye(item, status){
    this.itemSelected = item;
    this.adminService.enableEmployee(item.id, { status: status })
      .subscribe(
        res => {
          if(res.status){
            this.openSuccessModal("¡Activado exitosamente!", "");
          }else{
            this.openSuccessModal("Desactivado exitosamente!", "");  
          }
          this.populateEmployees();
        },
        err => {
          console.log("error ->", err);
        }
      );
  }

  delete(item) {
    this.itemSelected = item;
    this.modalService.open("deleteModal");
  }

  changePermissions(item){

  }

  //modals
  public openEmployeeModal(item = null){
    this.formEmployee.reset();
    this.itemSelected = {user: {rol: {}}};
    this.tempPhotoUrl = null;
    if(item){ //editing
      this.itemSelected = item;
      item['email'] = item.user.email;
      item['rol'] = item.user.rol.id;
      this.formEmployee.patchValue(item);
    }
    this.modalService.getModal('employeeModal').open();
    this.files = new Array<File>();
  }

  public openPictureModal(){
    this.modalService.getModal('pictureModal').open();
    this.files = new Array<File>();
  }
  public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal');
    this.modalService.setModalData(obj, 'successModal');
    this.modalService.getModal('successModal').open();
  }

  //drops
  public onSelect(event) {
    this.files.push(...event.addedFiles);
    var file = this.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.tempPhotoUrl = event.target.result;
      }
    }
  }

  public onSearch(){
    this.config.currentPage = 1;
    this.populateEmployees();
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
    this.populateEmployees();
  }

  public onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

}