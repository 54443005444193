<section id="reports" class="container justify-content-center text-center p-3">
  <article class="row justify-content-center">
    <div class="col-12">
      <!--
      <div style="position: absolute; color: #43425d;">
      !-->
      <div style="color: #43425d;">
        <h5><b></b></h5>
        <h5><b>Selecciona el tipo de reporte</b></h5>
      </div>
      <ngb-tabset type="pills" justify="center" #t="ngbTabset">
        <!-- Reporte Analítico !-->
        <ngb-tab class="tab-wizard" id="tab-selectbyid1">
          <ng-template ngbTabTitle>
            <div id="img-reporte-analitico" class="circle"></div>
            <span class="title">Reporte Analítico</span>
          </ng-template>
          <ng-template ngbTabContent>
            <app-reporte-analitico></app-reporte-analitico>
          </ng-template>
        </ngb-tab>
        <!-- Reporte de Compras !-->
        <ngb-tab class="tab-wizard" id="tab-selectbyid2">
          <ng-template ngbTabTitle>
            <div id="img-reporte-compras" class="circle"></div>
            <span class="title">Reporte de Compras</span>
          </ng-template>
          <ng-template ngbTabContent>
            <app-reporte-compras></app-reporte-compras>
          </ng-template>
        </ngb-tab>
        <!-- Reporte de Ventas !-->
        <ngb-tab class="tab-wizard" id="tab-selectbyid3">
          <ng-template ngbTabTitle>
            <div id="img-reporte-ventas" class="circle"></div>
            <span class="title">Reporte de Ventas</span>
          </ng-template>
          <ng-template ngbTabContent>
            <app-reporte-ventas></app-reporte-ventas>
          </ng-template>
        </ngb-tab>
        <!-- Reporte de Descuentos !-->
        <ngb-tab class="tab-wizard" id="tab-selectbyid4">
          <ng-template ngbTabTitle>
            <div id="img-reporte-descuentos" class="circle"></div>
            <span class="title">Reporte de Descuento sobre Ventas</span>
          </ng-template>
          <ng-template ngbTabContent>
            <app-reporte-descuentos></app-reporte-descuentos>
          </ng-template>
        </ngb-tab>
        <!-- Reporte de Líneas de Transporte !-->
        <ngb-tab class="tab-wizard" id="tab-selectbyid5">
          <ng-template ngbTabTitle>
            <div id="img-reporte-trunk" class="circle"></div>
            <span class="title">Reporte de Líneas de Transporte</span>
          </ng-template>
          <ng-template ngbTabContent>
            <app-reporte-lineas-transporte></app-reporte-lineas-transporte>
          </ng-template>
        </ngb-tab>
        
      </ngb-tabset>
    </div>
  </article>
</section>

<script type="text/javascript">
  $("#content-wrapper").style("background", "#f2f2f2");
</script>

<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div *ngIf="successModal.hasData()" class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">{{ successModal.getData().title }}</h1>
        <p class="h6" style="font-size: 0.9rem;">{{ successModal.getData().subtitle }} </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>