import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { MouseEvent } from '@agm/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbTabset, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../environments/environment';
import { FormsService } from './../../services/forms/forms.service';
import { PurchasesService } from './../../services/purchases/purchases.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  @ViewChild('t') tabs:NgbTabset;
  public currentTab:number = 1;
  public orderSelected: any = {
    id: 0,
    trip: '',
    purchaseDate: '',
    arrivalDate: '',
    quantity: 0,
    amount: 0,
    provider: {},
    transportLines: {},
    driver: {},
    productsOrder: [],
    observation: [],
    expenseRecordsTransportLine: [],
    expenseRecordsAdditional: [],
  };

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private orderService: PurchasesService, private toastService: ToastrService, public route:ActivatedRoute,
    @Inject(DOCUMENT) document,  private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if(params.id){
        this.orderSelected.id = +params['id'];
        this.getOrder(this.orderSelected.id);
      }
    });
  }

  public getOrder(id){
    console.log("getOrder-->", id);
    this.spinner.show();
    this.orderService.getPurchase(id).subscribe(
      (res) => {
        console.log("Order-res->", res);
        this.orderSelected = res;
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public beforeChange($event: NgbTabChangeEvent) {
    console.log("$event", $event);
    switch ($event.nextId) {
      case 'tab-selectbyid1':
        this.currentTab = 1;
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.remove("active");
            document.getElementById('tab-selectbyid3').classList.remove("active");
            document.getElementById('tab-selectbyid4').classList.remove("active");
            document.getElementById('tab-selectbyid5').classList.remove("active");
            document.getElementById('tab-selectbyid6').classList.remove("active");
          }, 100);
        break;
      case 'tab-selectbyid2':
        console.log("this.currentTab", this.currentTab);
        if(this.currentTab < 2 && !this.orderSelected.id){
          $event.preventDefault();
        }else{
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.add("active");
            document.getElementById('tab-selectbyid3').classList.remove("active");
            document.getElementById('tab-selectbyid4').classList.remove("active");
            document.getElementById('tab-selectbyid5').classList.remove("active");
            document.getElementById('tab-selectbyid6').classList.remove("active");
          }, 100);
          this.currentTab = 2;
        }
        break;
      case 'tab-selectbyid3':
        if(this.currentTab < 3 && !this.orderSelected.id){
          $event.preventDefault();
        }else{
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.add("active");
            document.getElementById('tab-selectbyid3').classList.add("active");
            document.getElementById('tab-selectbyid4').classList.remove("active");
            document.getElementById('tab-selectbyid5').classList.remove("active");
            document.getElementById('tab-selectbyid6').classList.remove("active");
          }, 100);
          this.currentTab = 3;
        }
        break;
      case 'tab-selectbyid4':
        if(this.currentTab < 4 && !this.orderSelected.id){
          $event.preventDefault();
        }else{
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.add("active");
            document.getElementById('tab-selectbyid3').classList.add("active");
            document.getElementById('tab-selectbyid4').classList.add("active");
            document.getElementById('tab-selectbyid5').classList.remove("active");
            document.getElementById('tab-selectbyid6').classList.remove("active");
          }, 100);
          this.currentTab = 4;
        }
        break;
      case 'tab-selectbyid5':
        if(this.currentTab < 5 && !this.orderSelected.id){
          $event.preventDefault();
        }else{
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.add("active");
            document.getElementById('tab-selectbyid3').classList.add("active");
            document.getElementById('tab-selectbyid4').classList.add("active");
            document.getElementById('tab-selectbyid5').classList.add("active");
            document.getElementById('tab-selectbyid6').classList.remove("active");
          }, 100);
          this.currentTab = 5;
        }
        break;
      case 'tab-selectbyid6':
        if(this.currentTab < 6 && !this.orderSelected.id){
          $event.preventDefault();
        }else{
          setTimeout(()=>{
            document.getElementById('tab-selectbyid1').classList.add("active");
            document.getElementById('tab-selectbyid2').classList.add("active");
            document.getElementById('tab-selectbyid3').classList.add("active");
            document.getElementById('tab-selectbyid4').classList.add("active");
            document.getElementById('tab-selectbyid5').classList.add("active");
            document.getElementById('tab-selectbyid6').classList.add("active");
          }, 100);
          this.currentTab = 6;
        }
        break;
      default:
        // code...
        break;
    }
  }

  public continue($event){
    this.currentTab++;
    this.tabs.select('tab-selectbyid' + this.currentTab);
  }

  public createOrder($event){
    this.spinner.show();
    this.orderService.addPurchase($event).subscribe(
      (res) => {
        this.orderSelected = res;
        this.spinner.hide();
        this.continue($event);
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
        this.toastService.warning(err.error.message);
      }
    );
  }

  public updateOrder($event){
    this.spinner.show();
    this.orderService.updatePurchase(this.orderSelected.id, $event).subscribe(
      (res) => {
        this.orderSelected = res;
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
        this.toastService.warning(err.error.message);
      }
    );
  }
  
}