import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MouseEvent } from '@agm/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../../environments/environment';
import { FormsService } from './../../../services/forms/forms.service';
import { AdminService } from './../../../services/admin/admin.service';

@Component({
  selector: 'app-seasons',
  templateUrl: './seasons.component.html',
  styleUrls: ['./seasons.component.css']
})
export class SeasonsComponent implements OnInit {
  @Input() provider;
  public formSeason:FormGroup;
  public environment = environment;
  public itemSelected: any = {};

  //table, pagination
  public config = {
    id: 'seasons',
    providerId: '',
    searchText: '',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100]
  };
  public rows: any = [];
  public years: any = [];

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private adminService: AdminService, private toastService: ToastrService, public route:ActivatedRoute) {
    this.route.paramMap.subscribe( params => {
      this.config.providerId = params.get('providerId');
    });
    var year = new Date().getFullYear();
    var range = [];
    range.push(year);
    for (var i = 1; i < 7; i++) {
        range.push(year + i);
    }
    this.years = range;
  }

  ngOnInit(): void {
    this.formSeason = this.formBuilder.group({
      codeSeason: ['', [Validators.required]],
      year: ['', [Validators.required]],
    });
    this.populateSeasons();
  }

  public populateSeasons(){
    this.spinner.show();
    this.adminService.searchSeasons(this.config).subscribe(
      res => {
        this.rows = res.data;
        this.config.totalItems = res.total;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public submitSeason(id){
    if(id === undefined){
      this.addSeason();
    }else{
      this.updateSeason(id);
    }
  }

  public addSeason() {
    console.log("addSeason-->");
    this.config.searchText = "";
    this.config.currentPage = 1;

    if (!this.formSeason.valid) {
       this.formsService.validateAllFormFields(this.formSeason);
    }else {
      this.spinner.show();
      this.formSeason.value['provider'] = this.config.providerId;
      this.adminService.addSeason(this.formSeason.value).subscribe(
        (res) => {
          this.itemSelected = res;
          this.modalService.getModal('seasonModal').close();
          this.openSuccessModal("Temporada agregada!", "Su registro ha sido guardado exitosamente.");
          this.populateSeasons();
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public updateSeason(id){
    console.log("addSeason-->");
    console.log("updateSeason-->", id);
    if (!this.formSeason.valid) {
       this.formsService.validateAllFormFields(this.formSeason);
    }else {
      this.spinner.show();
      this.adminService.updateSeason(id, this.formSeason.value).subscribe(
        (res) => {
          this.itemSelected = res;
          this.modalService.getModal('seasonModal').close();
          this.openSuccessModal("Temporada actualizada!", "Su registro ha sido modificado exitosamente.");
          this.populateSeasons();
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public deleteSeason(id){
    console.log("deleteSeason-->", id);
    this.spinner.show();
    this.adminService.deleteSeason(id).subscribe(
      (res) => {
        this.modalService.getModal('deleteModal').close();
        this.openSuccessModal("¡Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.populateSeasons();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  // actions
  edit(item) {
    this.itemSelected = item;
    this.openSeasonModal(item);
  }

  finish(item){
    this.itemSelected = item;
    if(item.status){
      this.modalService.open("finishModal");
    }else{
      this.toastService.warning("La temporada ya finalizó", "ADVERTENCIA");
    }
  }

  delete(item) {
    this.itemSelected = item;
    this.modalService.open("deleteModal");
  }

  //modals
  public openSeasonModal(item = null){
    this.formSeason.reset();
    this.itemSelected = {user: {rol: {}}};
    if(item){ //editing
      this.itemSelected = item;
      this.formSeason.patchValue(item);
    }else{
    }
    this.modalService.getModal('seasonModal').open();
  }

  public openPictureModal(){
    this.modalService.getModal('pictureModal').open();
  }
  
  public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal');
    this.modalService.setModalData(obj, 'successModal');
    this.modalService.getModal('successModal').open();
  }

  public onSearch(){
    this.config.currentPage = 1;
    this.populateSeasons();
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
    this.populateSeasons();
  }

  public finalizeSeason(id){
    console.log("finalizeSeason-->", id);
    this.spinner.show();
    this.adminService.finalizeSeason(id).subscribe(
      (res) => {
        this.modalService.getModal('finishModal').close();
        this.openSuccessModal("Temporada Finalizada!", "Su registro ha sido finalizado exitosamente.");
        this.populateSeasons();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

}