import { Component, OnInit, ViewChild, Input, Inject, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MouseEvent } from '@agm/core';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { LossesService } from './../../../../services/losses/losses.service';
declare var $: any;

@Component({
  selector: 'app-inventory-lost',
  templateUrl: './inventory-lost.component.html',
  styleUrls: ['./inventory-lost.component.css']
})
export class InventoryLostComponent implements OnInit {
  @Output() updateLost2 = new EventEmitter<any>();
  @Input() lostSelected;

  public productsSelected:{} = [];
  //table, pagination
  public rows: any = [];
  config = {
    id: 'product-inventory',
    searchText: '',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100],
    filter: { 
      id: '',
      purchaseDate: '',
      trip: '',
      tag: '',
      presentation: '',
      units: ''
    },
    order: {
      field: 'id',
      reverse: false
    }
  };

  constructor(private formBuilder: FormBuilder, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, 
    private toastService: ToastrService, public route:ActivatedRoute,
    @Inject(DOCUMENT) document, private lossesService:LossesService) { }

  ngOnInit(): void {
    this.getProductInventory();
  }

  public getProductInventory(){
    this.spinner.show();
    this.lossesService.searchLossesInventory(this.lostSelected.id, this.config).subscribe(
      res => {
        this.rows = res;
        var i = 0;
        for (var row of this.rows) {
          if(this.productsSelected[row.uniqueProduct]){
            var product = this.productsSelected[row.uniqueProduct];
            this.rows[i]['customQuantity'] = product.quantity;
            this.rows[i]['customPrice'] = product.price;

          }else if(row['productsSelected']){
            this.rows[i]['customQuantity'] = row.productsSelected.quantity;
            this.rows[i]['customPrice'] = row.productsSelected.price;

            this.productsSelected[row.uniqueProduct] = {
              "uniqueProduct": row.uniqueProduct,
              "quantity": row.productsSelected.quantity,
              "price": row.productsSelected.price,
              "order": row.productsOrderId
            }
          }else{
            this.rows[i]['customQuantity'] = 0;
            this.rows[i]['customPrice'] = row.unitPrice;
          }
          i++;
        }
        this.spinner.hide();
        setTimeout(()=>{
          try{
            $(".input-spinner").inputSpinner();
          }catch(e){
            console.log("e:", e);
          }
        }, 100);
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public onItemChanged(item){
    var id = item.uniqueProduct;
    this.productsSelected[id] = {
      "uniqueProduct": id,
      "quantity": item.customQuantity,
      "price": 0,
      "order": item.productsOrderId
    };
  }

  public submitInventory(){
    const products = Object.entries(this.productsSelected).map(
      ([Label, content]) => (content)
    );
    console.log("submitInventory-->", products);
    this.lossesService.updateInventory(this.lostSelected.id, products).subscribe(
      (res) => {
        this.lostSelected = res;
        this.updateLost2.next(this.lostSelected);
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  public onSearch(searchText){
    this.spinner.show();
    this.config.searchText = searchText;
    this.config.currentPage = 1;
    setTimeout(()=>{
      try{
        $(".input-spinner:only-child").inputSpinner();
        this.spinner.hide();
      }catch(e){
        console.log("e:", e);
      }
    }, 100);
  }

  public onSort(field){
    this.spinner.show();
    this.config.order.field = field;
    this.config.currentPage = 1;
    setTimeout(()=>{
      try{
        $(".input-spinner:only-child").inputSpinner();
        this.spinner.hide();
      }catch(e){
        console.log("e:", e);
      }
    }, 100);
  }

  public onPagerChanged(page){
    this.spinner.show();
    this.config.currentPage = page;
    setTimeout(()=>{
      try{
        $(".input-spinner:only-child").inputSpinner();
        this.spinner.hide();
      }catch(e){
        console.log("e:", e);
      }
    }, 100);
  }

}