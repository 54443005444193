import { Component, OnInit, ViewChild, Input,  OnChanges, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../environments/environment';
import { FormsService } from './../../services/forms/forms.service';
import { AdminService } from './../../services/admin/admin.service';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.css']
})
export class DriversComponent implements OnInit, OnChanges {
  @ViewChild("table", { static: true }) table: any;
  @Input() transportLine;
  public currentTransportLine:any = {id:0};

  public formDriver:FormGroup;
  public environment = environment;
  public itemSelected: any = {};
  
  //table, pagination
  public config2 = {
    id: 'employees',
    searchText: '',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100]
  };
  public rows: any = [];

  public responseMessage:String;

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private adminService: AdminService, private toastService: ToastrService) { }

  ngOnInit(): void {
    this.adminService.currentTransportLine.subscribe(transportLine => {
      this.currentTransportLine = transportLine;
      console.log("currentTransportLine: ", transportLine);
    });

    this.formDriver = this.formBuilder.group({
      name: ['', [Validators.required]],
      firstSurname: ['', [Validators.required]],
      secondSurname: ['', [Validators.required]],
      phone: ['', [Validators.required]]
    });
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    let log: string[] = [];
    for (let propName in changes) {
      if(propName == 'transportLine'){
        this.currentTransportLine = this.transportLine;
        this.populateDrivers();
      }
    }
  }

  public populateDrivers(){
    this.spinner.show();
    this.adminService.searchDrivers(this.config2, this.currentTransportLine.id).subscribe(
      res => {
        this.rows = res.data;
        this.config2.totalItems = res.total;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public submitDriver(id){
    if(id === undefined){
      this.adddriver();
    }else{
      this.updateDriver(id);
    }
  }

  public adddriver() {
    console.log("adddriver-->");
    this.config2.searchText = "";
    this.config2.currentPage = 1;

    if (!this.formDriver.valid) {
       this.formsService.validateAllFormFields(this.formDriver);
    }else {
      this.spinner.show();
      this.formDriver.value['transportLines'] = this.currentTransportLine.id;
      this.adminService.addDriver(this.formDriver.value).subscribe(
        (res) => {
          this.openSuccessModal("¡Chofer agregado!", "Su registro ha sido guardado exitosamente.");
          this.clearForm();
          this.populateDrivers();
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public updateDriver(id){
    console.log("updatedriver-->", id);
    if (!this.formDriver.valid) {
       this.formsService.validateAllFormFields(this.formDriver);
    }else {
      this.spinner.show();
      this.adminService.updateDriver(id, this.formDriver.value).subscribe(
        (res) => {
          this.openSuccessModal("¡Chofer actualizado!", "Su registro ha sido modificado exitosamente.");
          this.clearForm();
          this.populateDrivers();
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public deleteDriver(id){
    console.log("deletedriver-->", id);
    this.spinner.show();
    this.adminService.deleteDriver(id).subscribe(
      (res) => {
        this.modalService.getModal('deleteModal').close();
        this.openSuccessModal("¡Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.clearForm();
        this.populateDrivers();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.responseMessage = err.error.message;
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  public clearForm(){
    this.formDriver.reset();
    this.itemSelected = {};
  }

  // actions
  edit(item) {
    this.itemSelected = item;
    this.openDriverModal(item);
  }

  active(item, enabled){
    this.itemSelected = item;
    this.openSuccessModal("¡Activado exitosamente!", "");
  	/*
    this.jobRequestService
      .updatePersonalinformation({ enabled: true }, item.id)
      .subscribe(
        res => {
          this.toast.success("Activado", "Usuario");
          this.ngOnInit();
        },
        err => {
          console.log("error ->", err);
        }
      );
      */
  }

  delete(item) {
    this.itemSelected = item;
    this.modalService.open("deleteModal");
  }

  changePermissions(item){

  }

  //modals
  public openDriverModal(item = null){
    this.formDriver.reset();
    this.itemSelected = {};
    if(item){ //editing
      this.itemSelected = item;
      this.formDriver.patchValue(item);
    }
  }

  public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal');
    this.modalService.setModalData(obj, 'successModal');
    this.modalService.getModal('successModal').open();
  }


  public onSearch(){
    this.config2.currentPage = 1;
    this.populateDrivers();
  }

  public onPagerChanged($event){
    this.config2.currentPage = $event;
    this.populateDrivers();
  }

}