<section class="container justify-content-center text-center p-0">
  <article class="row justify-content-center" id="print-section">
    <div class="col-12">
      <h4 class="catalog-title font-weight-bold text-dark mb-3">
        {{product.name}}
      </h4>
      <div class="container">
        <div class="row mb-5 non-printable" style="align-items: baseline;">
          <div class="col-lg-6 col-8">
            <div class="search-control">
              <i class="fa fa-search"></i>
              <input type="search" #searchInput [(ngModel)]="config.searchText" (search)="onSearch()" class="form-control input-search text-dark" placeholder="Buscar por orden de compra (O.C) o viaje."/>
            </div>
          </div>
          <div class="col-lg-6 col-4 text-right"></div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table" style="border: none !important;">
                <thead>
                  <th scope="col">O.C</th>
                  <th scope="col">FECHA COMPRA</th>
                  <th scope="col">VIAJE</th>
                  <th scope="col">UNIDAD</th>
                  <th scope="col">PRODUCTO</th>
                  <th scope="col">CALIDAD</th>
                  <th scope="col">PRESENTACIÓN</th>
                  <th scope="col">ETIQUETA</th>
                  <th scope="col">COSTO DE COMPRA</th>
                  <th scope="col" class="size" *ngFor="let size of sizesKeys">
                    {{size}}
                  </th>
                   <th scope="col" class="size">Total</th>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rows | paginate: config">
                    <td>{{item.id}}</td>
                    <td>{{item.purchaseDate}}</td>
                    <td>{{item.trip}}</td>
                    <td>{{item.units}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.quality}}</td>
                    <td>{{item.presentation}}</td>
                    <td>{{item.tag}}</td>
                    <td>{{item.unitPrice | currency}}</td>
                    <td scope="col" *ngFor="let size of sizesKeys" class="{{item.sizes[size] == 0 ? 'emptySize' : ''}}">
                      <div *ngIf="item.sizes[size] == 0"></div>
                      <div *ngIf="item.sizes[size] > 0">
                        {{item.sizes[size]}}
                      </div>
                    </td>
                    <td>
                      <b class="font-weight-bold">
                        {{item.total}}
                      </b>
                   </td>
                  </tr>
                  <tr>
                    <td style="border: none !important;"></td>
                    <td style="border: none !important;"></td>
                    <td style="border: none !important;"></td>
                    <td style="border: none !important;"></td>
                    <td style="border: none !important;"></td>
                    <td style="border: none !important;"></td>
                    <td style="border: none !important;"></td>
                    <td style="border: none !important;">COSTO PROMEDIO:</td>
                    <td>
                      <b class="font-weight-bold">{{average | currency}}</b>
                    </td>
                    <td scope="col" *ngFor="let size of sizesKeys">
                      <div><b class="font-weight-bold">
                        {{totals[size].quantity}}
                      </b></div>
                    </td>
                    <td scope="col">
                      <div><b class="font-weight-bold">
                        {{total}}
                      </b></div>
                    </td>
                  </tr>
                  <tr *ngIf="(rows).length === 0">
                    <td colspan="9">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mtr-2 non-printable">
          <div class="col-3">
            <button type="submit" class="btn btn-primary btn-user btn-block shadow-sm" printSectionId="print-section" [useExistingCss]="true" ngxPrint>IMPRIMIR<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
            </button>
          </div>
          <div class="col-2">
             <button type="cancel" [routerLink]="['/inventory']" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>


