import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient, private datePipe: DatePipe,) {}

  getReporteAnalitico(id) {
      return this.http.get<any>(environment.apiUrl + '/reports/' + id + '/purchaseProducts', httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/' + id + '/purchaseProducts', data),
          error => throwError(error)
        )
      );
  }

  /* Reporte Compras */
  getResumenCompras(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/reports/totalPurchaseProducts/' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/totalPurchaseProducts/', data),
          error => throwError(error)
        )
      );
  }
  
  getGraphCompras(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/reports/graphPurchaseProducts/' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/graphPurchaseProducts/', data),
          error => throwError(error)
        )
      );
  }

  getReporteComprasPorProveedor(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/reports/providerPurchaseProducts/' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/providerPurchaseProducts/', data),
          error => throwError(error)
        )
      );
  }

  getReporteComprasHistorico(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd') +
                 '&providerId=' + config.provider;

      return this.http.get<any>(environment.apiUrl + '/reports/historyPurchaseProducts/' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/historyPurchaseProducts/', data),
          error => throwError(error)
        )
      );
  }

  /* Reporte Ventas */
  getResumenVentas(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/reports/totalSaleProducts/' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/totalSaleProducts/', data),
          error => throwError(error)
        )
      );
  }
  
  getGraphVentas(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/reports/graphSaleProducts/' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/graphSaleProducts/', data),
          error => throwError(error)
        )
      );
  }


  getReporteVentasPorCliente(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/reports/clientSaleProducts/' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/historySaleProducts/', data),
          error => throwError(error)
        )
      );
  }

  getReporteVentasHistorico(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd') +
                 '&clientId=' + config.client;

      return this.http.get<any>(environment.apiUrl + '/reports/historySaleProducts/' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/historySaleProducts/', data),
          error => throwError(error)
        )
      );
  }
  
  /*  Reporte Linea de Transporte */

  getResumenLineasTransporte(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/reports/totalTransportLines' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/totalTransportLines', data),
          error => throwError(error)
        )
      );
  }

  getGraphLineasTransporte(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/reports/graphTransportLines' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/graphTransportLines', data),
          error => throwError(error)
        )
      );
  }
  
  getReporteGastosLineasTransporte(config) {
      var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/reports/transportLines' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/transportLines', data),
          error => throwError(error)
        )
      );
  }

  getReporteLineaTransporteGastosVentas(id, config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/saleProducts/' + id + '/reportTransportLine' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/saleProducts/' + id + '/reportTransportLine', data),
          error => throwError(error)
        )
      );
  }

  getReporteLineaTransporteGastosCompras(id, config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/purchaseProducts/' + id + '/reportTransportLine' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/purchaseProducts/' + id + '/reportTransportLine', data),
          error => throwError(error)
        )
      );
  }


  /*  Descuentos */

  getResumenDescuentos(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/reports/totalDiscountSaleProducts' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/totalDiscountSaleProducts', data),
          error => throwError(error)
        )
      );
  }

  getGraphDescuentos(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/reports/graphDiscountSaleProducts' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/graphDiscountSaleProducts', data),
          error => throwError(error)
        )
      );
  }
  
  getReporteDescuentosPorCliente(config) {
      var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/reports/discountSaleProducts' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/discountSaleProducts', data),
          error => throwError(error)
        )
      );
  }

  getReporteDescuentosDetalle(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd') +
                 '&clientId=' + config.clientId;

      return this.http.get<any>(environment.apiUrl + '/reports/saleDiscount' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/saleDiscount', data),
          error => throwError(error)
        )
      );
  }

  /* Losses */
  getGraphLosses(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/reports/graphLosses/' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/graphLosses/', data),
          error => throwError(error)
        )
      );
  }

  getGraphNet(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get<any>(environment.apiUrl + '/reports/graphNetAmount/' + params, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log('/reports/graphNetAmount/', data),
          error => throwError(error)
        )
      );
  }

}