import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgxSmartModalService, NgxSmartModalComponent, } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../../environments/environment';
import { FormsService } from './../../../services/forms/forms.service';
import { AdminService } from './../../../services/admin/admin.service';
import { DriversComponent } from './../../../screens/drivers/drivers.component';
import { ReportsService } from './../../../services/reports/reports.service';
import locale from '../../../../assets/json/locale.json';
declare var $: any;

@Component({
  selector: 'app-reporte-compras',
  templateUrl: './reporte-compras.component.html',
  styleUrls: ['./reporte-compras.component.css']
})
export class ReporteComprasComponent implements OnInit {
	environment = environment;
  public company: any = null;
  public config = {
    id: 'orders',
    searchText: '',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    startDate: null,
    endDate: null,
    options: [10, 20, 50, 100],
    order: {
      field: 'id',
      reverse: false
    }
  };
  public summary = {
    purchaseProducts: 0,
    totalAmount: 0,
    totalProviders: 0,
  }

	constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private adminService: AdminService, private toastService: ToastrService, private reportsService: ReportsService, private router: Router, private datePipe: DatePipe) {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.config.startDate = firstDay;
      this.config.endDate = lastDay;
	}

	ngOnInit(): void {
    this.adminService.currentCompany.subscribe(company => {
      this.company = company;
    });

    $("input[name='main-order-range']").daterangepicker({
      showDropdowns: true,
      "locale": locale,
      startDate: this.datePipe.transform(this.config.startDate, 'dd/MM/yyyy'),
      endDate: this.datePipe.transform(this.config.endDate, 'dd/MM/yyyy'),
    }, function(start, end, label) {
      console.log("A new date selection was made: " + this.datePipe.transform(start, 'dd/MM/yyyy') + ' to ' + this.datePipe.transform(end, 'dd/MM/yyyy'));
      this.config.startDate = start;
      this.config.endDate = end;
      this.getSummary();
    }.bind(this));
    this.getSummary();
  }

  public getSummary(){
    console.log("getSummary-->");
    this.spinner.show();
    this.reportsService.getResumenCompras(this.config).subscribe(
      (res) => {
        console.log("summary-res->", res);
        this.summary = res;
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }
  
}