import { Component, OnInit, ViewChild, ComponentFactory } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgxSmartModalService, NgxSmartModalComponent, } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../environments/environment';
import { FormsService } from './../../services/forms/forms.service';
import { AdminService } from './../../services/admin/admin.service';
import { DriversComponent } from './../../screens/drivers/drivers.component';

@Component({
  selector: 'app-transportLines',
  templateUrl: './transportlines.component.html',
  styleUrls: ['./transportlines.component.css']
})
export class TransportLinesComponent implements OnInit {
  @ViewChild("table", { static: true }) table: any;
  public formTransportLine:FormGroup;
  public environment = environment;
  public itemSelected: any = {id: 0};
  
  //table, pagination
  public config = {
    id: 'employees',
    searchText: '',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100]
  };
  public rows: any = [];

  //picture
  public responseMessage:String;

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private adminService: AdminService, private toastService: ToastrService) { }

  ngOnInit(): void {
    this.formTransportLine = this.formBuilder.group({
      rfc: ['', [Validators.required]],
      businessName: ['', [Validators.required]],
      tradename: ['', [Validators.required]],
      city: ['', [Validators.required]],
      address: ['', [Validators.required]]
    });
    this.populateTransportLines();
  }

  public populateTransportLines(){
    this.spinner.show();
    this.adminService.searchTransportLines(this.config).subscribe(
      res => {
        this.rows = res.data;
        this.config.totalItems = res.total;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public submitTransportLine(id){
    if(id === undefined){
      this.addTransportLine();
    }else{
      this.updateTransportLine(id);
    }
  }

  public addTransportLine() {
    this.config.searchText = "";
    this.config.currentPage = 1;

    if (!this.formTransportLine.valid) {
       this.formsService.validateAllFormFields(this.formTransportLine);
    }else {
      this.spinner.show();
      this.formTransportLine.controls["rfc"].patchValue(this.formTransportLine.controls["rfc"].value.replace(/[^a-zA-Z0-9]/g, ''));
      this.adminService.addTransportLine(this.formTransportLine.value).subscribe(
        (res) => {
          this.modalService.getModal('transportLineModal').close();
          this.openSuccessModal("¡Línea de transporte agregada!", "Su registro ha sido guardado exitosamente.");
          this.populateTransportLines();
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public updateTransportLine(id){
    if (!this.formTransportLine.valid) {
       this.formsService.validateAllFormFields(this.formTransportLine);
    }else {
      this.spinner.show();
      this.formTransportLine.controls["rfc"].patchValue(this.formTransportLine.controls["rfc"].value.replace(/[^a-zA-Z0-9]/g, ''));
      this.adminService.updateTransportLine(id, this.formTransportLine.value).subscribe(
        (res) => {
            this.modalService.getModal('transportLineModal').close();
            this.openSuccessModal("¡Línea de Transporte actualizada!", "Su registro ha sido modificado exitosamente.");
            this.populateTransportLines();
            this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public deleteTransportLine(id){
    console.log("deleteTransportLine-->", id);
    this.spinner.show();
    this.adminService.deleteTransportLine(id).subscribe(
      (res) => {
        this.modalService.getModal('deleteTransportModal').close();
        this.openSuccessModal("¡Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.populateTransportLines();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.responseMessage = err.error.message;
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  // actions
  edit(item) {
    this.itemSelected = item;
    this.openTransportLineModal(item);
  }

  enable(item){
    this.activeTransportLine(item, 1);
  }

  disable(item){
    this.activeTransportLine(item, 0);
  }

  activeTransportLine(item, status){
    this.spinner.show();
    this.itemSelected = item;
    this.adminService.enableTransportLine(item.id, { status: status })
      .subscribe(
        res => {
          if(res.status){
            this.openSuccessModal("¡Activado exitosamente!", "");
          }else{
            this.openSuccessModal("Desactivado exitosamente!", "");  
          }
          var itemIndex = this.rows.findIndex((item => item.id == res.id));
          if(this.rows[itemIndex]){
            this.rows[itemIndex] = res;
          }
          this.spinner.hide();
        },
        err => {
          console.log("error ->", err);
        }
      );
  }

  delete(item) {
    console.log("hhh", item);
    this.itemSelected = item;
    this.modalService.open("deleteTransportModal");
    console.log("hola", "hola");
  }

  changePermissions(item){

  }

  showDrivers(item){
    this.itemSelected = item;
    this.modalService.open("driversModal");
  }

  //modals
  public openTransportLineModal(item = null){
    this.formTransportLine.reset();
    this.itemSelected = {user: {rol: {}}};
    if(item){ //editing
      this.itemSelected = item;
      this.formTransportLine.patchValue(item);
    }
    this.modalService.getModal('transportLineModal').open();
  }


public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal1');
    this.modalService.setModalData(obj, 'successModal1');
    this.modalService.getModal('successModal1').open();
  }

  public onSearch(){
    this.config.currentPage = 1;
    this.populateTransportLines();
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
    this.populateTransportLines();
  }

}