<section class="container justify-content-center text-center p-0">
  <article class="row justify-content-center">
    <div class="col-12">
      <div class="container">
        <div class="row mb-5" style="align-items: baseline;">
          <div class="col-lg-6 col-8"></div>
          <div class="col-lg-6 col-4 text-right">
            <div class="catalog-btn">
              <img src="../assets/icons/ic_plus.png" (click)="openInventoryModal();" />
              <label (click)="openInventoryModal();">AGREGAR PRODUCTO</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table">
                <thead class="gray-area">
                  <tr>
                    <th scope="col">
                      <b class="primary">CANTIDAD</b>
                    </th>
                    <th scope="col">
                      <b class="primary">UNIDAD</b>
                    </th>
                    <th scope="col">
                      <b class="primary">PRODUCTO</b>
                    </th>
                    <th scope="col">
                      <b class="primary">CALIDAD</b>
                    </th>
                    <th scope="col">
                      <b class="primary">PRESENTACIÓN</b>
                    </th>
                    <th scope="col">
                      <b class="primary">ETIQUETA</b>
                    </th>
                    <th scope="col">
                      <b class="primary">TAMAÑO</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rows">
                    <td>{{lostSelected.quantity}}</td>
                    <td>{{item.units? item.units.name: ''}}</td>
                    <td>{{item.product? item.product.name: ''}}</td>
                    <td>{{item.quality? item.quality.name: ''}}</td>
                    <td>{{item.presentation? item.presentation.shortname: ''}}</td>
                    <td>{{item.tag? item.tag.name: ''}}</td>
                    <td>{{item.size? item.size.name: ''}}</td>
                  </tr>
                  <tr *ngIf="(rows).length === 0">
                    <td colspan="10">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mtr-2">
          <div class="col-3">
            <button type="submit" (click)="submitProducts()" class="btn btn-primary btn-user btn-block shadow-sm">CONTINUAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
            </button>
          </div>
          <div class="col-2">
             <button type="cancel" [routerLink]="['/losses']" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
          </div>
        </div>

      </div>
    </div>
  </article>
</section>

<ngx-smart-modal customClass="xxxl-dialog inventoryModal" #inventoryModal identifier="inventoryModal" [closable]="true">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-12">
        <app-inventory-lost [lostSelected]="lostSelected" (updateLost2)="updateLost2($event);"></app-inventory-lost>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¡Registro guardado!</h1>
        <p class="h6" style="font-size: 0.9rem;">
          Su registro ha sido actualizado exitosamente.
        </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>