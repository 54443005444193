import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class AdminService {

	private PATH_COMPANY_INFO:String = "/companies/info";
	private PATH_COMPANIES:String = "/companies/";
	private PATH_COMPANY_LOGO:String = "/files/companies/";
	// employees
	private PATH_EMPLOYEES:String = "/employees/";
	private PATH_EMPLOYEE_LOGO:String = "/files/employees/";
	//providers
	private PATH_PROVIDERS:String = "/providers/";
	private PATH_PROVIDER_LOGO:String = "/files/providers/";
	private PATH_SEASONS:String = "/season/";
	//clients
	private PATH_CLIENTS:String = "/clients/";
	private PATH_CLIENTS_LOGO:String = "/files/clients/";
	private PATH_PORCENT:String = "/percent/";
	//transport lines
	private PATH_TRANSPORT_LINES:String = "/transportLines/";
	private PATH_TRANSPORT_LINE_LOGO:String = "/files/transportLines/";
	//drivers
	private PATH_DRIVERS:String = "/drivers/";
	//products
	private PATH_PRODUCTS:String = "/products/";

	private companySource = new BehaviorSubject({});
	private transportLineSource = new BehaviorSubject({});

	currentCompany = this.companySource.asObservable();
	currentTransportLine = this.transportLineSource.asObservable();

	constructor(private http: HttpClient, private router: Router) {
		var company = JSON.parse(localStorage.getItem("@COMPANY"));
		this.onCompanyChanged(company);
		var transportLine = JSON.parse(localStorage.getItem("@TRANSPORTLINE"));
		this.onTransportLineChanged(transportLine);
	}

	onCompanyChanged(profile: any) {
		localStorage.removeItem('@COMPANY');
		localStorage.setItem("@COMPANY", JSON.stringify(profile));
		this.companySource.next(profile);
	}

	onTransportLineChanged(transportLine: any) {
		console.log("transportLine-changed", transportLine);
		localStorage.removeItem('@TRANSPORTLINE');
		localStorage.setItem("@TRANSPORTLINE", JSON.stringify(transportLine));
		this.transportLineSource.next(transportLine);
	}

	getCompany() {
    	return this.http.get<any>(environment.apiUrl + this.PATH_COMPANY_INFO, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_COMPANY_INFO, data),
	        error => throwError(error)
	      )
	    );
	}

  	updateCompany(id, obj) {
    	return this.http.patch<any>(environment.apiUrl + this.PATH_COMPANIES + id, obj, httpOptions)
      	  .pipe(tap( // Log the result or error
            data => console.log(this.PATH_COMPANIES, data),
            error => throwError(error)
          )
    	);
  	}

	changeCompanyLogo(id, obj) {
		var httpOptions2 = {
		  headers: new HttpHeaders()
		};
		return this.http.post<any>(environment.apiUrl + this.PATH_COMPANY_LOGO + id + "/logo", obj, httpOptions2)
		  .pipe(tap( // Log the result or error
		      data => console.log(environment.apiUrl + this.PATH_COMPANY_LOGO + id + "/logo", data),
		      error => throwError(error)
		  )
		);
	}

	getEmployees() {
    	return this.http.get<any>(environment.apiUrl + this.PATH_EMPLOYEES, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_EMPLOYEES, data),
	        error => throwError(error)
	      )
	    );
	}

	searchEmployees(config) {
		config.searchText = config.searchText? config.searchText.trim(): "";
		var search = JSON.stringify(
			{"$or": [
				{"name": {"$cont": config.searchText}},
				{"firstSurname": {"$cont": config.searchText}},
				{"secondSurname": {"$cont": config.searchText}},
				{"user.rol.nombre": {"$cont": config.searchText}}
			]}
		);
		console.log("searchText-->", config.searchText);
		console.log("search-->", search);
		console.log("config-->", JSON.stringify(config));
		
		return this.http.get<any>(environment.apiUrl + this.PATH_EMPLOYEES + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_EMPLOYEES, data),
	        error => throwError(error)
	      )
	    );
	}

	addEmployee(obj) {
    	return this.http.post<any>(environment.apiUrl + this.PATH_EMPLOYEES, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_EMPLOYEES, data),
	        error => throwError(error)
	      )
	    );
	}

	updateEmployee(id, obj) {
		return this.http.patch<any>(environment.apiUrl + this.PATH_EMPLOYEES + id, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_EMPLOYEES, data),
	        error => throwError(error)
	      )
	    );
	}

	enableEmployee(id, obj) {
		return this.http.put<any>(environment.apiUrl + this.PATH_EMPLOYEES + id, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_EMPLOYEES, data),
	        error => throwError(error)
	      )
	    );
	}

	changeEmployeeLogo(id, obj) {
		var httpOptions2 = {
		  headers: new HttpHeaders()
		};
		return this.http.post<any>(environment.apiUrl + this.PATH_EMPLOYEE_LOGO + id + "/logo", obj, httpOptions2)
		  .pipe(tap( // Log the result or error
		      data => console.log(environment.apiUrl + this.PATH_EMPLOYEE_LOGO + id + "/logo", data),
		      error => throwError(error)
		  )
		);
	}

	deleteEmployee(id) {
		return this.http.delete<any>(environment.apiUrl + this.PATH_EMPLOYEES + id, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_EMPLOYEES, data),
	        error => throwError(error)
	      )
	    );
	}


	// Providers
	getProviders() {
    	return this.http.get<any>(environment.apiUrl + this.PATH_PROVIDERS, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PROVIDERS, data),
	        error => throwError(error)
	      )
	    );
	}

	searchProviders(config) {
		var search = JSON.stringify(
			{"$or": [
				{"rfc": {"$cont": config.searchText}},
				{"businessName": {"$cont": config.searchText}},
				{"tradename": {"$cont": config.searchText}},
				{"city": {"$cont": config.searchText}}
			]}
		);
		console.log("searchText-->", config.searchText);
		console.log("search-->", search);
		console.log("config-->", JSON.stringify(config));
		
		return this.http.get<any>(environment.apiUrl + this.PATH_PROVIDERS + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PROVIDERS, data),
	        error => throwError(error)
	      )
	    );
	}

	addProvider(obj) {
    	return this.http.post<any>(environment.apiUrl + this.PATH_PROVIDERS, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PROVIDERS, data),
	        error => throwError(error)
	      )
	    );
	}

	updateProvider(id, obj) {
		return this.http.patch<any>(environment.apiUrl + this.PATH_PROVIDERS + id, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PROVIDERS, data),
	        error => throwError(error)
	      )
	    );
	}

	enableProvider(id, obj) {
		return this.http.put<any>(environment.apiUrl + this.PATH_PROVIDERS + id, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PROVIDERS, data),
	        error => throwError(error)
	      )
	    );
	}

	changeProviderLogo(id, obj) {
		var httpOptions2 = {
		  headers: new HttpHeaders()
		};
		return this.http.post<any>(environment.apiUrl + this.PATH_PROVIDER_LOGO + id + "/logo", obj, httpOptions2)
		  .pipe(tap( // Log the result or error
		      data => console.log(environment.apiUrl + this.PATH_PROVIDER_LOGO + id + "/logo", data),
		      error => throwError(error)
		  )
		);
	}

	deleteProvider(id) {
		return this.http.delete<any>(environment.apiUrl + this.PATH_PROVIDERS + id, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PROVIDERS, data),
	        error => throwError(error)
	      )
	    );
	}


	// Seasons
	getSeasons() {
    	return this.http.get<any>(environment.apiUrl + this.PATH_SEASONS, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_SEASONS, data),
	        error => throwError(error)
	      )
	    );
	}

	searchSeasons(config) {
		var search = JSON.stringify(
			{"$and": [
				{"$or": [
					{"codeSeason": {"$cont": config.searchText}},
				]},
				{"provider.id": {"$eq": config.providerId}}
			]}
		);
		var sort = "&sort=id,DESC&sort=status,ASC";

		console.log("searchText-->", config.searchText);
		console.log("search-->", search);
		console.log("config-->", JSON.stringify(config));
		
		return this.http.get<any>(environment.apiUrl + this.PATH_SEASONS + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search + sort, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_SEASONS, data),
	        error => throwError(error)
	      )
	    );
	}

	addSeason(obj) {
    	return this.http.post<any>(environment.apiUrl + this.PATH_SEASONS, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_SEASONS, data),
	        error => throwError(error)
	      )
	    );
	}

	updateSeason(id, obj) {
		return this.http.patch<any>(environment.apiUrl + this.PATH_SEASONS + id, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_SEASONS, data),
	        error => throwError(error)
	      )
	    );
	}

	enableSeason(id, obj) {
		return this.http.put<any>(environment.apiUrl + this.PATH_SEASONS + id, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_SEASONS, data),
	        error => throwError(error)
	      )
	    );
	}

	finalizeSeason(id) {
		return this.http.put<any>(environment.apiUrl + this.PATH_SEASONS + id + '/finalize', httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_SEASONS + id + '/finalize', data),
	        error => throwError(error)
	      )
	    );
	}

	getCurrentSeason(id) {
      return this.http.get<any>(environment.apiUrl + this.PATH_PROVIDERS + id + '/season', httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PROVIDERS + id + '/season', data),
          error => throwError(error)
        )
      );
  	}

	deleteSeason(id) {
		return this.http.delete<any>(environment.apiUrl + this.PATH_SEASONS + id, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_SEASONS, data),
	        error => throwError(error)
	      )
	    );
	}

	



	// Clientes
	getClients() {
    	return this.http.get<any>(environment.apiUrl + this.PATH_CLIENTS, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_CLIENTS, data),
	        error => throwError(error)
	      )
	    );
	}

	searchClients(config) {
		var search = JSON.stringify(
			{"$or": [
				{"rfc": {"$cont": config.searchText}},
				{"businessName": {"$cont": config.searchText}},
				{"tradename": {"$cont": config.searchText}},
				{"city": {"$cont": config.searchText}}
			]}
		);
		console.log("searchText-->", config.searchText);
		console.log("search-->", search);
		console.log("config-->", JSON.stringify(config));
		
		return this.http.get<any>(environment.apiUrl + this.PATH_CLIENTS + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_CLIENTS, data),
	        error => throwError(error)
	      )
	    );
	}

	addClient(obj) {
    	return this.http.post<any>(environment.apiUrl + this.PATH_CLIENTS, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_CLIENTS, data),
	        error => throwError(error)
	      )
	    );
	}

	updateClient(id, obj) {
		return this.http.patch<any>(environment.apiUrl + this.PATH_CLIENTS + id, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_CLIENTS, data),
	        error => throwError(error)
	      )
	    );
	}

	enableClient(id, obj) {
		return this.http.put<any>(environment.apiUrl + this.PATH_CLIENTS + id, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_CLIENTS, data),
	        error => throwError(error)
	      )
	    );
	}

	changeClientLogo(id, obj) {
		var httpOptions2 = {
		  headers: new HttpHeaders()
		};
		return this.http.post<any>(environment.apiUrl + this.PATH_CLIENTS_LOGO + id + "/logo", obj, httpOptions2)
		  .pipe(tap( // Log the result or error
		      data => console.log(environment.apiUrl + this.PATH_CLIENTS_LOGO + id + "/logo", data),
		      error => throwError(error)
		  )
		);
	}

	deleteClient(id) {
		return this.http.delete<any>(environment.apiUrl + this.PATH_CLIENTS + id, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_CLIENTS, data),
	        error => throwError(error)
	      )
	    );
	}

	addPorcent(idClient, obj){
		obj["client"] = idClient;
		return this.http.post<any>(environment.apiUrl + this.PATH_PORCENT, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PORCENT, data),
	        error => throwError(error)
	      )
	    );
	}

	updatePorcent(id, obj) {
		return this.http.patch<any>(environment.apiUrl + this.PATH_PORCENT + id, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PORCENT, data),
	        error => throwError(error)
	      )
	    );
	}

	deletePorcent(id) {
		return this.http.delete<any>(environment.apiUrl + this.PATH_PORCENT + id, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PORCENT, data),
	        error => throwError(error)
	      )
	    );
	}

	getTransportLines() {
    	return this.http.get<any>(environment.apiUrl + this.PATH_TRANSPORT_LINES, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_TRANSPORT_LINES, data),
	        error => throwError(error)
	      )
	    );
	}

	searchTransportLines(config) {
		var search = JSON.stringify(
			{"$or": [
				{"rfc": {"$cont": config.searchText}},
				{"businessName": {"$cont": config.searchText}},
				{"tradename": {"$cont": config.searchText}},
				{"city": {"$cont": config.searchText}}
			]}
		);
		console.log("searchText-->", config.searchText);
		console.log("search-->", search);
		console.log("config-->", JSON.stringify(config));
		
		return this.http.get<any>(environment.apiUrl + this.PATH_TRANSPORT_LINES + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_TRANSPORT_LINES, data),
	        error => throwError(error)
	      )
	    );
	}

	addTransportLine(obj) {
    	return this.http.post<any>(environment.apiUrl + this.PATH_TRANSPORT_LINES, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_TRANSPORT_LINES, data),
	        error => throwError(error)
	      )
	    );
	}

	updateTransportLine(id, obj) {
		return this.http.patch<any>(environment.apiUrl + this.PATH_TRANSPORT_LINES + id, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_TRANSPORT_LINES, data),
	        error => throwError(error)
	      )
	    );
	}

	enableTransportLine(id, obj) {
		return this.http.put<any>(environment.apiUrl + this.PATH_TRANSPORT_LINES + id, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_TRANSPORT_LINES, data),
	        error => throwError(error)
	      )
	    );
	}

	deleteTransportLine(id) {
		return this.http.delete<any>(environment.apiUrl + this.PATH_TRANSPORT_LINES + id, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_TRANSPORT_LINES, data),
	        error => throwError(error)
	      )
	    );
	}

	getDrivers(transportLineId:number) {
		var search = JSON.stringify({"transportLines.id": {"$eq": transportLineId}});
    	return this.http.get<any>(environment.apiUrl + this.PATH_DRIVERS + '?s=' + search, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_TRANSPORT_LINES, data),
	        error => throwError(error)
	      )
	    );
	}

	searchDrivers(config, transportLineId) {
		var search = JSON.stringify(
			{"$and": [
				{"$or": [
					{"name": {"$cont": config.searchText}},
					{"firstSurname": {"$cont": config.searchText}},
					{"secondSurname": {"$cont": config.searchText}},
				]},
				{"transportLines.id": {"$eq": transportLineId}}
			]}
		);
		console.log("searchText-->", config.searchText);
		console.log("search-->", search);
		console.log("config-->", JSON.stringify(config));
		
		return this.http.get<any>(environment.apiUrl + this.PATH_DRIVERS + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_DRIVERS, data),
	        error => throwError(error)
	      )
	    );
	}

	addDriver(obj) {
    	return this.http.post<any>(environment.apiUrl + this.PATH_DRIVERS, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_DRIVERS, data),
	        error => throwError(error)
	      )
	    );
	}

	updateDriver(id, obj) {
		return this.http.patch<any>(environment.apiUrl + this.PATH_DRIVERS + id, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_DRIVERS, data),
	        error => throwError(error)
	      )
	    );
	}

	deleteDriver(id) {
		return this.http.delete<any>(environment.apiUrl + this.PATH_DRIVERS + id, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_DRIVERS, data),
	        error => throwError(error)
	      )
	    );
	}

	getProducts() {
    	return this.http.get<any>(environment.apiUrl + this.PATH_PRODUCTS, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PRODUCTS, data),
	        error => throwError(error)
	      )
	    );
	}

	searchProducts(config) {
		var search = JSON.stringify(
			{"$or": [
				{"id": {"$cont": config.searchText}},
				{"name": {"$cont": config.searchText}},
				{"description": {"$cont": config.searchText}},
			]}
		);

		console.log("searchText-->", config.searchText);
		console.log("search-->", search);
		console.log("config-->", JSON.stringify(config));
		
		return this.http.get<any>(environment.apiUrl + this.PATH_PRODUCTS + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PRODUCTS, data),
	        error => throwError(error)
	      )
	    );
	}

	addProduct(obj) {
    	return this.http.post<any>(environment.apiUrl + this.PATH_PRODUCTS, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PRODUCTS, data),
	        error => throwError(error)
	      )
	    );
	}

	updateProduct(id, obj) {
		return this.http.patch<any>(environment.apiUrl + this.PATH_PRODUCTS + id, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PRODUCTS, data),
	        error => throwError(error)
	      )
	    );
	}

	enableProduct(id, obj) {
		return this.http.put<any>(environment.apiUrl + this.PATH_PRODUCTS + id, obj, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PRODUCTS, data),
	        error => throwError(error)
	      )
	    );
	}

	getProduct(id) {
    	return this.http.get<any>(environment.apiUrl + this.PATH_PRODUCTS + id, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_COMPANY_INFO, data),
	        error => throwError(error)
	      )
	    );
	}

	deleteProduct(id) {
		return this.http.delete<any>(environment.apiUrl + this.PATH_PRODUCTS + id, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_PRODUCTS, data),
	        error => throwError(error)
	      )
	    );
	}

}
