import { Component, ChangeDetectionStrategy, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from 'ngx-smart-modal';
import { environment } from './../environments/environment';
import { AuthService } from './services/auth/auth.service';
import { DashboardService } from './services/dashboard/dashboard.service';
import { AdminService } from './services/admin/admin.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

	environment = environment;
	public imagesUrl: String = environment.imagesUrl;
	public profile: any = {
		role: {
			id: 0
		},
		id: 0
	}
	public company: any = null;
	private title = 'front-lander';
	public listOptions: any = [];
	public customRouterLinkActiveOptions: any = {
		exact:false
	};

	@ViewChild('contentWrapper') contentWrapper: ElementRef;
	@ViewChild('sidebarToggle') sidebarToggle: ElementRef<HTMLElement>;

	constructor( private router: Router, private spinner: NgxSpinnerService, private authService: AuthService, 
		@Inject(DOCUMENT) document, private dashboardService: DashboardService, private adminService: AdminService, public modalService: NgxSmartModalService) {
	}

	ngOnInit() {
		var cont = document.getElementById('content-wrapper');
		this.router.events.subscribe((event: Event) => {
      		if (event instanceof NavigationEnd) {
      			if(event.url == "/"){
      				this.customRouterLinkActiveOptions.exact = true;
      			}
      			if(event.url.startsWith('/reports')){
      				if(cont){
      					cont.style.backgroundColor = "#f2f2f2";
      				}
      			}else{
      				if(cont){
      					cont.style.backgroundColor = "#fdfdfd";
      				}
      			}
        	}
        });
        const token = this.getParamValueQueryString("token");
        if(token){
        	this.authService.logout();
        }
		this.authService.currentProfile.subscribe(profile => {
			if(profile && !(Object.keys(profile).length === 0) ){
				console.log("profile: ", profile);
				this.profile = profile;
				this.getSideBarOptions();
			}else{
				this.profile = null;
				if(this.contentWrapper){
					this.contentWrapper.nativeElement.classList.remove('blur');
				}
				this.router.navigate(['/login'], { queryParams: { token: token } });
			}
    	});
		this.adminService.currentCompany.subscribe(company => {
			this.company = company;
			console.log("company: ", company);
    	});
    }
    
    getSideBarOptions(){
		this.spinner.show();
		this.dashboardService.getOptions().subscribe(
		  (res) => {
		    console.log("res:options", res);
			this.listOptions = res;
		    this.spinner.hide();
		  },
		  (err) => {
		    console.log("err", err);
		    this.spinner.hide();
		  }
		);
	}

	clickOption(option){
		if(option.childOption.length == 0){
			this.router.navigateByUrl("/"+option.route);				
			this.toggleSidebar();
		}
	}

	toggleSidebar(){
		let el: HTMLElement = this.sidebarToggle.nativeElement;
	    el.click();
    }

    toggleContent(){
    	let el: HTMLElement = this.contentWrapper.nativeElement;
	    el.click();    	
    }

    hasPhoto(){
    	return this.profile && this.profile.photo;
    }

    getInitial(){
    	var label = "";
    	try {
    		label = this.profile.name.charAt(0) + this.profile.lastname.charAt(0);
    	}catch(e) {}
    	return label;
    }

	logout(){
		this.modalService.open("logOutModal");
	}

	logOutConfirmed(){
		this.modalService.close("logOutModal");
		this.authService.logout();
	}

	getParamValueQueryString( paramName ) {
		const url = window.location.href;
	    let paramValue;
	    if (url.includes('?')) {
	      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
	      paramValue = httpParams.get(paramName);
	    }
	    return paramValue;
	}

}