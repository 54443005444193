<div class="container">
  <!-- Outer Row -->
  <div class="row justify-content-center mtr-2">
    <div class="col-12" id="print-section">

      <table class="table main-table">
        <thead>
          <tr style="height: 40px; background: #42a950;">
            <th colspan="7" scope="col">
              <div style="height: 40px; background: #42a950;"></div>
            </th>
          </tr>
          <tr>
            <th colspan="3" scope="col" style="vertical-align: top;">
              <div class="company-container">
                <div class="datos">
                  <span class="title">{{company.name}}</span>
                  <span>{{company.representative}}</span>
                  <span>R.F.C. {{company.rfc}}</span>
                  <span>{{company.state}}, {{company.city}} C.P. {{company.postalCode}}</span>
                  <span>{{company.address}}</span>
                  <span>TELS. {{company.phone}}</span>
                </div>
              </div>
            </th>
            <th colspan="2" scope="col" style="vertical-align: top;">
              ORDEN DE COMPRA: <b style="background: #BBCDBF; color: black; padding: 0px 24px; font-size: 22px;">Nº {{orderSelected.id}}</b>
            </th>
            <th colspan="2" scope="col" style="vertical-align: top;">
              <div>
                <img id="logo" [src]="environment.imagesUrl + company.logo" style="height: 120px;" />
              </div>
            </th>
          </tr>
          <tr>
            <th colspan="2" scope="col" style="font-size: 12px !important;"><b>Proveedor:</b> {{orderSelected.provider? orderSelected.provider.businessName: ''}}</th>
            <th scope="col" style="font-size: 12px !important;"><b>Viaje:</b> {{orderSelected.trip}}</th>
            <th scope="col" style="font-size: 12px !important;"><b>Fecha de compra:</b> {{orderSelected.purchaseDate}}</th>
            <th scope="col" style="font-size: 12px !important;"><b>Fecha de entrega:</b> {{orderSelected.arrivalDate}}</th>
            <th colspan="2" scope="col" style="font-size: 12px !important;"></th>
          </tr>
          <tr>
            <td colspan="7" scope="col">

              <table class="table secondary-table">
                <thead>
                  <tr>
                    <th scope="col" style="color: black !important;">CANTIDAD</th>
                    <th scope="col" style="color: black !important;">UNIDAD</th>
                    <th scope="col" style="color: black !important;">PRODUCTO</th>
                    <th scope="col" style="color: black !important;">CALIDAD</th>
                    <th scope="col" style="color:black !important;">PRESENTACIÓN</th>
                    <th scope="col" style="color: black !important;">ETIQUETA</th>
                    <th scope="col" style="color: black !important;">TAMAÑO</th>
                    <th scope="col" style="color: black !important;">COSTO DE COMPRA</th>
                    <th scope="col" style="color: black !important;">IMPORTE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of orderSelected.productsOrder">
                    <td>{{item.quantity}}</td>
                    <td>{{item.units.name}}</td>
                    <td>{{item.product.name}}</td>
                    <td>{{item.quality.name}}</td>
                    <td>{{item.presentation.name}} {{item.presentation.shortname}}</td>
                    <td>{{item.tag.name}}</td>
                    <td>{{item.size.name}}</td>
                    <td>{{item.price | currency}}</td>
                    <td>{{(item.price * item.quantity) | currency }}</td>
                  </tr>
                  <tr *ngIf="(orderSelected.productsOrder).length === 0">
                    <td colspan="7">No se encontraron elementos</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="height: 30px;"></td>
                    <td colspan="1" style="height: 30px;"></td>
                    <td colspan="1" style="height: 30px;"></td>
                    <td colspan="1" style="height: 30px;"></td>
                    <td colspan="1" style="height: 30px;"></td>
                    <td colspan="1" style="height: 30px;"></td>
                    <td colspan="1" style="height: 30px;"></td>
                    <td colspan="1" style="height: 30px;"></td>
                    <td colspan="1" style="height: 30px;"></td>
                  </tr>
                  <tr>
                    <td colspan="1"><b>{{orderSelected.quantity}}</b></td>
                    <td colspan="6" style="border-bottom: none !important;border-right: none !important;"></td>
                    <td colspan="1" style="color: black !important;">IMPORTE TOTAL</td>
                    <td colspan="1"><b>{{orderSelected.amount | currency}}</b></td>
                  </tr>
                </tbody>
              </table>

            </td>
          </tr>
          <tr style="text-align: left;">
            <td colspan="7">
              <b style="color: black !important;">GASTOS DE TRANSPORTE</b>
            </td>
          </tr>
          <tr>
            <td colspan="7" scope="col">
              <table class="table secondary-table">
                <thead>
                  <tr>
                    <th scope="col" style="color: black !important;width:10% !important;">TRANSPORTE</th>
                    <th scope="col" style="color: black !important;width:10% !important;">CHOFER</th>
                    <th scope="col" style="color: black !important;width:10% !important;">TELÉFONO</th>
                    <th scope="col" style="color: black !important;width:10% !important;">CONCEPTO</th>
                    <th scope="col" style="color: black !important;width:10% !important;">VALOR</th>
                    <th scope="col" style="color: black !important;width:10% !important;">IVA</th>
                    <th scope="col" style="color: black !important;width:10% !important;">IMPORTE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of orderSelected.expenseRecordsTransportLine">
                    <td colspan="1">{{orderSelected.transportLines? orderSelected.transportLines.businessName: ''}}</td>
                    <td colspan="1">{{orderSelected.driver? orderSelected.driver.name + " " + orderSelected.driver.firstSurname: ''}}</td>
                    <td colspan="1">{{orderSelected.driver? orderSelected.driver.phone: ''}}</td>
                    <td colspan="1">{{item.concept}}</td>
                    <td colspan="1">{{item.value | currency}}</td>
                    <td colspan="1">{{(item.iva?(item.value*0.16):0) | currency}}</td>
                    <td colspan="1">{{(item.iva?(item.value*1.16):item.value) | currency}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="height: 30px;"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                  </tr>
                  <tr *ngIf="(orderSelected.expenseRecordsTransportLine).length === 0">
                    <td colspan="7">No se encontraron elementos</td>
                  </tr>
                  <tr>
                    <td colspan="5" style="border-bottom: none !important; border-left: none !important;"></td>
                    <td colspan="1" style="color: black !important;">TOTAL GASTOS DE TRANSPORTE:</td>
                    <td colspan="1"><b>{{orderSelected.transportLinesExpense | currency}}</b></td>
                  </tr>
                </tbody>
              </table>

            </td>
          </tr>

          <tr *ngIf="orderSelected.expenseRecordsAdditional.length" style="text-align: left;">
            <td colspan="7">
              <b style="color: black !important;">GASTOS ADICIONALES</b>
            </td>
          </tr>
          <tr *ngIf="orderSelected.expenseRecordsAdditional.length" >
            <td colspan="7" scope="col">
              <table class="table secondary-table">
                <thead>
                  <tr>
                    <th scope="col" style="color: black !important;width:40% !important;">CONCEPTO</th>
                    <th scope="col" style="color: black !important;width:9% !important;">VALOR</th>
                    <th scope="col" style="color: black !important;width:9% !important;">IVA</th>
                    <th scope="col" style="color: black !important;width:10% !important;">IMPORTE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of orderSelected.expenseRecordsAdditional">
                    <td colspan="1">{{item.concept}}</td>
                    <td colspan="1">{{item.value | currency}}</td>
                    <td colspan="1">{{(item.iva?(item.value*0.16):0) | currency}}</td>
                    <td colspan="1">{{(item.iva?(item.value*1.16):item.value) | currency}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="height: 30px;"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                  </tr>
                  <tr *ngIf="(orderSelected.expenseRecordsAdditional).length === 0">
                    <td colspan="4">No se encontraron elementos</td>
                  </tr>
                  <tr>
                    <td colspan="2" style="border-bottom: none !important; border-left: none !important;"></td>
                    <td colspan="1" style="color: black !important;">GASTOS ADICIONALES:</td>
                    <td colspan="1"><b>{{orderSelected.additionalCosts | currency}}</b></td>
                  </tr>
                </tbody>
              </table>

            </td>
          </tr>

          <tr style="text-align: left;">
            <td colspan="7">
              <b>OBSERVACIONES</b>
            </td>
          </tr>
          <tr>
            <td colspan="4" scope="col">
              <table class="table secondary-table">
                <thead>
                  <tr>
                    <th colspan="1" scope="col" style="color: black !important; text-align: left;">DESCRIPCIÓN DE LA OBSERVACIÓN</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of orderSelected.observation">
                    <td colspan="1" style="text-align: left;">{{item.observation}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="height: 30px;"></td>
                  </tr>
                  <tr *ngIf="(orderSelected.observation).length === 0">
                    <td colspan="2">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td colspan="3" scope="col">
              <table class="table secondary-table">
                <tbody>
                  <tr>
                    <td colspan="1" style="border: none !important; color: black;width: 49%;">IMPORTE TOTAL DE PRODUCTOS:</td>
                    <td colspan="1">{{orderSelected.amount | currency}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="border: none !important; color: black;">GASTOS DE TRANSPORTE:</td>
                    <td colspan="1">{{orderSelected.transportLinesExpenseSinIva  | currency}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="border: none !important; color: black;">GASTOS ADICIONALES:</td>
                    <td colspan="1">{{orderSelected.additionalCostsSinIva  | currency}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="border: none !important; color: black;">IVA:</td>
                    <td colspan="1" style="color: lightslategray;">{{orderSelected.ivaTotal | currency}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="border: none !important; color: black; font-size: 18px !important;"><b>TOTAL A PAGAR:</b></td>
                    <td colspan="1"><b style="background: #BBCDBF; color: black; padding: 0px 24px; font-size: 18px;">{{(orderSelected.amount + orderSelected.additionalCosts + orderSelected.transportLinesExpense) | currency}}</b></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </thead>
        <tbody id="footer" class="footer" style="border: none !important;">
          <tr>
            <td colspan="1" scope="col" style="text-align: center;">PÁG. 1 DE 1</td>
            <td colspan="1" scope="col"></td>
            <td colspan="1" scope="col">Compra registrada y realizada por:</td>
            <td colspan="2" scope="col">{{orderSelected.employee? orderSelected.employee.name + ' ' + orderSelected.employee.firstSurname + ' ' + orderSelected.employee.user.email: ''}}</td>
            <td colspan="1" scope="col" style="border-right: 1px solid;">Fecha y hora: {{orderSelected.purchaseDate}}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-3">
      <button type="submit" [routerLink]="['/purchases']" class="btn btn-primary btn-user btn-block shadow-sm" printSectionId="print-section" [useExistingCss]="true" ngxPrint>GUARDAR E IMPRIMIR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
      </button>
    </div>
    <div class="col-2">
      <button type="submit" [routerLink]="['/purchases']" class="btn btn-primary btn-user btn-block shadow-sm">GUARDAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
      </button>
    </div>
    <div class="col-2">
       <button type="cancel" [routerLink]="['/purchases']" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
    </div>
  </div>
</div>