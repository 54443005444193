import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MouseEvent } from '@agm/core';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../../../environments/environment';
import { FormsService } from './../../../../services/forms/forms.service';
import { AdminService } from './../../../../services/admin/admin.service';
import { InventoryService } from './../../../../services/inventory/inventory.service';

@Component({
  selector: 'app-product-inventory-single',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductInventorySingleComponent implements OnInit {
  public environment = environment;
  @Input() product: any = {
    id: 0,
    name: ''
  }

  //table, pagination
  public config = {
    id: 'product-inventory',
    searchText: '',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100]
  };
  public rows: any = [];
  public totals: any = [];
  public sizesKeys: any = [];
  public average:number = 0;
  public total:number = 0;

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private adminService:AdminService, private inventoryService:InventoryService, private toastService: ToastrService, public route:ActivatedRoute) { }

  ngOnInit(): void {
    this.getProductInventory();
  }

  public getProductInventory(){
    this.spinner.show();
    this.inventoryService.searchProductInventory(this.product.id, this.config).subscribe(
      res => {
        this.rows = res.list;
        this.average = res.average;
        if(this.rows.length > 0){
          console.log("product: ", this.rows[0].name);
          this.product.name = this.rows[0].name;
        }
        var i = 0;
        for (var row of this.rows) {
          this.sizesKeys = this.sizesKeys.concat(Object.keys(row.sizes));

          var sum:number = 0;
          for (var sizesKey of Object.keys(row.sizes)) {
            console.log("s", row.sizes[sizesKey]);
            sum+= Number(row.sizes[sizesKey]);
          }
          console.log("sum", sum);
          this.rows[i]['total'] = sum;
          this.total+= sum;
          i++;
        }
        this.sizesKeys = this.sizesKeys.filter((item, i, ar) => ar.indexOf(item) === i);
        console.log("sizesKeys: ", this.sizesKeys);

        for(var total of res.totals){
          this.totals[total.size] = total;
        }
        
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public onSearch(){
    this.config.currentPage = 1;
    this.getProductInventory();
  }

}