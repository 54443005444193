import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from './../../../../app.component';
import { environment } from './../../../../../environments/environment';
import { FormsService } from './../../../../services/forms/forms.service';
import { AdminService } from './../../../../services/admin/admin.service';
import { SalesService } from './../../../../services/sales/sales.service';

@Component({
  selector: 'app-costs-sale',
  templateUrl: './costs-sale.component.html',
  styleUrls: ['./costs-sale.component.css']
})
export class CostsSaleComponent implements OnInit, OnChanges {
  @Output() continue = new EventEmitter<any>();
  @Output() updateSale = new EventEmitter<any>();
  @Input() saleSelected;

  public transportCostsForm:FormGroup;
  public additionalCostsForm:FormGroup;
  public transportCosts = [];
  public additionalCosts = [];

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService,
    private spinner: NgxSpinnerService, private toastService: ToastrService, private adminService: AdminService,
    public salesService: SalesService ) { }

  ngOnInit(): void {
  	this.transportCostsForm = this.formBuilder.group({
      concept: ['', [Validators.required]],
      iva: [false],
      value: ['', [Validators.required]],
    });

    this.additionalCostsForm = this.formBuilder.group({
      concept: ['', [Validators.required]],
      iva: [false],
      value: ['', [Validators.required]],
    });
    this.populateTransportCosts();
    this.populateAdditionalCosts();
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    let log: string[] = [];
    for (let propName in changes) {
      if(propName == 'saleSelected'){
        console.log("saleSelected", this.saleSelected);
      }
    }
  }

  public populateTransportCosts(){
    this.spinner.show();
    this.salesService.getTransportCosts(this.saleSelected.id).subscribe(
      res => {
        this.transportCosts = res;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public populateAdditionalCosts(){
    this.spinner.show();
    this.salesService.getAdditionalCosts(this.saleSelected.id).subscribe(
      res => {
        this.additionalCosts = res;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public addTransportCost(){
    if (!this.transportCostsForm.valid) {
       this.formsService.validateAllFormFields(this.transportCostsForm);
    }else {
      this.spinner.show();
      this.transportCostsForm.value['saleProducts'] = this.saleSelected.id;
      this.salesService.addTransportCost(this.transportCostsForm.value).subscribe(
        (res) => {
          this.populateTransportCosts();
          this.transportCostsForm.reset();
          this.transportCostsForm.controls['iva'].patchValue(false);
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public addAdditionalCost(){
    if (!this.additionalCostsForm.valid) {
       this.formsService.validateAllFormFields(this.additionalCostsForm);
    }else {
      this.spinner.show();
      this.additionalCostsForm.value['saleProducts'] = this.saleSelected.id;
      this.salesService.addAdditionalCost(this.additionalCostsForm.value).subscribe(
        (res) => {
          this.populateAdditionalCosts();
          this.additionalCostsForm.reset();
          this.additionalCostsForm.controls['iva'].patchValue(false);
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public expenseToDelete;
  public costToDelete;
  delete(item, type) {
    if(type == 'additionalCost'){
      this.costToDelete = item;
      this.modalService.open("deleteAdditionalModal"); 
    }else{
      this.expenseToDelete = item;
      this.modalService.open("deleteTransportModal");      
    }
  }

  public deleteTransportCost(id){
    console.log("deleteTransportCost-->", id);
    this.spinner.show();
    this.salesService.deleteTransportCost(id).subscribe(
      (res) => {
        this.modalService.getModal('deleteTransportModal').close();
        this.openSuccessModal("¡Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.populateTransportCosts();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  public deleteAdditionalCost(id){
    console.log("deleteAdditionalCost-->", id);
    this.spinner.show();
    this.salesService.deleteAdditionalCost(id).subscribe(
      (res) => {
        this.modalService.getModal('deleteAdditionalModal').close();
        this.openSuccessModal("¡Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.populateAdditionalCosts();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal');
    this.modalService.setModalData(obj, 'successModal');
    this.modalService.getModal('successModal').open();
  }

  public next(){
    this.continue.next();  
  }

}