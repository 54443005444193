<div class="container mt-5">
  <!-- Outer Row -->
  <div class="row justify-content-center">
    
    <div class="col-6">
      <form [formGroup]="transportCostsForm" class="text-left bordered">
        <div class="row">
          <div class="col-12">
            <h6 class="font-weight-bold gray-area p-2 primary">Gasto asociado línea de transporte</h6>
          </div>
        </div>
        <div class="row" style="margin-top: -1rem;">
          <div class="col-12">
            <div class="form-group mb-0">
              <label for="concept" class="dark"><b class="red">*</b>Ingresa el concepto del gasto ej. Flete Cul-Tijuana</label>
              <input type="text" class="form-control no-border" name="concept" id="concept" formControlName="concept" >
              <div class="border-control"></div>
              <div *ngIf="transportCostsForm.controls['concept'].invalid && (transportCostsForm.controls['concept'].dirty || transportCostsForm.controls['concept'].touched)" class="inputAlert">
                <div *ngIf="transportCostsForm.controls['concept'].errors && transportCostsForm.controls['concept'].errors.required">
                    Concepto obligatorio
                </div>
              </div>
            </div>
          </div>
          <div class="col-10">
            <div class="form-group mb-0">
              <label for="value" class="dark"><b class="red">*</b>Ingresa el valor del gasto asociado</label>
              <input type="text" class="form-control no-border" name="value" id="value" formControlName="value" mask="separator.2" thousandSeparator="," >
              <div class="border-control"></div>
              <div *ngIf="transportCostsForm.controls['value'].invalid && (transportCostsForm.controls['value'].dirty || transportCostsForm.controls['value'].touched)" class="inputAlert">
                <div *ngIf="transportCostsForm.controls['value'].errors && transportCostsForm.controls['value'].errors.required">
                    Valor del gasto obligatorio
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="modal-catalog-btn mt-3">
              <div class="checkbox_wrapper">
                <input type="checkbox" name="iva" id="iva" formControlName="iva" />
                <label></label>
              </div>
              <label style="margin-top: 22px;">MÁS IVA</label>
            </div>
          </div>
          <div class="col-12">
            <div class="modal-catalog-btn mt-3">
              <img (click)="addTransportCost();" src="../assets/icons/ic_plus.png" />
              <label (click)="addTransportCost();">AGREGAR GASTO</label>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6 mt-2" *ngFor="let e of transportCosts">
            <div class="gasto">
              <span>{{e.concept}}</span>
              <span class="price-container">
                <p class="price">${{e.value}}</p>
                <p *ngIf="e.iva" class="iva">Agregar IVA: ${{ (e.value*0.16).toFixed(2) }}</p>
              </span>
              <a class="btn-close" (click)="delete(e, 'transportCost');">
                <i class="far fa-times-circle"></i>
              </a>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-6">
      <form [formGroup]="additionalCostsForm" class="text-left bordered">
        <div class="row">
          <div class="col-12">
            <h6 class="font-weight-bold gray-area p-2 primary">Gastos adicionales en esta compra</h6>
          </div>
        </div>
        <div class="row" style="margin-top: -1rem;">
          <div class="col-12">
            <div class="form-group mb-0">
              <label for="concept" class="dark"><b class="red">*</b>Ingresa el concepto del gasto ej. Flete Cul-Tijuana</label>
              <input type="text" class="form-control no-border" name="concept" id="concept" formControlName="concept" >
              <div class="border-control"></div>
              <div *ngIf="additionalCostsForm.controls['concept'].invalid && (additionalCostsForm.controls['concept'].dirty || additionalCostsForm.controls['concept'].touched)" class="inputAlert">
                <div *ngIf="additionalCostsForm.controls['concept'].errors && additionalCostsForm.controls['concept'].errors.required">
                    Concepto obligatorio
                </div>
              </div>
            </div>
          </div>
          <div class="col-10">
            <div class="form-group mb-0">
              <label for="value" class="dark"><b class="red">*</b>Ingresa el valor del gasto asociado</label>
              <input type="text" class="form-control no-border" name="value" id="value" formControlName="value" mask="separator.2" thousandSeparator=",">
              <div class="border-control"></div>
              <div *ngIf="additionalCostsForm.controls['value'].invalid && (additionalCostsForm.controls['value'].dirty || additionalCostsForm.controls['value'].touched)" class="inputAlert">
                <div *ngIf="additionalCostsForm.controls['value'].errors && additionalCostsForm.controls['value'].errors.required">
                    Valor del gasto obligatorio
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="modal-catalog-btn mt-3">
              <div class="checkbox_wrapper">
                <input type="checkbox" name="iva" id="iva" formControlName="iva" />
                <label></label>
              </div>
              <label style="margin-top: 22px;">MÁS IVA</label>
            </div>
          </div>
          <div class="col-12">
            <div class="modal-catalog-btn mt-3">
              <img (click)="addAdditionalCost();" src="../assets/icons/ic_plus.png" />
              <label (click)="addAdditionalCost();">AGREGAR GASTO</label>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6 mt-2" *ngFor="let a of additionalCosts">
            <div class="gasto">
              <span>{{a.concept}}</span>
              <span class="price-container">
                <p class="price">${{a.value}}</p>
                <p *ngIf="a.iva" class="iva">Agregar IVA: ${{ (a.value*0.16).toFixed(2)  }}</p>
              </span>
              <a class="btn-close" (click)="delete(a, 'additionalCost');">
                <i class="far fa-times-circle"></i>
              </a>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row justify-content-center mtr-2">
    <div class="col-3">
      <button type="button" (click)="submitCosts();" class="btn btn-primary btn-user btn-block shadow-sm">CONTINUAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
      </button>
    </div>
    <div class="col-2">
       <button type="cancel" [routerLink]="['/purchases']" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
    </div>
  </div>

</div>

<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div *ngIf="successModal.hasData()" class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">{{ successModal.getData().title }}</h1>
        <p class="h6" style="font-size: 0.9rem;">{{ successModal.getData().subtitle }} </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #deleteTransportModal identifier="deleteTransportModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_warning.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¿Desea eliminar este registro?</h1>
        <p class="h6" style="font-size: 0.9rem;">Recuerda que será eliminado de la base de datos</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <button type="submit" (click)="deleteTransportCost(expenseToDelete.id)" class="btn btn-primary btn-user btn-block shadow-sm">SÍ, ELIMINAR<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
      <div class="col-4">
        <button type="cancel" (click)="deleteTransportModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #deleteAdditionalModal identifier="deleteAdditionalModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_warning.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¿Desea eliminar este registro?</h1>
        <p class="h6" style="font-size: 0.9rem;">Recuerda que será eliminado de la base de datos</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <button type="submit" (click)="deleteAdditionalCost(costToDelete.id)" class="btn btn-primary btn-user btn-block shadow-sm">SÍ, ELIMINAR<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
      <div class="col-4">
        <button type="cancel" (click)="deleteAdditionalModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
      </div>
    </div>
  </div>
</ngx-smart-modal>