import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MouseEvent } from '@agm/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../../environments/environment';
import { FormsService } from './../../../services/forms/forms.service';
import { AdminService } from './../../../services/admin/admin.service';
import { UnitsService } from './../../../services/units/units.service';
import { PurchasesService } from './../../../services/purchases/purchases.service';
declare var $: any;

@Component({
  selector: 'app-products-order',
  templateUrl: './products-order.component.html',
  styleUrls: ['./products-order.component.css']
})
export class ProductsOrderComponent implements OnInit, OnChanges {
  @Output() continue = new EventEmitter<any>();
  @Input() orderSelected;
  public productPurchaseSelected: any = {
    id: '',
    product: {
      id: 0,
      name: '',
      description: '',
    },
    units: { id: 0 },
    quality: { id: 0 },
    presentation: { id: 0 },
    size: { id: 0 },
    tag: { id: 0 },
    quantity: null,
    price: null
  };

  //table, pagination
  public config = {
    id: 'products',
    searchText: '',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100]
  };
  public rows: any = [];

  //modal, details
  public formProduct:FormGroup;
  public productList: any[] = [];
  public unitList: any[] = [];
  public qualityList: any[] = [];
  public tagList: any[] = [];
  public sizeList: any[] = [];
  public presentationList: any[] = [];

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, 
    public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, 
    private filterPipe: FilterPipe, private adminService: AdminService, private toastService: ToastrService,
    private unitsService: UnitsService, private purchasesService: PurchasesService) {
    this.formProduct = this.formBuilder.group({
      id: ['', [Validators.required]],
      description: [''],
      units: [''],
      quality: [''],
      presentation: [''],
      size: [''],
      tag: [''],
      quantity: [0, [Validators.required]],
      price: [0, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.populateProductsPurchase();
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    let log: string[] = [];
    for (let propName in changes) {
      if(propName == 'orderSelected'){
        console.log("orderSelected --> ", this.orderSelected);
        this.formProduct.patchValue(this.orderSelected);
      }
    }
  }

  public populateProductsPurchase(){
    this.spinner.show();
    this.purchasesService.searchProducts(this.orderSelected.id, this.config).subscribe(
      res => {
        this.rows = res.data;
        this.config.totalItems = res.total;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public populateProducts(){
    this.spinner.show();
    this.adminService.getProducts().subscribe(
      res => {
        this.productList = res;
        var productId = this.productPurchaseSelected.product.id;
        console.log("id-->", productId);
        if(productId){
          this.formProduct.controls['id'].patchValue(this.productPurchaseSelected.product.id);
          this.onProductChanged(this.productPurchaseSelected.product.id);          
        }else{
          setTimeout(()=>{
            $("#id").val(this.productPurchaseSelected.product.id).selectpicker("refresh");
            $("#units").val('default').selectpicker("refresh");
            $("#quality").val('default').selectpicker("refresh");
            $("#presentation").val('default').selectpicker("refresh");
            $("#tag").val('default').selectpicker("refresh");
            $("#size").val('default').selectpicker("refresh");
          }, 100);
          this.spinner.hide();
        }
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public onProductChanged(value){
    this.productPurchaseSelected.product = this.productList.find(x => x.id == value);
    this.spinner.show();
    this.adminService.getProduct(value).subscribe(
      res => {
        this.populateUnit("unit").then(function(unitsList){
          this.unitList = unitsList;
          this.formProduct.controls['units'].patchValue(this.productPurchaseSelected.units.id);
          return this.populateUnit("quality");

        }.bind(this)).then(function(qualityList){
          this.qualityList = qualityList;
          this.formProduct.controls['quality'].patchValue(this.productPurchaseSelected.quality.id);
          return this.populateUnit("size");

        }.bind(this)).then(function(sizeList){
           this.sizeList = sizeList;
           this.formProduct.controls['size'].patchValue(this.productPurchaseSelected.size.id);
           return this.populateUnit("tag");

        }.bind(this)).then(function(tagList){
           this.tagList = tagList;
           this.formProduct.controls['tag'].patchValue(this.productPurchaseSelected.tag.id);
           return  this.populateUnit("presentation");

        }.bind(this)).then(function(presentationList){
          this.presentationList = presentationList;
          this.formProduct.controls['presentation'].patchValue(this.productPurchaseSelected.presentation.id);
          return true;

        }.bind(this)).then(function(presentationList){
          setTimeout(()=>{
            $("#id").val(this.productPurchaseSelected.product.id).selectpicker("refresh");
            $("#units").val(this.productPurchaseSelected.units.id).selectpicker("refresh");
            $("#quality").val(this.productPurchaseSelected.quality.id).selectpicker("refresh");
            $("#presentation").val(this.productPurchaseSelected.presentation.id).selectpicker("refresh");
            $("#tag").val(this.productPurchaseSelected.tag.id).selectpicker("refresh");
            $("#size").val(this.productPurchaseSelected.size.id).selectpicker("refresh");
          }, 100);
          this.spinner.hide();

        }.bind(this)).catch(function(e){
          console.log(e);
          this.spinner.hide();
        }.bind(this));
        
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public populateUnit(type){
    return new Promise(function(resolve, reject) {
      this.unitsService.getUnitList(this.productPurchaseSelected.product.id, type).subscribe(
        res => {
          resolve(res);
        },
        err => {
          console.log("error ->", err);
          reject(err.error.message);
        }
      );
    }.bind(this));
  }

  public submitProduct(id){
    if(id === ''){
      this.addProduct();
    }else{
      this.updateProduct(id);
    }
  }

  public addProduct() {
    console.log("addProduct-->");
    if (!this.formProduct.valid) {
       this.formsService.validateAllFormFields(this.formProduct);

    }else {
      delete this.formProduct.value['id'];
      this.formProduct.value['product']= this.productPurchaseSelected.product.id;
      this.formProduct.value['purchaseProducts']= this.orderSelected.id;
      console.log("this.formProduct.value-->", this.formProduct.value);

      this.purchasesService.addProduct(this.formProduct.value).subscribe(
        (res) => {
          this.modalService.getModal('productModal').close();
          this.populateProductsPurchase();
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public updateProduct(id){
    console.log("updateProduct-->", id);
    if (!this.formProduct.valid) {
       this.formsService.validateAllFormFields(this.formProduct);
    }else {
      this.purchasesService.updateProduct(id, this.formProduct.value).subscribe(
        (res) => {
          this.modalService.getModal('productModal').close();
          this.populateProductsPurchase();
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public deleteProduct(id){
    console.log("deleteProduct-->", id);
    this.spinner.show();
    this.purchasesService.deleteProduct(id).subscribe(
      (res) => {
        this.modalService.getModal('deleteModal').close();
        this.openSuccessModal("¡Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.populateProductsPurchase();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  // actions
  edit(item) {
    this.productPurchaseSelected = item;
    this.openProductModal(item);
  }

  active(item, enabled){
    this.productPurchaseSelected = item;
    this.openSuccessModal("¡Activado exitosamente!", "");
    /*
    this.jobRequestService
      .updatePersonalinformation({ enabled: true }, item.id)
      .subscribe(
        res => {
          this.toast.success("Activado", "Usuario");
          this.ngOnInit();
        },
        err => {
          console.log("error ->", err);
        }
      );
    */
  }

  delete(item) {
    this.productPurchaseSelected = item;
    this.modalService.open("deleteModal");
  }

  //modals
  public openProductModal(item = null){
    this.productPurchaseSelected = {
      id: '',
      product: {
        id: 0,
        name: '',
        description: '',
      },
      units: { id: 0 },
      quality: { id: 0 },
      presentation: { id: 0 },
      size: { id: 0 },
      tag: { id: 0 },
      quantity: null,
      price: null
    };
    this.formProduct.reset();
    if(item){ //editing
      this.productPurchaseSelected = item;
    }
    this.populateProducts();
    this.formProduct.patchValue(this.productPurchaseSelected);
    this.modalService.getModal('productModal').open();
  }

  public onUnitChanged(value){
    this.productPurchaseSelected.units = this.unitList.find(x => x.id == value);
  }

  public onQualityChanged(value){
    this.productPurchaseSelected.quality = this.qualityList.find(x => x.id == value);
  }

  public onSizeChanged(value){
    this.productPurchaseSelected.size = this.sizeList.find(x => x.id == value);
  }

  public onTagChanged(value){
    this.productPurchaseSelected.tag = this.tagList.find(x => x.id == value);
  }

  public onPresentationChanged(value){
    this.productPurchaseSelected.presentation = this.presentationList.find(x => x.id == value);
  }

  public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal');
    this.modalService.setModalData(obj, 'successModal');
    this.modalService.getModal('successModal').open();
  }

  public onSearch(){
    this.config.currentPage = 1;
    this.populateProductsPurchase();
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
    this.populateProductsPurchase();
  }

  public submitProducts(){
    this.continue.next();  
  }

}