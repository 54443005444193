import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MouseEvent } from '@agm/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../environments/environment';
import { FormsService } from './../../services/forms/forms.service';
import { AdminService } from './../../services/admin/admin.service';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.css']
})
export class ProvidersComponent implements OnInit {
  @ViewChild("table", { static: true }) table: any;
  public formProvider:FormGroup;
  public environment = environment;
  public itemSelected: any = {user: {rol: {}}};
  
  //table, pagination
  public config = {
    id: 'employees',
    searchText: '',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100]
  };
  public rows: any = [];

  //picture
  public files: File[] = [];
  public tempPhotoUrl: any;
  public responseMessage:String;

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private adminService: AdminService, private toastService: ToastrService) { }

  ngOnInit(): void {
    this.formProvider = this.formBuilder.group({
      rfc: ['', [Validators.required]],
      tradename: ['', [Validators.required]],
      businessName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      city: ['', [Validators.required]],
      address: ['', [Validators.required]]
    });
    this.populateProviders();
  }

  public populateProviders(){
    this.spinner.show();
    this.adminService.searchProviders(this.config).subscribe(
      res => {
        this.rows = res.data;
        this.config.totalItems = res.total;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public submitProvider(id){
    if(id === undefined){
      this.addProvider();
    }else{
      this.updateProvider(id);
    }
  }

  public addProvider() {
    console.log("addProvider-->");
    this.config.searchText = "";
    this.config.currentPage = 1;

    if (!this.formProvider.valid) {
       this.formsService.validateAllFormFields(this.formProvider);
    }else {
      this.spinner.show();
      this.formProvider.controls["rfc"].patchValue(this.formProvider.controls["rfc"].value.replace(/[^a-zA-Z0-9]/g, ''));
      this.formProvider.controls["phone"].patchValue(this.formProvider.controls["phone"].value.replace(/[^a-zA-Z0-9]/g, ''));
      this.adminService.addProvider(this.formProvider.value).subscribe(
        (res) => {
          this.modalService.getModal('providerModal').close();
          this.openSuccessModal("¡Proveedor agregado!", "Su registro ha sido guardado exitosamente.");
          this.populateProviders();
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public updateProvider(id){
    var changeImageRequired = true;
    console.log("addProvider-->");
    var file = this.files[0];
    if(file === null){
      changeImageRequired = false;
    }

    if(this.files.length == 0){
      changeImageRequired = false;
    }

    const formData = new FormData();
    formData.append('file', file);

    console.log("updateProvider-->", id);
    if (!this.formProvider.valid) {
       this.formsService.validateAllFormFields(this.formProvider);
    }else {
      this.spinner.show();
      this.formProvider.controls["rfc"].patchValue(this.formProvider.controls["rfc"].value.replace(/[^a-zA-Z0-9]/g, ''));
      this.formProvider.controls["phone"].patchValue(this.formProvider.controls["phone"].value.replace(/[^a-zA-Z0-9]/g, ''));
      this.adminService.updateProvider(id, this.formProvider.value).subscribe(
        (res) => {
          if(changeImageRequired){
            this.adminService.changeProviderLogo(id, formData).subscribe(
              (res2) => {
                this.files = new Array<File>();
                this.modalService.getModal('providerModal').close();
                this.openSuccessModal("¡Proveedor actualizado!", "Su registro ha sido modificado exitosamente.");
                this.files = new Array<File>();
                this.populateProviders();
                this.spinner.hide();
              },
              (err) => {
                console.log("err", err);
                this.responseMessage = err.error.message;
                this.spinner.hide();
              }
            )
          }else{
            this.modalService.getModal('providerModal').close();
            this.openSuccessModal("¡Proveedor actualizado!", "Su registro ha sido modificado exitosamente.");
            this.populateProviders();
            this.spinner.hide();
          }
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public deleteProvider(id){
    console.log("deleteProvider-->", id);
    this.spinner.show();
    this.adminService.deleteProvider(id).subscribe(
      (res) => {
        this.modalService.getModal('deleteModal').close();
        this.openSuccessModal("¡Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.populateProviders();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.responseMessage = err.error.message;
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  // actions
  edit(item) {
    this.itemSelected = item;
    this.openProviderModal(item);
  }

  enable(item){
    this.activeProvider(item, 1);
  }

  disable(item){
    this.activeProvider(item, 0);
  }

  activeProvider(item, status){
    this.itemSelected = item;
    this.adminService.enableProvider(item.id, { status: status })
      .subscribe(
        res => {
          if(res.status){
            this.openSuccessModal("¡Activado exitosamente!", "");
          }else{
            this.openSuccessModal("Desactivado exitosamente!", "");  
          }
          this.populateProviders();
        },
        err => {
          console.log("error ->", err);
        }
      );
  }

  delete(item) {
    this.itemSelected = item;
    this.modalService.open("deleteModal");
  }

  //modals
  public openProviderModal(item = null){
    this.formProvider.reset();
    this.itemSelected = {user: {rol: {}}};
    if(item){ //editing
      this.itemSelected = item;
      this.formProvider.patchValue(item);
    }
    this.modalService.getModal('providerModal').open();
    this.files = new Array<File>();
  }

  public openPictureModal(){
    this.modalService.getModal('pictureModal').open();
    this.files = new Array<File>();
  }
  public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal');
    this.modalService.setModalData(obj, 'successModal');
    this.modalService.getModal('successModal').open();
  }

  //drops
  public onSelect(event) {
    this.files.push(...event.addedFiles);
    var file = this.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.tempPhotoUrl = event.target.result;
      }
    }
  }

  public onSearch(){
    this.config.currentPage = 1;
    this.populateProviders();
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
    this.populateProviders();
  }

  public onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

}