import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
	private PATH_DASHBOARD:String = "/dashboard";
  private PATH_OPTIONS:String = "/options";

  constructor(private http: HttpClient) {}

  getDashboard() {
    return this.http.get<any>(environment.apiUrl + this.PATH_DASHBOARD + "?sort=sequence,DESC", httpOptions)
      .pipe(tap( // Log the result or error
        data => console.log(this.PATH_DASHBOARD, data),
	      error => throwError(error)
	    )
	  );
  }

  getOptions() {
    return this.http.get<any>(environment.apiUrl + this.PATH_OPTIONS + "?sort=sequence,DESC", httpOptions)
      .pipe(tap( // Log the result or error
        data => console.log(this.PATH_OPTIONS, data),
        error => throwError(error)
      )
    );
  }

}