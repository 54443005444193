import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ChartDataSets, ChartOptions, ChartType, Chart } from 'chart.js';
import { Label } from 'ng2-charts';
import { environment } from './../../../environments/environment';
import { AuthService } from './../../services/auth/auth.service';
import { DashboardService } from './../../services/dashboard/dashboard.service';
import { ReportsService } from './../../services/reports/reports.service';
import { ExcelsService } from './../../services/excels/excels.service';
import locale from '../../../assets/json/locale.json';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
	public profile: any = null;
  public listPanels: any = [];
  public imagesUrl: String = environment.imagesUrl;


  public chart: any = null;
  public datasets:any = [];
  public labels:any = [];
  
  public config = {
    id: 'all',
    searchText: '',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    startDate: null,
    endDate: null,
    options: [10, 20, 50, 100],
    order: {
      field: 'id',
      reverse: false
    }
  };

	constructor(private authService: AuthService, private dashboardService: DashboardService, private spinner: NgxSpinnerService,
    private router: Router, private datePipe: DatePipe, private reportsService: ReportsService, private excelsService: ExcelsService ) {
    const token = this.getParamValueQueryString("token");
    if(token){
      this.authService.logout();
    }
    this.authService.currentProfile.subscribe(profile => {
      if(profile && !(Object.keys(profile).length === 0) ){
        console.log("profile: ", profile);
      }else{
        this.router.navigate(['/login'], { queryParams: { token: token } });
      }
    });

    var date = new Date();
    var firstDay = new Date(date.getFullYear() - 1, date.getMonth(), date.getDay());
    var lastDay = new Date();
    this.config.startDate = firstDay;
    this.config.endDate = lastDay;
  }

	ngOnInit(): void {
    this.authService.currentProfile.subscribe(profile => {
  			if(profile && !(Object.keys(profile).length === 0)){
				console.log("profile: ", profile);
				this.profile = profile;
			}
    });
		this.spinner.show();
		this.dashboardService.getDashboard().subscribe(
        (res) => {
        	console.log("res:panels", res);
          this.listPanels = res;
        	this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.spinner.hide();
        }
  	);
    setTimeout(()=>{
      $("input[name='range-all']").daterangepicker({
        showDropdowns: true,
        "locale": locale,
        startDate: this.datePipe.transform(this.config.startDate, 'dd/MM/yyyy'),
        endDate: this.datePipe.transform(this.config.endDate, 'dd/MM/yyyy'),
      }, function(start, end, label) {
        console.log("A new date selection was made: " + this.datePipe.transform(start, 'dd/MM/yyyy') + ' to ' + this.datePipe.transform(end, 'dd/MM/yyyy'));
        this.config.startDate = start;
        this.config.endDate = end;
        this.getGraph();
      }.bind(this));
    }, 100);
    this.getGraphs();
  }

  public getGraphs(){
    console.log("getGraphs-->");
    this.spinner.show();
    this.reportsService.getGraphCompras(this.config).subscribe(
      (resCompras) => {
        console.log("compras-res->", resCompras);
        var data = resCompras.values.map(function(value) {
            return value.data.toFixed(2);
        });
        this.labels = resCompras.labels;
        this.datasets.push({
          "label": 'Compras',
          "data": data,
          "backgroundColor": "#8bde5e"
        });

        this.reportsService.getGraphVentas(this.config).subscribe(
          (resVentas) => {
            console.log("ventas-res->", resVentas);
            var data2 = resVentas.values.map(function(value) {
                return value.data.toFixed(2);
            });
            this.datasets.push({
              "label": 'Ventas',
              "data": data2,
              "backgroundColor": "#a3a1fb"
            });

            this.reportsService.getGraphLosses(this.config).subscribe(
              (resLosses) => {
                console.log("losses-res->", resLosses);
                var data3 = resLosses.values.map(function(value) {
                    return value.data.toFixed(2);
                });
                this.datasets.push({
                  "label": 'Mermas',
                  "data": data3,
                  "backgroundColor": "#f9db83"
                });

                this.reportsService.getGraphNet(this.config).subscribe(
                  (resNet) => {
                    console.log("net-res->", resNet);
                    var data4 = resNet.values.map(function(value) {
                        return value.data.toFixed(2);
                    });
                    this.datasets.push({
                      "label": 'Utilidad',
                      "data": data4,
                      "backgroundColor": "#1c62ff"
                    });

                    this.setGraph();
                    this.spinner.hide();
                    
                  }, (err) => {
                    console.log("err", err);
                    this.spinner.hide();
                  }
                );
              }, (err) => {
                console.log("err", err);
                this.spinner.hide();
              }
            );
          }, (err) => {
            console.log("err", err);
            this.spinner.hide();
          }
        );
      }, (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public setGraph(){
    if(!$("#baseChart")){
      return ;
    }
    if(this.chart){
      this.chart.data.labels = this.labels;
      this.chart.data.datasets = this.datasets;
      this.chart.update();
      this.saveImage();
      return;
    }
    this.chart = new Chart('baseChart', {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: this.datasets
      },
      options: {
        legend: { display: false },
        scales:  { xAxes: [{}], yAxes: [{ ticks: { 
            beginAtZero: true,
            callback: function(value:any, index, values) {
              if(parseInt(value) >= 1000){
                return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return '$' + value;
              }
            }
        } }] },
        tooltips: {
          callbacks: {
            label: function(tooltipItem:any, data) {
                if(parseInt(tooltipItem.yLabel) >= 1000){
                  return '$' + tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return '$' + tooltipItem.yLabel;
                }
            }
          }
        }
      }
    });
    setTimeout(()=>{
      this.saveImage();
    }, 100);
  }

  public saveImage(){
    var canvas = $("#baseChart").get(0);
    if(canvas){
      var dataURL = canvas.toDataURL();
      $("#fakeChart").attr("src", dataURL);      
    }
  }

  public getExcel(){
    console.log("getExcel-->");
    this.spinner.show();
    this.excelsService.getGraphAll(this.config).subscribe(
      (res) => {
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
  }

  getParamValueQueryString( paramName ) {
    const url = window.location.href;
      let paramValue;
      if (url.includes('?')) {
        const httpParams = new HttpParams({ fromString: url.split('?')[1] });
        paramValue = httpParams.get(paramName);
      }
      return paramValue;
  }

}