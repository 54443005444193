import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class SalesService {
	private PATH_SALES:String = "/saleProducts/";
  private PATH_PRODUCTS:String = "/productsOrder/";
  private PATH_OBSERVATION:String = "/observation/";
  private PATH_TRANSPORT_COSTS:String = "/expenseRecordsTransportLine/";
  private PATH_ADDITIONAL_COSTS:String = "/expenseRecordsAdditional/";

  constructor(private http: HttpClient) {}

  getSales() {
      return this.http.get<any>(environment.apiUrl + this.PATH_SALES, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_SALES, data),
          error => throwError(error)
        )
      );
  }

  searchSales(config) {
    var search = JSON.stringify(
      {"$or": [
        {"id": {"$cont": config.searchText}},
        /*{"product.name": {"$cont": config.searchText}},*/
        /*{"provider.businessName": {"$cont": config.searchText}},*/
        {"trip": {"$cont": config.searchText}},
      ]}
    );

    var sort = "&sort=id,DESC";

    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));
    
    return this.http.get<any>(environment.apiUrl + this.PATH_SALES + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search + sort, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_SALES, data),
          error => throwError(error)
        )
      );
  }

  addSale(obj) {
    console.log(obj);
    return this.http.post<any>(environment.apiUrl + this.PATH_SALES, obj, httpOptions)
      .pipe(tap( // Log the result or error
        data => console.log(this.PATH_SALES, data),
        error => throwError(error)
      )
    );
  }

  updateSale(id, obj) {
    return this.http.patch<any>(environment.apiUrl + this.PATH_SALES + id, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_SALES, data),
          error => throwError(error)
        )
      );
  }

  getSale(id) {
      return this.http.get<any>(environment.apiUrl + this.PATH_SALES + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_SALES, data),
          error => throwError(error)
        )
      );
  }

  deleteSale(id) {
    return this.http.delete<any>(environment.apiUrl + this.PATH_SALES + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_SALES, data),
          error => throwError(error)
        )
      );
  }


  /* Products */
  getProducts() {
      return this.http.get<any>(environment.apiUrl + this.PATH_PRODUCTS, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PRODUCTS, data),
          error => throwError(error)
        )
      );
  }

  searchProducts(saleId, config) {
    var search = JSON.stringify(
      {"$or": [
        {"id": {"$cont": config.searchText}},
        {"product.description": {"$cont": config.searchText}},
        {"product.name": {"$cont": config.searchText}},
      ]}
    );

    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));

    return this.http.get<any>(environment.apiUrl + this.PATH_SALES + saleId + '/temp?join=product&page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_SALES, data),
          error => throwError(error)
        )
      );
  }

  searchInventoryProducts(saleId, config) {
    var search = JSON.stringify(
      {"$or": [
        {"id": {"$cont": config.searchText}},
        {"product.description": {"$cont": config.searchText}},
        {"product.name": {"$cont": config.searchText}},
      ]}
    );

    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));

    return this.http.get<any>(environment.apiUrl + this.PATH_SALES + saleId + '?join=product&page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_SALES, data),
          error => throwError(error)
        )
      );
  }

  updateInventory(obj) {
    console.log("obj", obj);
      return this.http.post<any>(environment.apiUrl + this.PATH_SALES + "temp", obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_SALES, data),
          error => throwError(error)
        )
      );
  }

  addProduct(obj) {
      return this.http.post<any>(environment.apiUrl + this.PATH_PRODUCTS, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PRODUCTS, data),
          error => throwError(error)
        )
      );
  }

  updateProduct(id, obj) {
    return this.http.patch<any>(environment.apiUrl + this.PATH_PRODUCTS + id, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PRODUCTS, data),
          error => throwError(error)
        )
      );
  }

  getProduct(id) {
      return this.http.get<any>(environment.apiUrl + this.PATH_PRODUCTS + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PRODUCTS, data),
          error => throwError(error)
        )
      );
  }

  deleteProduct(id) {
    return this.http.delete<any>(environment.apiUrl + this.PATH_PRODUCTS + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_PRODUCTS, data),
          error => throwError(error)
        )
      );
  }


  /* Products */
  getObservations() {
      return this.http.get<any>(environment.apiUrl + this.PATH_OBSERVATION, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  searchObservations(saleId, config) {
    var search = JSON.stringify(
      {"$and": [
        {"$or": [
          {"id": {"$cont": config.searchText}},
          {"observation": {"$cont": config.searchText}},
        ]},
        {"saleProducts.id": {"$eq": saleId}}
      ]}
    );

    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));
    
    return this.http.get<any>(environment.apiUrl + this.PATH_OBSERVATION + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  updateObservation(id, obj) {
    return this.http.patch<any>(environment.apiUrl + this.PATH_OBSERVATION + id, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  getObservation(id) {
      return this.http.get<any>(environment.apiUrl + this.PATH_OBSERVATION + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  deleteObservation(id) {
    return this.http.delete<any>(environment.apiUrl + this.PATH_OBSERVATION + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  addObservation(obj) {
      return this.http.post<any>(environment.apiUrl + this.PATH_OBSERVATION, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }


  /* Transport Costs */
  getTransportCosts(saleId) {
    var search = JSON.stringify(
      {"saleProducts.id": {"$eq": saleId}}
    );

    return this.http.get<any>(environment.apiUrl + this.PATH_TRANSPORT_COSTS + '?s=' + search, httpOptions)
      .pipe(tap( // Log the result or error
        data => console.log(this.PATH_TRANSPORT_COSTS, data),
        error => throwError(error)
      )
    );
  }

  searchTransportCosts(saleId, config) {
    var search = JSON.stringify(
      {"$and": [
        {"$or": [
          {"id": {"$cont": config.searchText}},
          {"observation": {"$cont": config.searchText}},
        ]},
        {"saleProducts.id": {"$eq": saleId}}
      ]}
    );

    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));
    
    return this.http.get<any>(environment.apiUrl + this.PATH_TRANSPORT_COSTS + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_TRANSPORT_COSTS, data),
          error => throwError(error)
        )
      );
  }

  updateTransportCost(id, obj) {
    return this.http.patch<any>(environment.apiUrl + this.PATH_TRANSPORT_COSTS + id, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_TRANSPORT_COSTS, data),
          error => throwError(error)
        )
      );
  }

  getTransportCost(id) {
      return this.http.get<any>(environment.apiUrl + this.PATH_TRANSPORT_COSTS + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_TRANSPORT_COSTS, data),
          error => throwError(error)
        )
      );
  }

  deleteTransportCost(id) {
    return this.http.delete<any>(environment.apiUrl + this.PATH_TRANSPORT_COSTS + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_TRANSPORT_COSTS, data),
          error => throwError(error)
        )
      );
  }

  addTransportCost(obj) {
      return this.http.post<any>(environment.apiUrl + this.PATH_TRANSPORT_COSTS, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_TRANSPORT_COSTS, data),
          error => throwError(error)
        )
      );
  }


  /* Additional Costs */
  getAdditionalCosts(saleId) {
    var search = JSON.stringify(
      {"saleProducts.id": {"$eq": saleId}}
    );
      return this.http.get<any>(environment.apiUrl + this.PATH_ADDITIONAL_COSTS + '?s=' + search, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_ADDITIONAL_COSTS, data),
          error => throwError(error)
        )
      );
  }

  searchAdditionalCosts(saleId, config) {
    var search = JSON.stringify(
      {"$and": [
        {"$or": [
          {"id": {"$cont": config.searchText}},
          {"observation": {"$cont": config.searchText}},
        ]},
        {"saleProducts.id": {"$eq": saleId}}
      ]}
    );

    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));
    
    return this.http.get<any>(environment.apiUrl + this.PATH_ADDITIONAL_COSTS + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_ADDITIONAL_COSTS, data),
          error => throwError(error)
        )
      );
  }

  updateAdditionalCost(id, obj) {
    return this.http.patch<any>(environment.apiUrl + this.PATH_ADDITIONAL_COSTS + id, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_ADDITIONAL_COSTS, data),
          error => throwError(error)
        )
      );
  }

  getAdditionalCost(id) {
      return this.http.get<any>(environment.apiUrl + this.PATH_ADDITIONAL_COSTS + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_ADDITIONAL_COSTS, data),
          error => throwError(error)
        )
      );
  }

  deleteAdditionalCost(id) {
    return this.http.delete<any>(environment.apiUrl + this.PATH_ADDITIONAL_COSTS + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_ADDITIONAL_COSTS, data),
          error => throwError(error)
        )
      );
  }

  addAdditionalCost(obj) {
      return this.http.post<any>(environment.apiUrl + this.PATH_ADDITIONAL_COSTS, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_ADDITIONAL_COSTS, data),
          error => throwError(error)
        )
      );
  }

}