import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgxSmartModalService, NgxSmartModalComponent, } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../../../environments/environment';
import { FormsService } from './../../../../services/forms/forms.service';
import { AdminService } from './../../../../services/admin/admin.service';
import { DriversComponent } from './../../../../screens/drivers/drivers.component';
import { LossesService } from './../../../../services/losses/losses.service';

@Component({
  selector: 'app-register-lost',
  templateUrl: './register-lost.component.html',
  styleUrls: ['./register-lost.component.css']
})
export class RegisterLostComponent implements OnInit, OnChanges {
	environment = environment;

	@Output() continue = new EventEmitter<string>();
  @Input() lostSelected;

  public imagesUrl: String = environment.imagesUrl;
	public profile: any = null;
	public company: any = null;

  public gastos: [] = [];

	constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private adminService: AdminService, private toastService: ToastrService, private lossesService: LossesService, private router: Router) {
	}

	ngOnInit(): void {
    this.adminService.currentCompany.subscribe(company => {
      this.company = company;
			console.log("company: ", company);
    });
    this.getLost(this.lostSelected.id);
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    let log: string[] = [];
    for (let propName in changes) {
      if(propName == 'lostSelected'){
        console.log("lostSelected --> ", this.lostSelected);
      }
    }
  }

  public getLost(id){
    console.log("getLost-->", id);
    this.spinner.show();
    this.lossesService.getLost(id).subscribe(
      (res) => {
        console.log("lost-res->", res);
        this.lostSelected = res;
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public saveLost(){
    this.modalService.getModal('successModal').open();
  }
  
}