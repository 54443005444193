<div class="container">
  <!-- Outer Row -->
  <div *ngIf="currentState == 'Login'" class="row justify-content-center">
    <div class="col-xl-7 col-lg-7 col-md-9">
      <div id="card-login" class="card o-hidden my-5">
        <div class="card-body p-0">
          <!-- Nested Row within Card Body -->
          <div class="row">
            <div class="col-lg-12">
              <div class="p-login">
                <div class="text-center">
                  <h1 class="h6 text-primary mb-4">Identifícate con usuario y contraseña</h1>
                </div>
                <form class="user" [formGroup]="formLogin" (ngSubmit)="login()">
                  <div class="row form-card">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-1">
                      <img src="assets/icons/ic_user.png" class="icon">
                    </div>
                    <div class="col-11">
                      <div class="form-group">
                        <label for="username">USUARIO</label>
                        <input type="email" class="form-control no-border" name="username" id="username" formControlName="username">
                      </div>
                    </div>
                    <div class="col-12" style="margin-top: -0.5rem;">
                      <div *ngIf="errorLogin || (formLogin.controls['username'].invalid && (formLogin.controls['username'].dirty || formLogin.controls['username'].touched))" class="inputAlert">
                        <div *ngIf="formLogin.controls['username'].errors && formLogin.controls['username'].errors.required">
                          Usuario obligatorio
                        </div>
                        <div *ngIf="errorLogin">
                          {{errorLogin}}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row form-card mt-4">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-1">
                      <img src="assets/icons/ic_lock.png" class="icon">
                    </div>
                    <div class="col-11">
                      <div class="form-group">
                        <label for="password">CONTRASEÑA</label>
                        <input type="password" class="form-control no-border" name="password" id="password" formControlName="password">
                      </div>
                    </div>
                    <div class="col-12" style="margin-top: -0.5rem;">
                      <div *ngIf="formLogin.controls['password'].invalid && (formLogin.controls['password'].dirty || formLogin.controls['password'].touched)" class="inputAlert">
                        <div *ngIf="formLogin.controls['password'].errors.required">
                          Contraseña obligatoria
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center mt-4">
                    <div class="col-lg-7">
                      <button type="submit" class="btn btn-primary btn-user btn-block shadow-sm">
                        INGRESAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                      </button>
                    </div>
                  </div>
                  <div class="row justify-content-center mt-4">
                    <div class="col-lg-7">
                      <div class="text-center">
                        <a (click)="currentState = 'RecoverPassword'; formPassword.reset(); errorLogin = ''; " class="h6 text-primary mb-4 pointer">¿Olvidaste tu contraseña?</a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentState == 'RecoverPassword'" class="row justify-content-center">
    <div class="col-xl-5 col-lg-5 col-md-7">
      <div id="card-login" class="card o-hidden my-5">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-lg-12">
              <div class="p-5">
                <div class="text-center">
                  <h1 class="h6 mb-4">Ingresa el email y nosotros enviaremos un link a tu correo para que puedas restablecer tu contraseña.</h1>
                </div>
                <form class="user" [formGroup]="formPassword" (ngSubmit)="recoverPassword()">
                  <div class="row form-card">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-1">
                      <i class="far fa-envelope" style="margin-top: 20px;"></i>
                    </div>
                    <div class="col-11">
                      <div class="form-group">
                        <label for="username">EMAIL</label>
                        <input type="email" class="form-control no-border" name="email" id="email" formControlName="email">
                      </div>
                    </div>
                    <div class="col-12" style="margin-top: -0.5rem;">
                      <div *ngIf="errorLogin || (formPassword.controls['email'].invalid && (formPassword.controls['email'].dirty || formPassword.controls['email'].touched))" class="inputAlert">
                        <div *ngIf="formPassword.controls['email'].errors && formPassword.controls['email'].errors.required">
                          Email obligatorio
                        </div>
                        <div *ngIf="errorLogin">
                          {{errorLogin}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center mt-4">
                    <div class="col-lg-7">
                      <button type="submit" class="btn btn-primary btn-user btn-block shadow-sm">
                        ENVIAR CORREO <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                      </button>
                    </div>
                  </div>
                  <div class="row justify-content-center mt-4">
                    <div class="col-lg-7">
                      <div class="text-center">
                        <a (click)="currentState = 'Login'; formLogin.reset(); errorLogin = '';" class="h6 mb-4 pointer">Regresar</a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentState == 'CreateNewPassword'" class="row justify-content-center">
    <div class="col-xl-7 col-lg-7 col-md-9">
      <div id="card-login" class="card o-hidden my-5">
        <div class="card-body p-0">
          <!-- Nested Row within Card Body -->
          <div class="row">
            <div class="col-lg-12">
              <div class="p-login">
                <div class="text-center">
                  <h1 class="h6 text-primary mb-4">Crear una nueva contraseña</h1>
                </div>
                <form class="user" [formGroup]="formNewPassword" (ngSubmit)="createPassword()">
                  <div class="row form-card">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-1">
                      <img src="assets/icons/ic_lock.png" class="icon">
                    </div>
                    <div class="col-11">
                      <div class="form-group">
                        <label for="password">INGRESE UNA NUEVA CONTRASEÑA</label>
                        <input type="password" class="form-control no-border" name="password" id="password" formControlName="password">
                      </div>
                    </div>
                    <div class="col-12" style="margin-top: -0.5rem;">
                      <div *ngIf="errorLogin || (formNewPassword.controls['password'].invalid && (formNewPassword.controls['password'].dirty || formNewPassword.controls['password'].touched))" class="inputAlert">
                        <div *ngIf="formNewPassword.controls['password'].errors && formNewPassword.controls['password'].errors.required">
                          Contraseña obligatoria
                        </div>
                        <div *ngIf="errorLogin">
                          {{errorLogin}}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row form-card mt-4">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-1">
                      <img src="assets/icons/ic_lock.png" class="icon">
                    </div>
                    <div class="col-11">
                      <div class="form-group">
                        <label for="password_confirm">CONFIRMAR CONTRASEÑA</label>
                        <input type="password" class="form-control no-border" name="password_confirm" id="password_confirm" formControlName="password_confirm">
                      </div>
                    </div>
                    <div class="col-12" style="margin-top: -0.5rem;">
                      <div *ngIf="formNewPassword.controls['password_confirm'].invalid && (formNewPassword.controls['password_confirm'].dirty || formNewPassword.controls['password_confirm'].touched)" class="inputAlert">
                        <div *ngIf="formNewPassword.controls['password_confirm'].errors.required">
                          Confirmación de Contraseña obligatoria
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center mt-4">
                    <div class="col-lg-7">
                      <button type="submit" class="btn btn-primary btn-user btn-block shadow-sm">
                        CAMBIAR CONTRASEÑA<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div *ngIf="successModal.hasData()" class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">{{ successModal.getData().title }}</h1>
        <p class="h6" style="font-size: 0.9rem;">{{ successModal.getData().subtitle }} </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close(); currentState='Login'" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>