<section class="container justify-content-center text-center p-0">
  <article class="row justify-content-center" id="print-section">
    <div class="col-12">
      <h4 class="catalog-title font-weight-bold text-dark mb-5">
        EXISTENCIA EN INVENTARIO
      </h4>
      <div class="container">
        <div class="row mb-5 non-printable" style="align-items: baseline;">
          <div class="col-lg-6 col-8">
            <div class="search-control">
              <i class="fa fa-search"></i>
              <input type="search" #searchInput (search)="onSearch($event.target.value)" class="form-control input-search text-dark" placeholder="Buscar por orden de compra (O.C), producto, viaje."/>
            </div>
          </div>
          <div class="col-lg-6 col-4 text-right"></div>
        </div>
        <div class="row mb-5" style="align-items: baseline;">
          <div class="col-lg-2 col-12" style="text-align: right;">
            <select class="form-control" (change)="onSort($event.target.value);">
              <option value="" disabled="disabled">Ordenar por:</option>
              <option value="id">O.C</option>
              <option value="purchaseDate">Fecha de Llegada</option>
              <option value="trip">Viaje</option>
              <option value="tag">Etiqueta</option>
              <option value="presentation">Presentación</option>
              <option value="units">Unidades</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table no-border" style="text-align: left;">
                <thead>
                  <th scope="col">O.C</th>
                  <th scope="col">VIAJE</th>
                  <th scope="col" style="width: 20%;">PRODUCTO</th>
                  <th scope="col">CANTIDAD INVENTARIO</th>
                  <th scope="col" style="width: 20%">CANTIDAD</th>
                  <th scope="col">PRECIO</th>
                </thead>
                <tbody>
                  <ng-container *ngFor="let row of rows | filterBy: { $or : [
                          { 'id' : config.searchText}, 
                          { 'trip' : config.searchText}, 
                          { 'name' : config.searchText}
                        ]}
                      | paginate: config | orderBy: config.order.field: false;index as i">
                    <tr id="row-{{row.uniqueProduct}}" class="{{row.customQuantity? 'row-selected': ''}}">
                      <td>{{row.id}}</td>
                      <td>
                        <div><b>{{row.trip}}</b></div>
                        <div>{{row.purchaseDate}}</div>
                      </td>
                      <td>
                        <div><b>Nombre:</b> {{row.name}}</div>
                        <div><b>Etiqueta:</b> {{row.tag}}</div>
                        <div><b>Presentación: </b>{{row.presentation}} </div>
                        <div><b>Tamaño: {{row.size}}</b></div>
                      </td>
                      <td>{{row.quantity}} {{row.units}}</td>
                      <td>
                        <input type="number" [(ngModel)]="row.customQuantity" min="0" max="{{row.quantity}}" step="1" class="input-spinner" name="quantity" style="border: 1px solid;" (change)="onItemChanged(row)" id="{{row.uniqueProduct}}" />
                      </td>
                      <td>
                        <input type="text" class="form-control dropdown-form-control" name="price"
                        [(ngModel)]="row.customPrice" [disabled]="row.customQuantity == 0" prefix="$" mask="separator.2" thousandSeparator="," id="price-{{row.uniqueProduct}}" (change)="onItemChanged(row)">
                        <span>{{(row.productsSelected? row.productsSelected.price: row.unitPrice) | currency}}</span>
                        <div class="mt-1"><b>Costo de compra: </b>{{row.unitPrice | currency}}</div>
                      </td>
                    </tr>
                  </ng-container>
                  <tr *ngIf="(rows).length === 0">
                    <td colspan="9">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagerChanged($event)">

              <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                  <a (click)="p.previous()" *ngIf="!p.isFirstPage();">
                    <img class="btn-icon" src="../../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isFirstPage();">
                    <img class="btn-icon" src="../../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

              <div *ngFor="let page of p.pages" class="page-number" [class.current]="p.getCurrent() === page.value">
                  <a (click)="p.setCurrent(page.value);" *ngIf="p.getCurrent() !== page.value">
                      <span>{{ page.label }}</span>
                  </a>
                  <div *ngIf="p.getCurrent() === page.value">
                      <span>{{ page.label }}</span>
                  </div>
              </div>

              <div class="pagination-next" [class.disabled]="p.isLastPage()">
                  <a (click)="p.next()" *ngIf="!p.isLastPage();">
                    <img class="btn-icon" src="../../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isLastPage();">
                    <img class="btn-icon" src="../../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>
            </pagination-template>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 pager">
            <label>Mostrar <select [(ngModel)]="config.itemsPerPage" (change)='onPagerChanged(1);'>
              <option *ngFor="let c of config.options" [ngValue]="c">{{c}}</option>
            </select> Registros por página</label>
          </div>
        </div>
        <div class="row justify-content-center mtr-2 non-printable">
          <div class="col-3">
            <button type="submit" (click)="submitInventory();" class="btn btn-primary btn-user btn-block shadow-sm">Agregar productos<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
            </button>
          </div>
          <div class="col-2">
             <button type="cancel" (click)="modalService.getModal('inventoryModal').close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>