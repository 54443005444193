<section class="container justify-content-center text-center p-0">
  <article id="print-section" class="row justify-content-center">
    <div class="col-12 text-center">
      <h4 class="catalog-title font-weight-bold text-dark mb-3">
        <img class="header-image" src="../assets/icons/ic_inventory.png"/>
        GESTIÓN DE INVENTARIO
      </h4>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div *ngFor="let item of rows | paginate: config">
              <app-product-inventory-single [product]="item"></app-product-inventory-single>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
  <div class="row">
    <div class="col-lg-12">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagerChanged($event)">

              <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                  <a (click)="p.previous()" *ngIf="!p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

              <div *ngFor="let page of p.pages" class="page-number" [class.current]="p.getCurrent() === page.value">
                  <a (click)="p.setCurrent(page.value);" *ngIf="p.getCurrent() !== page.value">
                      <span>{{ page.label }}</span>
                  </a>
                  <div *ngIf="p.getCurrent() === page.value">
                      <span>{{ page.label }}</span>
                  </div>
              </div>

              <div class="pagination-next" [class.disabled]="p.isLastPage()">
                  <a (click)="p.next()" *ngIf="!p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

            </pagination-template>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 pager">
            <label>Mostrar <select [(ngModel)]="config.itemsPerPage" (change)='onPagerChanged(1);'>
              <option *ngFor="let c of config.options" [ngValue]="c">{{c}}</option>
            </select> Registros por página</label>
          </div>
        </div>

        <div class="row justify-content-center mtr-2 non-printable">
          <div class="col-3">
            <button type="submit" class="btn btn-primary btn-user btn-block shadow-sm" printSectionId="print-section" [useExistingCss]="true" ngxPrint>IMPRIMIR<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
            </button>
          </div>
          <div class="col-2">
             <button type="cancel" [routerLink]="['/inventory']" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>