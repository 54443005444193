<section class="container justify-content-center text-center p-0">
  <article class="row justify-content-center">
    <div class="col-12">
      <div class="container">
        <div class="row mb-5" style="align-items: baseline;">
          <div class="col-lg-6 col-8">
            <div class="search-control">
              <i class="fa fa-search"></i>
              <input type="search" #searchInput [(ngModel)]="config.searchText" (search)="onSearch()" class="form-control input-search text-dark" placeholder="Buscar por id, nombre, descripción"/>              
            </div>
          </div>
          <div class="col-lg-6 col-4 text-right">
            <div class="catalog-btn">
              <img src="../assets/icons/ic_plus.png" (click)="openProductModal();" />
              <label (click)="openProductModal();">NUEVO PRODUCTO</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">CANTIDAD</th>
                    <th scope="col">UNIDAD</th>
                    <th scope="col">PRODUCTO</th>
                    <th scope="col">CALIDAD</th>
                    <th scope="col">PRESENTACIÓN</th>
                    <th scope="col">ETIQUETA</th>
                    <th scope="col">TAMAÑO</th>
                    <th scope="col">COSTO DE COMPRA</th>
                    <th scope="col">IMPORTE</th>
                    <th scope="col">ACCIONES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rows | paginate: config">
                    <td>{{item.quantity}}</td>
                    <td>{{item.units.name}}</td>
                    <td>{{item.product.name}}</td>
                    <td>{{item.quality.name}}</td>
                    <td>{{item.presentation.name}} {{item.presentation.shortname}}</td>
                    <td>{{item.tag.name}}</td>
                    <td>{{item.size.name}}</td>
                    <td>{{item.price | currency }}</td>
                    <td>{{(item.price * item.quantity) | currency }}</td>
                    <td class="iconContainer" style="display: flex;">
                      <img class="catalog-icon" src="../assets/icons/ic_edit.png" (click)="openProductModal(item);" placement="top" ngbTooltip="editar"/>
                      <!--
                      <img *ngIf="item.enabled" class="catalog-icon" src="../assets/icons/ic_enabled.png" (click)="active(item, false);" placement="top" ngbTooltip="deshabilitar"/>
                      <img *ngIf="!item.enabled" class="catalog-icon" src="../assets/icons/ic_disabled.png" (click)="active(item, true);" placement="top" ngbTooltip="habilitar"/>
                      !-->
                      <img *ngIf="!item.enabled" class="catalog-icon" src="../assets/icons/ic_delete.png" (click)="delete(item);" placement="top" ngbTooltip="eliminar"/>
                    </td>
                  </tr>
                  <tr *ngIf="(rows).length === 0">
                    <td colspan="6">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagerChanged($event)">

              <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                  <a (click)="p.previous()" *ngIf="!p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

              <div *ngFor="let page of p.pages" class="page-number" [class.current]="p.getCurrent() === page.value">
                  <a (click)="p.setCurrent(page.value);" *ngIf="p.getCurrent() !== page.value">
                      <span>{{ page.label }}</span>
                  </a>
                  <div *ngIf="p.getCurrent() === page.value">
                      <span>{{ page.label }}</span>
                  </div>
              </div>

              <div class="pagination-next" [class.disabled]="p.isLastPage()">
                  <a (click)="p.next()" *ngIf="!p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

            </pagination-template>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 pager">
            <label>Mostrar <select [(ngModel)]="config.itemsPerPage" (change)='onPagerChanged(1);'>
              <option *ngFor="let c of config.options" [ngValue]="c">{{c}}</option>
            </select> Registros por página</label>
          </div>
        </div>
        <div class="row justify-content-center mtr-2">
          <div class="col-3">
            <button type="submit" (click)="submitProducts()" class="btn btn-primary btn-user btn-block shadow-sm">CONTINUAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
            </button>
          </div>
          <div class="col-2">
             <button type="cancel" [routerLink]="['/purchases']" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
          </div>
        </div>

      </div>
    </div>
  </article>
</section>

<ngx-smart-modal customClass="xl-dialog closable" #productModal identifier="productModal" [closable]="true">
  <button class="btn-back-modal" (click)="productModal.close()">
    <i class="fas fa-long-arrow-alt-left"></i>
  </button>
  <div class="container justify-content-center text-left">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="row justify-content-center mb--4">
          <div class="col-6 text-center">
            <h1 class="h6 font-weight-bold text-dark mb-0 ml-3">
              <p *ngIf="!productPurchaseSelected.id">AGREGAR PRODUCTO</p>
              <p *ngIf="productPurchaseSelected.id">EDITAR PRODUCTO</p>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container justify-content-center text-left">
      <form [formGroup]="formProduct">
        <div class="row justify-content-center mt-3">
          <div class="col-lg-6">
            <div class="form-group mb-0 label-animate">
              <label for="id" class="dark" style="top: 5px !important; color: #afb1cc; left: 10px; font-size: 0.75em;"><b>*</b>Seleccione un producto</label>
              <select class="form-control no-border" data-live-search="true" name="id" id="id" (change)="onProductChanged($event.target.value);" formControlName="id" value="{{productPurchaseSelected.product.id}}">
                <option value="0" disabled="disabled">--</option>
                <option *ngFor="let p of productList" value="{{p.id}}" attr.data-tokens="p.name">{{p.name}}</option>
              </select>
              <div class="border-control"></div>
              <div *ngIf="formProduct.controls['id'].invalid && (formProduct.controls['id'].dirty || formProduct.controls['id'].touched)" class="inputAlert">
                <div *ngIf="formProduct.controls['id'].errors && formProduct.controls['id'].errors.required">
                  Producto obligatorio
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-0 label-animate">
              <label for="quantity" class="dark" style="top: 5px !important; color: #afb1cc; left: 10px; font-size: 0.75em;"><b>*</b>Ingrese la cantidad de productos</label>
              <input type="text" class="form-control no-border" name="quantity" id="quantity" formControlName="quantity" value="{{productPurchaseSelected.quantity}}" prefix="" mask="separator.0" thousandSeparator=",">
              <div class="border-control"></div>
              <div *ngIf="formProduct.controls['quantity'].invalid && (formProduct.controls['quantity'].dirty || formProduct.controls['quantity'].touched)" class="inputAlert">
                <div *ngIf="formProduct.controls['quantity'].errors && formProduct.controls['quantity'].errors.required">
                  Cantidad del producto obligatorio
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-lg-6">
            <div class="form-group mb-0 label-animate">
              <label for="units" class="dark" style="top: 5px !important; color: #afb1cc; left: 10px; font-size: 0.75em;"><b>*</b>Seleccione unidad de medida</label>
              <select class="form-control no-border" data-live-search="true" name="units" id="units" formControlName="units" value="{{productPurchaseSelected.units.id}}" (change)='onUnitChanged($event.target.value);'>
                <option value="0" disabled="disabled">--</option>
                <option *ngFor="let unit of unitList" value="{{unit.id}}" attr.data-tokens="{{unit.name}}">{{unit.name}}</option>
              </select>
              <div class="border-control"></div>
              <div *ngIf="formProduct.controls['units'].invalid && (formProduct.controls['units'].dirty || formProduct.controls['units'].touched)" class="inputAlert">
                <div *ngIf="formProduct.controls['units'].errors && formProduct.controls['units'].errors.required">
                  Unidad obligatoria
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-0 label-animate">
              <label for="quality" class="dark" style="top: 5px !important; color: #afb1cc; left: 10px; font-size: 0.75em;"><b>*</b>Seleccione calidad</label>
              <select class="form-control no-border" data-live-search="true" name="quality" id="quality" formControlName="quality" value="{{productPurchaseSelected.quality.id}}" (change)='onQualityChanged($event.target.value);'>
                <option value="0" disabled="disabled">--</option>
                <option *ngFor="let unit of qualityList" value="{{unit.id}}" attr.data-tokens="unit.name">{{unit.name}}</option>
              </select>
              <div class="border-control"></div>
              <div *ngIf="formProduct.controls['quality'].invalid && (formProduct.controls['quality'].dirty || formProduct.controls['quality'].touched)" class="inputAlert">
                <div *ngIf="formProduct.controls['quality'].errors && formProduct.controls['quality'].errors.required">
                  Calidad obligatoria
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-lg-6">
            <div class="form-group mb-0 label-animate">
              <label for="presentation" class="dark" style="top: 5px !important; color: #afb1cc; left: 10px; font-size: 0.75em;"><b>*</b>Seleccione presentación</label>
              <select class="form-control no-border" data-live-search="true" name="presentation" id="presentation" formControlName="presentation" value="{{productPurchaseSelected.presentation.id}}" (change)='onPresentationChanged($event.target.value);' >
                <option value="0" disabled="disabled">--</option>
                <option *ngFor="let unit of presentationList" value="{{unit.id}}" attr.data-tokens="unit.name + ',' + unit.shortname" >{{unit.name}} {{unit.shortname}}</option>
              </select>
              <div class="border-control"></div>
              <div *ngIf="formProduct.controls['presentation'].invalid && (formProduct.controls['presentation'].dirty || formProduct.controls['presentation'].touched)" class="inputAlert">
                <div *ngIf="formProduct.controls['presentation'].errors && formProduct.controls['presentation'].errors.required">
                  Presentación obligatoria
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-0 label-animate">
              <label for="tag" class="dark" style="top: 5px !important; color: #afb1cc; left: 10px; font-size: 0.75em;"><b>*</b>Seleccione etiqueta</label>
              <select class="form-control no-border" data-live-search="true" name="tag" id="tag" formControlName="tag" value="{{productPurchaseSelected.tag.id}}" (change)='onTagChanged($event.target.value);'>
                <option value="0" disabled="disabled">--</option>
                <option *ngFor="let unit of tagList" value="{{unit.id}}" attr.data-tokens="unit.name">{{unit.name}}</option>
              </select>
              <div class="border-control"></div>
              <div *ngIf="formProduct.controls['tag'].invalid && (formProduct.controls['tag'].dirty || formProduct.controls['tag'].touched)" class="inputAlert">
                <div *ngIf="formProduct.controls['tag'].errors && formProduct.controls['tag'].errors.required">
                  Etiqueta obligatoria
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-lg-6">
            <div class="form-group mb-0 label-animate">
              <label for="size" class="dark" style="top: 5px !important; color: #afb1cc; left: 10px; font-size: 0.75em;"><b>*</b>Seleccione tamaño</label>
              <select class="form-control no-border" data-live-search="true" name="size" id="size" formControlName="size" value="{{productPurchaseSelected.size.id}}" (change)='onSizeChanged($event.target.value);'>
                <option value="0" disabled="disabled">--</option>
                <option *ngFor="let unit of sizeList" value="{{unit.id}}" attr.data-tokens="unit.name">{{unit.name}}</option>
              </select>
              <div class="border-control"></div>
              <div *ngIf="formProduct.controls['size'].invalid && (formProduct.controls['size'].dirty || formProduct.controls['size'].touched)" class="inputAlert">
                <div *ngIf="formProduct.controls['size'].errors && formProduct.controls['size'].errors.required">
                  Tamaño obligatorio
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-0 label-animate">
              <label for="price" class="dark" style="top: 5px !important; color: #afb1cc; left: 10px; font-size: 0.75em;"><b>*</b>Ingrese el costo del producto</label>
              <input type="text" class="form-control no-border" name="price" id="price" formControlName="price" value="{{productPurchaseSelected.price}}" prefix="$" mask="separator.2" thousandSeparator=",">
              <div class="border-control"></div>
              <div *ngIf="formProduct.controls['price'].invalid && (formProduct.controls['price'].dirty || formProduct.controls['price'].touched)" class="inputAlert">
                <div *ngIf="formProduct.controls['price'].errors && formProduct.controls['price'].errors.required">
                  Costo del producto obligatorio
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-lg-5">
            <button type="submit" (click)="submitProduct(productPurchaseSelected.id)" class="btn btn-primary btn-user btn-block shadow-sm">
              <span *ngIf="!productPurchaseSelected.id">AGREGAR PRODUCTO</span>
              <span *ngIf="productPurchaseSelected.id">GUARDAR</span>
              <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
            </button>
          </div>
          <div class="col-lg-3">
            <button type="cancel" (click)="productModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ngx-smart-modal>


<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div *ngIf="successModal.hasData()" class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">{{ successModal.getData().title }}</h1>
        <p class="h6" style="font-size: 0.9rem;">{{ successModal.getData().subtitle }} </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #deleteModal identifier="deleteModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_warning.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¿Desea eliminar este registro?</h1>
        <p class="h6" style="font-size: 0.9rem;">Recuerda que será eliminado de la base de datos</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <button type="submit" (click)="deleteProduct(productPurchaseSelected.id)" class="btn btn-primary btn-user btn-block shadow-sm">SÍ, ELIMINAR<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
      <div class="col-4">
        <button type="cancel" (click)="deleteModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
      </div>
    </div>
  </div>
</ngx-smart-modal>