import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { ReportsService } from './../../../../services/reports/reports.service';
import { ExcelsService } from './../../../../services/excels/excels.service';
import locale from '../../../../../assets/json/locale.json';
declare var $: any;

@Component({
  selector: 'app-graph-orders-provider',
  templateUrl: './graph-orders-provider.component.html',
  styleUrls: ['./graph-orders-provider.component.css']
})
export class GraphOrdersProviderComponent implements OnInit {
  public config = {
    id: 'orders-by-provider',
    searchText: '',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    startDate: null,
    endDate: null,
    options: [10, 20, 50, 100],
    order: {
      field: 'id',
      reverse: false
    }
  };
  public rows: [] = [];
  public summary = {
    totalPurchaseProducts: 0
  }
  
	constructor(private datePipe: DatePipe, private spinner: NgxSpinnerService, 
    private filterPipe: FilterPipe, private reportsService: ReportsService, private excelsService: ExcelsService) {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.config.startDate = firstDay;
    this.config.endDate = lastDay;
  }

	ngOnInit(): void {
    $("input[name='range-orders-provider']").daterangepicker({
      showDropdowns: true,
      "locale": locale,
      startDate: this.datePipe.transform(this.config.startDate, 'dd/MM/yyyy'),
      endDate: this.datePipe.transform(this.config.endDate, 'dd/MM/yyyy'),
    }, function(start, end, label) {
      console.log("A new date selection was made: " + this.datePipe.transform(start, 'dd/MM/yyyy') + ' to ' + this.datePipe.transform(end, 'dd/MM/yyyy'));
      this.config.startDate = start;
      this.config.endDate = end;
      this.getReport();
    }.bind(this));
    this.getReport();
  }

  public getReport(){
    console.log("getReport-->");
    this.spinner.show();
    this.reportsService.getReporteComprasPorProveedor(this.config).subscribe(
      (res) => {
        console.log("report-res->", res);
        this.rows = res.list;
        this.summary.totalPurchaseProducts = res.totalPurchaseProducts;
        this.config.totalItems = res.totalItems;
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public getExcel(){
    console.log("getExcel-->");
    this.spinner.show();
    this.excelsService.getReporteComprasPorProveedor(this.config).subscribe(
      (res) => {
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }



  public onPagerChanged($event){
    this.config.currentPage = $event;
    this.getReport();
  }
  
}