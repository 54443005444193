<div class="container report-box-container p-3">
  <div class="row mb-3">
    <div class="col-6 text-left">
      <span class="primary">
        <b>Ventas por cliente</b>
      </span>
      <span style="display: block; color: #144272; font-size: 14px;">Se reflejan los montos totales de venta por cliente</span>
    </div>
    <div class="col-4 text-right">
      <div class="date_range_form">
        <img class="icon_date" src="../assets/icons/ic_tabs_date.png">
        <input class="form-control range" name="range-sales-client" type="text">
      </div>
    </div>
    <div class="col-2 text-right">
      <button class="form-control btn-export" (click)="getExcel();">
        <i class="fas fa-file"></i> Exportar
      </button>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-4 text-left"></div>
    <div class="col-8 text-right">
      <span style="font-size: 12px;">Monto total de ventas</span>
      <span style="display: block; color: #144272;">
        <b>{{summary.totalAmount | currency}}</b>
      </span>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <table class="table report-table">
        <thead class="gray-area">
          <tr>
            <th scope="col">Cliente</th>
            <th scope="col">Importe de Venta</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of rows | paginate: config">
            <td scope="col">{{item.clientName}}</td>
            <td scope="col">{{item.amount | currency }}</td>
          </tr>
          <tr *ngIf="(rows).length === 0">
            <td colspan="2">No se encontraron elementos</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-6 text-left">
      <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagerChanged($event)">
        <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
            <a (click)="p.previous()" *ngIf="!p.isFirstPage();">
              <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
            </a>
            <a *ngIf="p.isFirstPage();">
              <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
            </a>
        </div>

        <div *ngFor="let page of p.pages" class="page-number" [class.current]="p.getCurrent() === page.value">
            <a (click)="p.setCurrent(page.value);" *ngIf="p.getCurrent() !== page.value">
                <span>{{ page.label }}</span>
            </a>
            <div *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
            </div>
        </div>

        <div class="pagination-next" [class.disabled]="p.isLastPage()">
            <a (click)="p.next()" *ngIf="!p.isLastPage();">
              <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
            </a>
            <a *ngIf="p.isLastPage();">
              <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
            </a>
        </div>
      </pagination-template>
    </div>
    <div class="col-6 pager">
      <label>Mostrar <select [(ngModel)]="config.itemsPerPage" (change)='onPagerChanged(1);'>
        <option *ngFor="let c of config.options" [ngValue]="c">{{c}}</option>
      </select>Registros por página</label>
    </div>
  </div>
</div>