import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class LossesService {
	private PATH_LOSSES:String = "/losses/";
  private PATH_INVENTORY:String = "/inventory/";
  private PATH_OBSERVATION:String = "/observation/";

  constructor(private http: HttpClient) {}

  searchLosses(config) {
    var search = JSON.stringify(
      {"$or": [
        {"id": {"$cont": config.searchText}},
        {"productsOrder.purchaseProducts.trip": {"$cont": config.searchText}},
        {"productsOrder.product.name": {"$cont": config.searchText}},
      ]}
    );

    var sort = "&sort=lossesDate,DESC";
    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));
    
    return this.http.get<any>(environment.apiUrl + this.PATH_LOSSES + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search + sort, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_LOSSES, data),
          error => throwError(error)
        )
      );
  }

  addLost(obj) {
    console.log(obj);
    return this.http.post<any>(environment.apiUrl + this.PATH_LOSSES, obj, httpOptions)
      .pipe(tap( // Log the result or error
        data => console.log(this.PATH_LOSSES, data),
        error => throwError(error)
      )
    );
  }

  getLost(id) {
      return this.http.get<any>(environment.apiUrl + this.PATH_LOSSES + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_LOSSES, data),
          error => throwError(error)
        )
      );
  }

  updateLost(id, obj) {
    return this.http.patch<any>(environment.apiUrl + this.PATH_LOSSES + id, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_LOSSES, data),
          error => throwError(error)
        )
      );
  }

  deleteLost(id) {
    return this.http.delete<any>(environment.apiUrl + this.PATH_LOSSES + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_LOSSES, data),
          error => throwError(error)
        )
      );
  }


  /* Inventory */
  searchLossesInventory(id, config) {
    var search = JSON.stringify(
      {"$or": [
        {"id": {"$cont": config.searchText}},
        {"name": {"$cont": config.searchText}},
        {"trip": {"$cont": config.searchText}}
      ]}
    );

    var sort = "?sort="+config.order.field+",DESC";

    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));
    
    return this.http.get<any>(environment.apiUrl + this.PATH_INVENTORY + id + '/losses/?s=' + search + sort, httpOptions)
    // return this.http.get<any>(environment.apiUrl + this.PATH_INVENTORY + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_INVENTORY, data),
          error => throwError(error)
        )
      );
  }

  updateInventory(id, obj) {
    return this.http.patch<any>(environment.apiUrl + this.PATH_LOSSES + id, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_LOSSES, data),
          error => throwError(error)
        )
      );
  }


  /* Observations */
  getObservations() {
      return this.http.get<any>(environment.apiUrl + this.PATH_OBSERVATION, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  searchObservations(lossesId, config) {
    var search = JSON.stringify(
      {"$and": [
        {"$or": [
          {"id": {"$cont": config.searchText}},
          {"observation": {"$cont": config.searchText}},
        ]},
        {"losses.id": {"$eq": lossesId}}
      ]}
    );

    console.log("searchText-->", config.searchText);
    console.log("search-->", search);
    console.log("config-->", JSON.stringify(config));
    
    return this.http.get<any>(environment.apiUrl + this.PATH_OBSERVATION + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  getObservation(id) {
      return this.http.get<any>(environment.apiUrl + this.PATH_OBSERVATION + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  addObservation(obj) {
      return this.http.post<any>(environment.apiUrl + this.PATH_OBSERVATION, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  updateObservation(id, obj) {
    return this.http.patch<any>(environment.apiUrl + this.PATH_OBSERVATION + id, obj, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

  deleteObservation(id) {
    return this.http.delete<any>(environment.apiUrl + this.PATH_OBSERVATION + id, httpOptions)
        .pipe(tap( // Log the result or error
          data => console.log(this.PATH_OBSERVATION, data),
          error => throwError(error)
        )
      );
  }

}