import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { AuthService } from './../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

	constructor(private router: Router, public authService: AuthService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token: string = localStorage.getItem('@ACCESS_TOKEN');
		let request = req;

		if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`
        }
      });
    };

		return next.handle(request).pipe(
			catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.authService.logout();
					//this.router.navigateByUrl('/login');
        }
        return throwError( err );
      })
    );
	}

}