import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MouseEvent } from '@agm/core';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../environments/environment';
import { FormsService } from './../../services/forms/forms.service';
import { AdminService } from './../../services/admin/admin.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  @ViewChild("table", { static: true }) table: any;
  @ViewChild('t') tabs:NgbTabset;

  public formClient:FormGroup;
  public formPorcent:FormGroup;
  public secondTabEnabled: boolean = false;

  public environment = environment;
  public itemSelected: any = {
    percent: []
  };
  public numbers: any;

  //table, pagination
  public config = {
    id: 'employees',
    searchText: '',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100]
  };
  public rows: any = [];

  //picture
  public files: File[] = [];
  public tempPhotoUrl: any;
  public responseMessage:String;

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private adminService: AdminService, private toastService: ToastrService) { }

  ngOnInit(): void {
    this.numbers = Array(100).fill(0).map((x,i)=>i+1); // fills array with 5 incremental entries starting from 1 (hence the "i+1"). To start at zero remove the "+1"

    this.formClient = this.formBuilder.group({
      rfc: ['', [Validators.required]],
      tradename: ['', [Validators.required]],
      businessName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      city: ['', [Validators.required]],
      address: ['', [Validators.required]]
    });
    this.formPorcent = this.formBuilder.group({
      concept: ['', [Validators.required]],
      percentage: ['', [Validators.required]]
    });
    this.populateClients();
  }

  public populateClients(){
    this.spinner.show();
    this.adminService.searchClients(this.config).subscribe(
      res => {
        this.rows = res.data;
        this.config.totalItems = res.total;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public submitClient(id){
    if(id === undefined){
      this.addClient();
    }else{
      this.updateClient(id);
    }
  }

  public addClient() {
    console.log("addClient-->");
    this.config.searchText = "";
    this.config.currentPage = 1;

    if (!this.formClient.valid) {
       this.formsService.validateAllFormFields(this.formClient);
    }else {
      this.spinner.show();
      this.formClient.controls["rfc"].patchValue(this.formClient.controls["rfc"].value.replace(/[^a-zA-Z0-9]/g, ''));
      this.formClient.controls["phone"].patchValue(this.formClient.controls["phone"].value.replace(/[^a-zA-Z0-9]/g, ''));
      this.adminService.addClient(this.formClient.value).subscribe(
        (res) => {
          this.secondTabEnabled = true;
          this.tabs.select('tab-selectbyid2');
          this.itemSelected = res;
          //this.modalService.getModal('clientModal').close();
          this.openSuccessModal("¡Cliente agregado!", "Su registro ha sido guardado exitosamente.");
          this.populateClients();
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public updateClient(id){
    console.log("addClient-->");
    console.log("updateClient-->", id);
    if (!this.formClient.valid) {
       this.formsService.validateAllFormFields(this.formClient);
    }else {
      this.spinner.show();
      this.formClient.controls["rfc"].patchValue(this.formClient.controls["rfc"].value.replace(/[^a-zA-Z0-9]/g, ''));
      this.formClient.controls["phone"].patchValue(this.formClient.controls["phone"].value.replace(/[^a-zA-Z0-9]/g, ''));
      this.adminService.updateClient(id, this.formClient.value).subscribe(
        (res) => {
          this.tabs.select('tab-selectbyid2');
          this.itemSelected = res;
          //this.modalService.getModal('clientModal').close();
          //this.openSuccessModal("¡Proveedor actualizado!", "Su registro ha sido modificado exitosamente.");
          this.populateClients();
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public deleteClient(id){
    console.log("deleteClient-->", id);
    this.spinner.show();
    this.adminService.deleteClient(id).subscribe(
      (res) => {
        this.modalService.getModal('deleteModal').close();
        this.openSuccessModal("¡Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.populateClients();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.responseMessage = err.error.message;
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  public submitPorcent(){
    console.log("submitPorcent-->");
    if (!this.formPorcent.valid) {
       this.formsService.validateAllFormFields(this.formPorcent);
    }else {
      this.spinner.show();
      this.adminService.addPorcent(this.itemSelected.id, this.formPorcent.value).subscribe(
        (res) => {
          console.log("res-->", res);
          console.log("value-->", this.formPorcent.value);
          console.log("res-->", res);
          console.log(" this.itemSelected.percent-->",  this.itemSelected.percent);
          if(!this.itemSelected.percent){
            this.itemSelected.percent = [];
          }
          this.itemSelected.percent.push(res);  
          this.formPorcent.reset();
          this.populateClients();
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public deletePorcent(index, id){
    console.log("deletePorcent-->", id);
    this.spinner.show();
    this.adminService.deletePorcent(id).subscribe(
      (res) => {
        this.modalService.getModal('deleteModal').close();
        this.openSuccessModal("¡Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.itemSelected.percent.splice(index, 1);
        this.populateClients();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.responseMessage = err.error.message;
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  // actions
  edit(item) {
    this.itemSelected = item;
    this.openClientModal(item);
  }

  enable(item){
    this.activeClient(item, 1);
  }

  disable(item){
    this.activeClient(item, 0);
  }

  activeClient(item, status){
    this.itemSelected = item;
    this.adminService.enableClient(item.id, { status: status })
      .subscribe(
        res => {
          if(res.status){
            this.openSuccessModal("¡Activado exitosamente!", "");
          }else{
            this.openSuccessModal("Desactivado exitosamente!", "");  
          }
          this.populateClients();
        },
        err => {
          console.log("error ->", err);
        }
      );
  }

  delete(item) {
    this.itemSelected = item;
    this.modalService.open("deleteModal");
  }

  changePermissions(item){

  }

  //modals
  public openClientModal(item = null){
    this.tabs.select('tab-selectbyid1')
    this.formClient.reset();
    this.formPorcent.reset();
    this.itemSelected = {user: {rol: {}}};
    if(item){ //editing
      this.itemSelected = item;
      this.formClient.patchValue(item);
      this.secondTabEnabled = true;
    }else{
      this.secondTabEnabled = false;
    }
    this.modalService.getModal('clientModal').open();
    this.files = new Array<File>();
  }

  public openPictureModal(){
    this.modalService.getModal('pictureModal').open();
    this.files = new Array<File>();
  }
  
  public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal');
    this.modalService.setModalData(obj, 'successModal');
    this.modalService.getModal('successModal').open();
  }

  //drops
  public onSelect(event) {
    this.files.push(...event.addedFiles);
    var file = this.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.tempPhotoUrl = event.target.result;
      }
    }
  }

  public onSearch(){
    this.config.currentPage = 1;
    this.populateClients();
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
    this.populateClients();
  }

  public onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  public tabClicked(){
    this.formClient.patchValue(this.itemSelected);
  }

}

interface Porcent {
  id: number,
  concept: string,
  percentage: number,
  client: number
}
