import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MouseEvent } from '@agm/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../environments/environment';
import { FormsService } from './../../services/forms/forms.service';
import { SalesService } from './../../services/sales/sales.service';
import { Sale } from './Sale';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {  
  public itemSelected: any = {};

  //table, pagination
  public config = {
    id: 'sales',
    searchText: '',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100]
  };
  public rows: any = [];
  public responseMessage:String;

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, private router: Router,
    public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, 
    private filterPipe: FilterPipe, private salesService: SalesService, private toastService: ToastrService) {
  }

  ngOnInit(): void {
    this.populateSales();
  }

  public populateSales(){
    this.spinner.show();
    this.salesService.searchSales(this.config).subscribe(
      res => {
        this.rows = res.data;
        this.config.totalItems = res.total;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public deleteSale(id){
    console.log("deleteSale-->", id);
    this.spinner.show();
    this.salesService.deleteSale(id).subscribe(
      (res) => {
        this.modalService.getModal('deleteModal').close();
        this.openSuccessModal("¡Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.populateSales();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.responseMessage = err.error.message;
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  active(item, enabled){
    this.itemSelected = item;
    this.openSuccessModal("¡Activado exitosamente!", "");
    /*
    this.jobRequestService
      .updatePersonalinformation({ enabled: true }, item.id)
      .subscribe(
        res => {
          this.toast.success("Activado", "Usuario");
          this.ngOnInit();
        },
        err => {
          console.log("error ->", err);
        }
      );
      */
  }

  delete(item) {
    this.itemSelected = item;
    this.modalService.open("deleteModal");
  }

  public createSale(){
    this.spinner.show();
    let emptySale = new Sale();
   
    this.salesService.addSale(emptySale).subscribe(
      (res) => {
        this.router.navigate(['sales', res.id]);
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
        this.toastService.warning(err.error.message);
      }
    );
  }

  public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal');
    this.modalService.setModalData(obj, 'successModal');
    this.modalService.getModal('successModal').open();
  }

  public onSearch(){
    this.config.currentPage = 1;
    this.populateSales();
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
    this.populateSales();
  }

}