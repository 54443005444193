<section class="container justify-content-center text-center p-0">
  <article class="row justify-content-center">
    <div class="col-12">
      <h4 class="catalog-title font-weight-bold text-dark mb-3">
        <img class="header-image" src="../assets/icons/ic_employees.png"/>
      EMPLEADOS</h4>
      <div class="container">
        <div class="row mb-5" style="align-items: baseline;">
          <div class="col-lg-6 col-8">
            <div class="search-control">
              <i class="fa fa-search"></i>
                <input type="search" #searchInput [(ngModel)]="config.searchText" (search)="onSearch()" class="form-control input-search text-dark" placeholder="Buscar por Nombres, Apellidos, Rol"/>
            </div>
          </div>
          <div class="col-lg-6 col-4 text-right">
            <div class="catalog-btn">
              <img src="../assets/icons/ic_plus.png" (click)="openEmployeeModal();" />
              <label (click)="openEmployeeModal();">Nuevo Empleado</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">NOMBRES</th>
                    <th scope="col">APELLIDO PATERNO</th>
                    <th scope="col">APELLIDO MATERNO</th>
                    <th scope="col">TELÉFONO</th>
                    <th scope="col">CORREO</th>
                    <th scope="col">ROL/PERFIL</th>
                    <th scope="col">ACCIONES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rows | paginate: config">
                    <td>{{item.id}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.firstSurname}}</td>
                    <td>{{item.secondSurname}}</td>
                    <td>{{item.phone | mask: '(000)000-00-00'}}</td>
                    <td>{{item.user.email}}</td>
                    <td>{{item.user.rol.nombre}}</td>
                    <td class="iconContainer" style="display: flex;">
                      <img class="catalog-icon" src="../assets/icons/ic_edit.png" (click)="openEmployeeModal(item);" placement="top" ngbTooltip="editar"/>
                      <img *ngIf="item.status == 1" class="catalog-icon" src="../assets/icons/ic_enabled.png" (click)="disable(item);" placement="top" ngbTooltip="deshabilitar"/>
                      <img *ngIf="item.status == 0" class="catalog-icon" src="../assets/icons/ic_disabled.png" (click)="enable(item);" placement="top" ngbTooltip="habilitar"/>
                      <img *ngIf="false" class="catalog-icon" src="../assets/icons/ic_permissions.png" (click)="changePermissions(item);" placement="top" ngbTooltip="permisos"/>
                      <img *ngIf="!item.enabled" class="catalog-icon" src="../assets/icons/ic_delete.png" (click)="delete(item);" placement="top" ngbTooltip="eliminar"/>
                    </td>
                  </tr>
                  <tr *ngIf="(rows).length === 0">
                    <td colspan="6">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagerChanged($event)">

              <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                  <a (click)="p.previous()" *ngIf="!p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

              <div *ngFor="let page of p.pages" class="page-number" [class.current]="p.getCurrent() === page.value">
                  <a (click)="p.setCurrent(page.value);" *ngIf="p.getCurrent() !== page.value">
                      <span>{{ page.label }}</span>
                  </a>
                  <div *ngIf="p.getCurrent() === page.value">
                      <span>{{ page.label }}</span>
                  </div>
              </div>

              <div class="pagination-next" [class.disabled]="p.isLastPage()">
                  <a (click)="p.next()" *ngIf="!p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

            </pagination-template>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 pager">
            <label>Mostrar <select [(ngModel)]="config.itemsPerPage" (change)='onPagerChanged(1)'>
              <option *ngFor="let c of config.options" [ngValue]="c">{{c}}</option>
            </select> Registros por página</label>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>

<ngx-smart-modal customClass="xl-dialog closable" #employeeModal identifier="employeeModal" [closable]="true" [dismissable] = "false">
  <button class="btn-back-modal" (click)="employeeModal.close()">
    <i class="fas fa-long-arrow-alt-left"></i>
  </button>
  <div class="container justify-content-center text-left">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <form [formGroup]="formEmployee">
          <div class="row justify-content-center mb--4">
            <div class="col-6 text-center">
              <h1 class="h6 font-weight-bold text-dark mb-0 ml-3">
                <p *ngIf="!itemSelected.id">AGREGAR EMPLEADO</p>
                <p *ngIf="itemSelected.id">EDITAR EMPLEADO</p>
              </h1>
            </div>
          </div>
          <div class="{{itemSelected.rfc? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
            <label for="rfc" class="dark"><b class="red">*</b>RFC/CURP</label>
            <input type="text" class="form-control no-border" name="rfc" id="rfc" formControlName="rfc" value="{{itemSelected.rfc | mask: 'AAAA-AAAAAA-AAA'}}" [dropSpecialCharacters]="false" mask="AAAA-AAAAAA-AAA">
            <div class="border-control"></div>
            <div *ngIf="formEmployee.controls['rfc'].invalid && (formEmployee.controls['rfc'].dirty || formEmployee.controls['rfc'].touched)" class="inputAlert">
              <div *ngIf="formEmployee.controls['rfc'].errors && formEmployee.controls['rfc'].errors.required">
                  RFC/CURP obligatorio
              </div>
            </div>
          </div>
          <div class="{{itemSelected.name? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
            <label for="name" class="dark"><b class="red">*</b>Nombres</label>
            <input type="text" class="form-control no-border" name="name" id="name" formControlName="name" value="{{itemSelected.name}}">
            <div class="border-control"></div>
            <div *ngIf="formEmployee.controls['name'].invalid && (formEmployee.controls['name'].dirty || formEmployee.controls['name'].touched)" class="inputAlert">
              <div *ngIf="formEmployee.controls['name'].errors && formEmployee.controls['name'].errors.required">
                  Nombre obligatorio
              </div>
            </div>
          </div>
          <div class="{{itemSelected.firstSurname? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
              <label for="firstSurname" class="dark"><b class="red">*</b>Apellido paterno</label>
              <input type="text" class="form-control no-border" name="firstSurname" id="firstSurname" formControlName="firstSurname" value="{{itemSelected.firstSurname}}">
              <div class="border-control"></div>
              <div *ngIf="formEmployee.controls['firstSurname'].invalid && (formEmployee.controls['firstSurname'].dirty || formEmployee.controls['firstSurname'].touched)" class="inputAlert">
                <div *ngIf="formEmployee.controls['firstSurname'].errors && formEmployee.controls['firstSurname'].errors.required">
                    Apellido paterno obligatorio
                </div>
              </div>
            </div>
            <div class="{{itemSelected.secondSurname? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
              <label for="secondSurname" class="dark"><b class="red">*</b>Apellido materno</label>
              <input type="text" class="form-control no-border" name="secondSurname" id="secondSurname" formControlName="secondSurname" value="{{itemSelected.secondSurname}}">
              <div class="border-control"></div>
              <div *ngIf="formEmployee.controls['secondSurname'].invalid && (formEmployee.controls['secondSurname'].dirty || formEmployee.controls['secondSurname'].touched)" class="inputAlert">
                <div *ngIf="formEmployee.controls['secondSurname'].errors && formEmployee.controls['secondSurname'].errors.required">
                    Apellido materno obligatorio
                </div>
              </div>
            </div>
            <div class="{{itemSelected.phone? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
              <label for="phone" class="dark"><b class="red">*</b>Teléfonos</label>
              <input type="text" class="form-control no-border" name="phone" id="phone" formControlName="phone" value="{{itemSelected.phone | mask: '(000)000-00-00'}}" [dropSpecialCharacters]="false" mask="(000)000-00-00">
              <div class="border-control"></div>
              <div *ngIf="formEmployee.controls['phone'].invalid && (formEmployee.controls['phone'].dirty || formEmployee.controls['phone'].touched)" class="inputAlert">
                <div *ngIf="formEmployee.controls['phone'].errors && formEmployee.controls['phone'].errors.required">
                    Teléfonos obligatorios
                </div>
              </div>
            </div>
            <div class="{{itemSelected.user.email? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
              <label for="email" class="dark"><b class="red">*</b>Correo electrónico (al correo que registre se le enviará su contraseña)</label>
              <input type="text" class="form-control no-border" name="email" id="email" formControlName="email" value="{{itemSelected.user.email}}">
              <div class="border-control"></div>
              <div *ngIf="formEmployee.controls['email'].invalid && (formEmployee.controls['email'].dirty || formEmployee.controls['email'].touched)" class="inputAlert">
                <div *ngIf="formEmployee.controls['email'].errors && formEmployee.controls['email'].errors.required">
                    Correo electrónico obligatorio
                </div>
              </div>
            </div>
            <div class="{{itemSelected.user.rol? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
              <label for="rol" class="dark"><b class="red">*</b>Seleccione el perfil del empleado</label>
              <select class="form-control no-border" name="rol" id="rol" formControlName="rol" value="{{itemSelected.user.rol.id}}">
                <option disabled="disabled">Seleccione el perfil del empleado</option>
                <option value="3" [disabled]="profile.rol.id != 3">Gerente</option>
                <option value="1">Administrador</option>
                <option value="2">Empleado</option>
              </select>
              <div class="border-control"></div>
              <div *ngIf="formEmployee.controls['rol'].invalid && (formEmployee.controls['rol'].dirty || formEmployee.controls['rol'].touched)" class="inputAlert">
                <div *ngIf="formEmployee.controls['rol'].errors && formEmployee.controls['rol'].errors.required">
                    Perfil del empleado obligatorio
                </div>
              </div>
            </div>
            <div class="row justify-content-center" style="margin-top: 2rem !important;">
              <div class="col-12 m-2 list-group-horizontal align-items-center">
                <img *ngIf="itemSelected.photo && !tempPhotoUrl" [src]="environment.imagesUrl + itemSelected.photo" class="header-image">
                <img *ngIf="tempPhotoUrl" [src]="tempPhotoUrl" class="header-image">
                <div>
                  <label (click)="openPictureModal();" for="files" class="btn" style="margin-top: -0.8rem; margin-left: 1rem;">Seleccionar fotografía...</label>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-5">
                <button type="submit" (click)="submitEmployee(itemSelected.id)" class="btn btn-primary btn-user btn-block shadow-sm">
                  <span *ngIf="!itemSelected.id">AGREGAR EMPLEADO</span>
                  <span *ngIf="itemSelected.id">ACTUALIZAR</span>
                  <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                </button>
              </div>
              <div class="col-lg-3">
                <button type="cancel" (click)="employeeModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
              </div>
            </div>
        </form>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog closable" #pictureModal identifier="pictureModal" [closable]="true">
  <div class="container justify-content-center text-center mb-1">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div id="dropzone" class="custom-dropzone" ngx-dropzone [multiple]="false" (change)="onSelect($event)" accept="image/*">
          <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
          <img *ngIf="files.length == 0" src="../assets/icons/ic_plus.png" style="width: 60px; margin: 10px;" alt="" />
          <p *ngIf="files.length == 0" style="font-size: 0.8rem;">Selecciona y/o arrastra la fotografía del empleado</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p style="height: 3rem; padding: 0.5rem;"></p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <button type="submit" (click)="pictureModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">GUARDAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
      <div class="col-4">
        <button type="cancel" (click)="pictureModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
      </div>
    </div>

  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div *ngIf="successModal.hasData()" class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">{{ successModal.getData().title }}</h1>
        <p class="h6" style="font-size: 0.9rem;">{{ successModal.getData().subtitle }} </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #deleteModal identifier="deleteModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_warning.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¿Desea eliminar este registro?</h1>
        <p class="h6" style="font-size: 0.9rem;">Recuerda que será eliminado de la base de datos</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <button type="submit" (click)="deleteEmployee(itemSelected.id)" class="btn btn-primary btn-user btn-block shadow-sm">SÍ, ELIMINAR<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
      <div class="col-4">
        <button type="cancel" (click)="deleteModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
      </div>
    </div>
  </div>
</ngx-smart-modal>