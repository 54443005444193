import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

	private PATH_INVENTORY:String = "/inventory/";

	constructor(private http: HttpClient, private router: Router) { }

	searchInventory(config) {
		var search = JSON.stringify(
			{"$or": [
				{"id": {"$cont": config.searchText}},
				{"name": {"$cont": config.searchText}},
				{"trip": {"$cont": config.searchText}},
				{"tag": {"$cont": config.searchText}},
				{"presentation": {"$cont": config.searchText}},
				{"units": {"$cont": config.searchText}},
			]}
		);

		console.log("searchText-->", config.searchText);
		console.log("search-->", search);
		console.log("config-->", JSON.stringify(config));
		
		return this.http.get<any>(environment.apiUrl + this.PATH_INVENTORY + 'products/?s=' + search, httpOptions)
		// return this.http.get<any>(environment.apiUrl + this.PATH_INVENTORY + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_INVENTORY, data),
	        error => throwError(error)
	      )
	    );
	}

	searchProductInventory(id, config) {
		var search = JSON.stringify(
			{"$or": [
				{"id": {"$cont": config.searchText}},
				{"trip": {"$cont": config.searchText}},
			]}
		);

		console.log("searchText-->", config.searchText);
		console.log("search-->", search);
		console.log("config-->", JSON.stringify(config));
		
		return this.http.get<any>(environment.apiUrl + this.PATH_INVENTORY + id + '/product/?s=' + search, httpOptions)
		// return this.http.get<any>(environment.apiUrl + this.PATH_INVENTORY + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_INVENTORY, data),
	        error => throwError(error)
	      )
	    );
	}

	searchSalesInventory(id, config) {
		var search = JSON.stringify(
			{"$or": [
				{"id": {"$cont": config.searchText}},
				{"name": {"$cont": config.searchText}},
				{"trip": {"$cont": config.searchText}}
			]}
		);

		var sort = "?sort="+config.order.field+",DESC";

		console.log("searchText-->", config.searchText);
		console.log("search-->", search);
		console.log("config-->", JSON.stringify(config));
		
		//return this.http.get<any>(environment.apiUrl + this.PATH_INVENTORY + id + '/order/?s=' + search + sort, httpOptions)
		return this.http.get<any>(environment.apiUrl + this.PATH_INVENTORY + id + '/order/' + '?page=' + config.currentPage + '&limit=' + config.itemsPerPage + '&s=' + search + sort, httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_INVENTORY, data),
	        error => throwError(error)
	      )
	    );
	}

	getProductInventory(id) {
    	return this.http.get<any>(environment.apiUrl + this.PATH_INVENTORY + id + '/product/', httpOptions)
	      .pipe(tap( // Log the result or error
	        data => console.log(this.PATH_INVENTORY + '/product/' + id, data),
	        error => throwError(error)
	      )
	    );
	}
}