<div class="container">
  <!-- Outer Row -->
  <div class="row justify-content-center mtr-2">
    <div class="col-12 bordered" id="print-section">
      <table class="table main-table">
        <thead>
          <tr>
            <th colspan="3" scope="col" style="vertical-align: top;">
              <div class="company-container">
                <img id="logo" [src]="environment.imagesUrl + company.logo" style="height: 120px;" />
                <div class="datos">
                  <span class="title">{{company.name}}</span>
                  <span>{{company.representative}}</span>
                  <span>R.F.C. {{company.rfc}}</span>
                  <span>{{company.state}}, {{company.city}} C.P. {{company.postalCode}}</span>
                  <span>{{company.address}}</span>
                  <span>TELS. {{company.phone}}</span>
                </div>
              </div>
            </th>
            <th colspan="1" scope="col"></th>
            <th colspan="2" scope="col" style="vertical-align: top;">
              <div class="gray-area m-1 pl-3 pr-3 pt-1 pb-1" style="border-radius: 30px;">
                <h1 class="primary" style="font-size: 18px; font-family: poppins-semibold;">
                  Nota de merma
                </h1>
                <div class="text-center" style="display: table;">
                  <h1 style="font-size: 10px; margin: 0px;">Número de Merma</h1>
                  <h1 class="primary" style="font-size: 42px;font-family: poppins-semibold;">{{lostSelected.id}}</h1>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th colspan="8">
              <div *ngIf="lostSelected.transportLines" class="row text-left gray-area m-1 p-1">
                <div class="col-12">
                  <div class="container mb-0">
                    <div class="row text-left gray-area m-1 p-1" style="align-items: center;">
                      <div class="col-3">
                        <h1><b class="primary">{{lostSelected.client.businessName}}</b></h1>
                        <p class="m-0">Cliente</p>
                      </div>
                      <div class="col-3">
                        <p class="m-0"><span class="primary">Tel:</span> {{lostSelected.client.phone}}</p>
                        <p><span class="primary">E-mail:</span> {{lostSelected.client.email}}</p>
                      </div>
                      <div class="col-3">
                        <p><span class="primary">Dirección:</span> {{lostSelected.client.address}}. {{lostSelected.client.city}}</p>
                      </div>
                      <div class="col-3">
                        <p class="m-0">Fecha de Expedición</p>
                        <h1><b class="primary">{{lostSelected.lossesDate}}</b></h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <td colspan="7" scope="col">

              <table class="table secondary-table">
                <thead class="gray-area" style="border: 1px solid black;">
                  <tr>
                    <th scope="col" style="color: black !important; width: 10% !important;">VIAJE</th>
                    <th scope="col" style="color: black !important;">CANTIDAD</th>
                    <th scope="col" style="color: black !important;">UNIDAD</th>
                    <th scope="col" style="color: black !important;">PRODUCTO</th>
                    <th scope="col" style="color: black !important;">CALIDAD</th>
                    <th scope="col" style="color:black !important;">PRESENTACIÓN</th>
                    <th scope="col" style="color: black !important;">ETIQUETA</th>
                    <th scope="col" style="color: black !important;">TAMAÑO</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="lostSelected">
                    <tr *ngFor="let item of [lostSelected.productsOrder]">
                      <td>{{item.purchaseProducts? item.purchaseProducts.trip: ''}}</td>
                      <td>{{lostSelected.quantity}}</td>
                      <td>{{item.units? item.units.name: ''}}</td>
                      <td>{{item.product? item.product.name: ''}}</td>
                      <td>{{item.quality? item.quality.name: ''}}</td>
                      <td>{{item.presentation? item.presentation.shortname: ''}}</td>
                      <td>{{item.tag? item.tag.name: ''}}</td>
                      <td>{{item.size? item.size.name: ''}}</td>
                    </tr>
                    <tr *ngIf="([lostSelected.productsOrder]).length === 0">
                      <td colspan="7">No se encontraron elementos</td>
                    </tr>
                    <tr>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                      <td colspan="1" style="height: 30px;"></td>
                    </tr>
                    <tr>
                      <td colspan="1" style="color: black !important;">TOTAL DE PRODUCTOS:</td>
                      <td colspan="1"><b>{{lostSelected.quantity}}</b></td>
                      <td colspan="6" style="border-bottom: none !important;border-right: none !important;">
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </td>
          </tr>

          <tr style="text-align: left;">
            <td colspan="7">
              <b class="primary">OBSERVACIONES</b>
            </td>
          </tr>
          <tr>
            <td colspan="4" scope="col">
              <table class="table secondary-table">
                <thead class="gray-area" style="border: 1px solid black;">
                  <tr>
                    <th colspan="1" scope="col" style="color: black !important; text-align: left;">DESCRIPCIÓN DE LA OBSERVACIÓN</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of lostSelected.observation">
                    <td colspan="1" style="text-align: left;">{{item.observation}}</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="height: 30px;"></td>
                  </tr>
                  <tr *ngIf="(lostSelected.observation).length === 0">
                    <td colspan="2">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td colspan="3" scope="col">
              <table class="table secondary-table">
                <tbody>
                  <tr>
                    <td colspan="1" style="border: none !important; color: black;width: 49%; font-size: 12px; text-align: right;">
                      <b class="primary" style="font-size: 18px;">TOTAL DE PRODUCTOS:</b>
                    </td>
                    <td colspan="1">{{lostSelected.quantity}}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </thead>
        <tbody id="footer" class="footer" style="border: none !important;">
          <tr>
            <td colspan="1" scope="col" style="text-align: center;">PÁG. 1 DE 1</td>
            <td colspan="1" scope="col"></td>
            <td colspan="1" scope="col">Merma registrada y realizada por:</td>
            <td colspan="2" scope="col">{{lostSelected.employee && lostSelected.employee.user? lostSelected.employee.name + ' ' + lostSelected.employee.firstSurname + ' ' + lostSelected.employee.user.email: ''}}</td>
            <td colspan="1" scope="col" style="border-right: 1px solid;">Fecha y hora: {{lostSelected.lossesDate}}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-3">
      <button type="submit" (click)="saveLost()" class="btn btn-primary btn-user btn-block shadow-sm" printSectionId="print-section" [useExistingCss]="true" ngxPrint>GUARDAR E IMPRIMIR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
      </button>
    </div>
    <div class="col-2">
      <button type="submit" (click)="saveLost()" class="btn btn-primary btn-user btn-block shadow-sm">GUARDAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
      </button>
    </div>
    <div class="col-2">
       <button type="cancel" [routerLink]="['/losses']" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
    </div>
  </div>
</div>

<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¡Merma registrada con éxito!</h1>
        <p class="h6" style="font-size: 0.9rem;">La merma se ha registrado con el Nº Remisión <b>{{lostSelected.id}}</b></p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" [routerLink]="['/losses']" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>