import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MouseEvent } from '@agm/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../environments/environment';
import { FormsService } from './../../services/forms/forms.service';
import { AdminService } from './../../services/admin/admin.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  @ViewChild("table", { static: true }) table: any;
  public formProduct:FormGroup;
  public environment = environment;
  public itemSelected: any = {
    name: '',
    description: '',
  };
  
  //table, pagination
  public config = {
    id: 'products',
    searchText: '',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100]
  };
  public rows: any = [];

  //picture
  public files: File[] = [];
  public tempPhotoUrl: any;
  public responseMessage:String;

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, 
    public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, 
    private filterPipe: FilterPipe, private adminService: AdminService, private toastService: ToastrService) {
  }

  ngOnInit(): void {
    this.formProduct = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
    });
    this.populateProducts();
  }

  public populateProducts(){
    this.spinner.show();
    this.adminService.searchProducts(this.config).subscribe(
      res => {
        this.rows = res.data;
        this.config.totalItems = res.total;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public submitProduct(id){
    if(id === undefined){
      this.addProduct();
    }else{
      this.updateProduct(id);
    }
  }

  public addProduct() {
    console.log("addProduct-->");
    this.config.searchText = "";
    this.config.currentPage = 1;

    if (!this.formProduct.valid) {
       this.formsService.validateAllFormFields(this.formProduct);
    }else {
      this.spinner.show();
      this.formProduct.value['description'] = "";
      this.adminService.addProduct(this.formProduct.value).subscribe(
        (res) => {
          this.modalService.getModal('productModal').close();
          this.populateProducts();
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public updateProduct(id){
    if (!this.formProduct.valid) {
       this.formsService.validateAllFormFields(this.formProduct);
    }else {
      this.spinner.show();
      this.adminService.updateProduct(id, this.formProduct.value).subscribe(
        (res) => {
          this.modalService.getModal('productModal').close();
          this.openSuccessModal("¡Producto actualizado!", "Su registro ha sido modificado exitosamente.");
          this.populateProducts();
          this.spinner.hide();
        },
        (err) => {
          console.log("err", err);
          this.responseMessage = err.error.message;
          this.toastService.warning(err.error.message, "ADVERTENCIA");
          this.spinner.hide();
        }
      );
    }
  }

  public deleteProduct(id){
    console.log("deleteProduct-->", id);
    this.spinner.show();
    this.adminService.deleteProduct(id).subscribe(
      (res) => {
        this.modalService.getModal('deleteModal').close();
        this.openSuccessModal("¡Registro eliminado!", "Su registro ha sido eliminado exitosamente.");
        this.populateProducts();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.responseMessage = err.error.message;
        this.toastService.warning(err.error.message, "ADVERTENCIA");
        this.spinner.hide();
      }
    );
  }

  // actions
  edit(item) {
    this.itemSelected = item;
    this.openProductModal(item);
  }

  enable(item){
    this.activeProduct(item, 1);
  }

  disable(item){
    this.activeProduct(item, 0);
  }

  activeProduct(item, status){
    this.itemSelected = item;
    this.adminService.enableProduct(item.id, { status: status })
      .subscribe(
        res => {
          if(res.status){
            this.openSuccessModal("¡Activado exitosamente!", "");
          }else{
            this.openSuccessModal("Desactivado exitosamente!", "");  
          }
          this.populateProducts();
        },
        err => {
          console.log("error ->", err);
        }
      );
  }

  delete(item) {
    this.itemSelected = item;
    this.modalService.open("deleteModal");
  }

  showUnits(item){
    this.itemSelected = item;
    this.modalService.open("unitsModal");
  }

  //modals
  public openProductModal(item = null){
    this.formProduct.reset();
    this.itemSelected = {
      name: '',
      description: ''
    };
    if(item){ //editing
      this.itemSelected = item;
      this.formProduct.controls["name"].patchValue(item.name);
      this.formProduct.controls["description"].patchValue(item.description);
    }
    this.modalService.getModal('productModal').open();
    this.files = new Array<File>();
  }

  public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal2');
    this.modalService.setModalData(obj, 'successModal2');
    this.modalService.getModal('successModal2').open();
  }

  public onSearch(){
    this.config.currentPage = 1;
    this.populateProducts();
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
    this.populateProducts();
  }

  //drops
  public onSelect(event) {
    this.files.push(...event.addedFiles);
    var file = this.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.tempPhotoUrl = event.target.result;
      }
    }
  }

  public onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

}