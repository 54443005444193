import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgxSmartModalService, NgxSmartModalComponent, } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../../environments/environment';
import { FormsService } from './../../../services/forms/forms.service';
import { AuthService } from './../../../services/auth/auth.service';
import { AdminService } from './../../../services/admin/admin.service';
import { DriversComponent } from './../../../screens/drivers/drivers.component';
import { ReportsService } from './../../../services/reports/reports.service';
import { PurchasesService } from './../../../services/purchases/purchases.service';

@Component({
  selector: 'app-reporte-analitico',
  templateUrl: './reporte-analitico.component.html',
  styleUrls: ['./reporte-analitico.component.css']
})
export class ReporteAnaliticoComponent implements OnInit {
	environment = environment;
  public company: any = null;
  public profile: any = null;
  public today = new Date();
  public orderSelected: any = {
    id: 0,
    purchaseProducts: {
      list: [],
      totals: {}
    },
    saleProducts: {
      list: [],
      totals: {}
    },
    lossesProducts: {
      list: [],
      totals: {}
    },
    expenseRecordsPurchaseProducts: [],
    expenseRecordsSaleProducts: [],
    observationsPurchaseProducts: [],
    observationsSaleProducts: [],
    extraCost: [],
    netAmount: 0,
  };


  //table, pagination
  public config = {
    id: 'purchases',
    searchText: '',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    options: [10, 20, 50, 100]
  };
  public rows: any = [];

	constructor(private authService: AuthService, private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private adminService: AdminService, private toastService: ToastrService, private reportsService: ReportsService, private router: Router, private purchasesService: PurchasesService) {
	}

	ngOnInit(): void {
    this.adminService.currentCompany.subscribe(company => {
      this.company = company;
    });
    this.authService.currentProfile.subscribe(profile => {
      if(profile && !(Object.keys(profile).length === 0)){
        console.log("profile: ", profile);
        this.profile = profile;
      }
    });
    this.populatePurchases();
  }

  public populatePurchases(){
    this.spinner.show();
    this.purchasesService.searchPurchases(this.config).subscribe(
      res => {
        this.rows = res.data;
        this.config.totalItems = res.total;
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public getReport(id){
    console.log("getReport-->", id);
    this.spinner.show();
    this.config.searchText = '';
    this.config.currentPage = 1;
    this.reportsService.getReporteAnalitico(id).subscribe(
      (res) => {
        res.id = id;
        console.log("report-res->", res);
        this.orderSelected = res;
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public onSearch(){
    this.config.currentPage = 1;
    this.reset();
    this.populatePurchases();
  }

  public reset(){
    this.orderSelected = {
      id: 0,
      purchaseProducts: {
        list: [],
        totals: {}
      },
      saleProducts: {
        list: [],
        totals: {}
      },
      lossesProducts: {
        list: [],
        totals: {}
      },
      expenseRecordsPurchaseProducts: [],
      expenseRecordsSaleProducts: [],
      observationsPurchaseProducts: [],
      observationsSaleProducts: [],
      extraCost: [],
      netAmount: 0,
    };
  }

  public onPagerChanged($event){
    this.config.currentPage = $event;
    this.populatePurchases();
  }
  
}