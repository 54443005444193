import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { ReportsService } from './../../../../services/reports/reports.service';
import { ExcelsService } from './../../../../services/excels/excels.service';
import { AdminService } from './../../../../services/admin/admin.service';
import locale from '../../../../../assets/json/locale.json';
declare var $: any;

@Component({
  selector: 'app-graph-expenses-orders',
  templateUrl: './graph-expenses-orders.component.html',
  styleUrls: ['./graph-expenses-orders.component.css']
})
export class GraphExpensesOrdersComponent implements OnInit {
  public config = {
    id: 'expenses-orders',
    searchText: '',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    startDate: null,
    endDate: null,
    transportLine: 0,
    options: [10, 20, 50, 100],
    order: {
      field: 'id',
      reverse: false
    }
  };
  public rows: [] = [];
  public transportLines: any[] = [];
  public summary = {
    totalAmount: 0
  }

	constructor(private datePipe: DatePipe, private spinner: NgxSpinnerService, 
    private filterPipe: FilterPipe, private reportsService: ReportsService,
    private adminService: AdminService, private excelsService: ExcelsService ) { 
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.config.startDate = firstDay;
      this.config.endDate = lastDay;
  }

	ngOnInit(): void {
    $("input[name='range-expenses-orders']").daterangepicker({
      showDropdowns: true,
      "locale": locale,
      startDate: this.datePipe.transform(this.config.startDate, 'dd/MM/yyyy'),
      endDate: this.datePipe.transform(this.config.endDate, 'dd/MM/yyyy'),
    }, function(start, end, label) {
      console.log("A new date selection was made: " + this.datePipe.transform(start, 'dd/MM/yyyy') + ' to ' + this.datePipe.transform(end, 'dd/MM/yyyy'));
      this.config.startDate = start;
      this.config.endDate = end;
      this.getReport(this.config.transportLine);
    }.bind(this));
    this.populateTransportLines();
  }

  public populateTransportLines(){
    this.spinner.show();
    this.adminService.getTransportLines().subscribe(
      res => {
        this.transportLines = res;
        setTimeout(()=>{
          $("#transportLines-expenses-orders").selectpicker("refresh");
        }, 100);
        this.getReport(0);
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public getReport(id){
    console.log("getReport-->", id);
    this.spinner.show();
    this.reportsService.getReporteLineaTransporteGastosCompras(id, this.config).subscribe(
      (res) => {
        console.log("report-res->", res);
        this.rows = res.list;
        this.summary.totalAmount = res.totalAmount;
        this.config.totalItems = res.totalItems;
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public getExcel(){
    console.log("getExcel-->", this.config.transportLine);
    this.spinner.show();
    this.excelsService.getReporteLineaTransporteGastosCompras(this.config.transportLine, this.config).subscribe(
      (res) => {
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  

  public onTransportLinesChanged(value){
    console.log("value", value);
    this.config.transportLine = value;
    this.config.currentPage = 1;
    this.getReport(this.config.transportLine);
  }

  public onPagerChanged($event){
    if(!isNaN($event)){
      this.config.currentPage = $event;
      this.getReport(this.config.transportLine);
    }
  }
  
}