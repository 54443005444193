import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from './../../services/auth/auth.service';
import { FormsService } from './../../services/forms/forms.service';
import { AdminService } from './../../services/admin/admin.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public currentState: String = 'Login';

  public formLogin:FormGroup;
  public formPassword:FormGroup;
  public formNewPassword:FormGroup;
  public errorLogin:String;
  public token:string = "";

  constructor(private router: Router, private authService: AuthService, private toastService: ToastrService, 
    private formBuilder: FormBuilder, private formsService: FormsService, private spinner: NgxSpinnerService,
    private adminService: AdminService, private route: ActivatedRoute, public modalService: NgxSmartModalService, ) { 

    this.token = this.getParamValueQueryString("token");

    if(this.token){
      this.currentState = 'CreateNewPassword';
    }
  }

  ngOnInit(): void {
    this.authService.currentProfile.subscribe(profile => {
      if(profile && !(Object.keys(profile).length === 0) ){
        this.router.navigateByUrl('/');
      }
    });
    this.formLogin = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: [null, Validators.required]
    });

    this.formPassword = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.formNewPassword = this.formBuilder.group({
      password: [null, Validators.required],
      password_confirm: [null, Validators.required]
    });
  }

  public login() {
    this.errorLogin = "";
    if (!this.formLogin.valid) {
      this.formsService.validateAllFormFields(this.formLogin);
    }else {
      this.spinner.show();
      this.authService.login(this.formLogin.value).subscribe(
        (res) => {
          this.authService.saveAccessToken(res.access_token);
          this.authService.getProfile().subscribe(
            (res2) => {
              this.authService.onProfileChanged(res2);
              this.adminService.getCompany().subscribe(
                (res3:any) => {
                  this.adminService.onCompanyChanged(res3);
                  this.spinner.hide();
                  this.router.navigateByUrl('/');
                },
                (err) => {
                  console.log("err", err);
                  this.spinner.hide();
                  this.router.navigateByUrl('/');
                }
              );
            },
            (err) => {
              console.log("err", err);
              this.errorLogin = err.error.message;
              this.spinner.hide();
            }
          )
        },
        (err) => {
          console.log("err", err);
          this.errorLogin = err.error.message;
          this.spinner.hide();
        }
      );
    }
  }

  public recoverPassword() {
    this.errorLogin = "";
    if (!this.formPassword.valid) {
      this.formsService.validateAllFormFields(this.formPassword);
    }else {
      this.spinner.show();
      this.authService.recoverPassword(this.formPassword.value).subscribe(
        (res) => {
          this.spinner.hide();
          this.openSuccessModal("¡Solicitud enviada!", "Revisa tu correo para restablecer tu contraseña.");
        },
        (err) => {
          console.log("err", err);
          this.spinner.hide();
        }
      );
    }
  }

  public createPassword() {
    this.errorLogin = "";
    if (!this.formNewPassword.valid) {
      this.formsService.validateAllFormFields(this.formNewPassword);
    }else {
      if(this.formNewPassword.controls['password'].value != 
        this.formNewPassword.controls['password_confirm'].value){
        this.errorLogin = "La contraseña no coincide.";
        return;
      }

      console.log("token", this.token);

      var obj = {
        token: this.token,
        newPassword: this.formNewPassword.controls['password'].value
      };

      this.spinner.show();
      this.authService.changePassword(obj).subscribe(
        (res) => {
          this.spinner.hide();
          this.openSuccessModal("¡Contraseña actualizada!", "Tu contraseña ha sido actualizada de forma exitosa.");
        },
        (err) => {
          console.log("err", err);
          this.spinner.hide();
        }
      );
    }
  }


  public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal');
    this.modalService.setModalData(obj, 'successModal');
    this.modalService.getModal('successModal').open();
  }

  getParamValueQueryString( paramName ) {
    const url = window.location.href;
      let paramValue;
      if (url.includes('?')) {
        const httpParams = new HttpParams({ fromString: url.split('?')[1] });
        paramValue = httpParams.get(paramName);
      }
      return paramValue;
  }

}