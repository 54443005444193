import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AgmCoreModule } from '@agm/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { OrderModule } from 'ngx-order-pipe';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import {NgxPrintModule} from 'ngx-print';

import { ChartsModule } from 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//interceptor
import { environment } from './../environments/environment';
import { AuthInterceptorService } from './services/auth/auth-interceptor.service';
import { MyFilterPipe } from './services/forms/content-filter.pipe';
//screens
import { HomeComponent } from './screens/home/home.component';
import { LoginComponent } from './screens/login/login.component';
import { CompanyComponent } from './screens/company/company.component';
import { EmployeesComponent } from './screens/employees/employees.component';
import { ProvidersComponent } from './screens/providers/providers.component';
import { SeasonsComponent } from './screens/providers/seasons/seasons.component';
import { ClientsComponent } from './screens/clients/clients.component';
import { TransportLinesComponent } from './screens/transportlines/transportlines.component';
import { DriversComponent } from './screens/drivers/drivers.component';
import { UnitsComponent } from './screens/units/units.component';
import { UnitsUnitComponent } from './screens/units/unit/unit.component';
import { UnitsQualityComponent } from './screens/units/quality/quality.component';
import { UnitsTagComponent } from './screens/units/tag/tag.component';
import { UnitsSizeComponent } from './screens/units/size/size.component';
import { UnitsPresentationComponent } from './screens/units/presentation/presentation.component';
import { ProductsComponent } from './screens/products/products.component';

import { PurchasesComponent } from './screens/purchases/purchases.component';
import { OrderComponent } from './screens/order/order.component';
import { ProviderOrderComponent } from './screens/order/provider-order/provider-order.component';
import { ProductsOrderComponent } from './screens/order/products-order/products-order.component';
import { TransportLinesOrderComponent } from './screens/order/transportlines-order/transportlines-order.component';
import { CostsOrderComponent } from './screens/order/costs-order/costs-order.component';
import { ObservationsOrderComponent } from './screens/order/observations-order/observations-order.component';
import { RegisterOrderComponent } from './screens/order/register-order/register-order.component';

import { InventoryComponent } from './screens/inventory/inventory.component';
import { ProductInventoryComponent } from './screens/inventory/product/product.component';

import { InventoryAllComponent } from './screens/inventory/inventory-all/inventory-all.component';
import { ProductInventorySingleComponent } from './screens/inventory/inventory-all/product/product.component';

import { SalesComponent } from './screens/sales/sales.component';
import { SaleComponent } from './screens/sales/sale/sale.component';
import { ClientSaleComponent } from './screens/sales/sale/client-sale/client-sale.component';
import { ProductsSaleComponent } from './screens/sales/sale/products-sale/products-sale.component';
import { TransportLinesSaleComponent } from './screens/sales/sale/transportlines-sale/transportlines-sale.component';
import { CostsSaleComponent } from './screens/sales/sale/costs-sale/costs-sale.component';
import { ObservationsSaleComponent } from './screens/sales/sale/observations-sale/observations-sale.component';
import { RegisterSaleComponent } from './screens/sales/sale/register-sale/register-sale.component';
import { InventorySaleComponent } from './screens/sales/sale/inventory-sale/inventory-sale.component';

import { LossesComponent } from './screens/losses/losses.component';
import { LostComponent } from './screens/losses/lost/lost.component';
import { ProductsLostComponent } from './screens/losses/lost/products-lost/products-lost.component';
import { ObservationsLostComponent } from './screens/losses/lost/observations-lost/observations-lost.component';
import { RegisterLostComponent } from './screens/losses/lost/register-lost/register-lost.component';
import { InventoryLostComponent } from './screens/losses/lost/inventory-lost/inventory-lost.component';

import { ReportsComponent } from './screens/reports/reports.component';
import { ReporteAnaliticoComponent } from './screens/reports/reporte-analitico/reporte-analitico.component';
import { ReporteLineasTransporteComponent } from './screens/reports/reporte-lineas-transporte/reporte-lineas-transporte.component';

import { ReporteVentasComponent } from './screens/reports/reporte-ventas/reporte-ventas.component';
import { GraphSalesMonthlyComponent } from './screens/reports/reporte-ventas/graph-sales-monthly/graph-sales-monthly.component';
import { GraphSalesClientComponent } from './screens/reports/reporte-ventas/graph-sales-client/graph-sales-client.component';
import { GraphSalesHistoricalComponent } from './screens/reports/reporte-ventas/graph-sales-historical/graph-sales-historical.component';

import { ReporteComprasComponent } from './screens/reports/reporte-compras/reporte-compras.component';
import { GraphOrdersMonthlyComponent } from './screens/reports/reporte-compras/graph-orders-monthly/graph-orders-monthly.component';
import { GraphOrdersProviderComponent } from './screens/reports/reporte-compras/graph-orders-provider/graph-orders-provider.component';
import { GraphOrdersHistoricalComponent } from './screens/reports/reporte-compras/graph-orders-historical/graph-orders-historical.component';

import { GraphExpensesOrdersComponent } from './screens/reports/reporte-lineas-transporte/graph-expenses-orders/graph-expenses-orders.component';
import { GraphExpensesSalesComponent } from './screens/reports/reporte-lineas-transporte/graph-expenses-sales/graph-expenses-sales.component';
import { GraphExpensesLinesComponent } from './screens/reports/reporte-lineas-transporte/graph-expenses-lines/graph-expenses-lines.component';
import { GraphExpensesTransportComponent } from './screens/reports/reporte-lineas-transporte/graph-expenses-transport/graph-expenses-transport.component';

import { ReporteDescuentosComponent } from './screens/reports/reporte-descuentos/reporte-descuentos.component';
import { GraphDiscountsMonthlyComponent } from './screens/reports/reporte-descuentos/graph-discounts-monthly/graph-discounts-monthly.component';
import { GraphDiscountsClientComponent } from './screens/reports/reporte-descuentos/graph-discounts-client/graph-discounts-client.component';
import { GraphDiscountsDetailsComponent } from './screens/reports/reporte-descuentos/graph-discounts-details/graph-discounts-details.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    MyFilterPipe,
    AppComponent,
    HomeComponent,
    LoginComponent,
    CompanyComponent,
    EmployeesComponent,
    ProvidersComponent,
    SeasonsComponent,
    ClientsComponent,
    TransportLinesComponent,
    DriversComponent,
    UnitsComponent,
    UnitsUnitComponent,
    UnitsQualityComponent,
    UnitsTagComponent,
    UnitsSizeComponent,
    UnitsPresentationComponent,
    ProductsComponent,
    PurchasesComponent,
    OrderComponent,
    ProviderOrderComponent,
    ProductsOrderComponent,
    TransportLinesOrderComponent,
    CostsOrderComponent,
    ObservationsOrderComponent,
    RegisterOrderComponent,
    ProductInventoryComponent,
    InventoryComponent,
    SalesComponent,
    SaleComponent,
    ClientSaleComponent,
    ProductsSaleComponent,
    TransportLinesSaleComponent,
    CostsSaleComponent,
    ObservationsSaleComponent,
    RegisterSaleComponent,
    InventorySaleComponent,
    LossesComponent,
    LostComponent,
    ProductsLostComponent,
    ObservationsLostComponent,
    RegisterLostComponent,
    InventoryLostComponent,
    ReportsComponent,
    ReporteAnaliticoComponent,
    ReporteComprasComponent,
    ReporteVentasComponent,
    ReporteLineasTransporteComponent,
    GraphOrdersMonthlyComponent,
    GraphOrdersProviderComponent,
    GraphOrdersHistoricalComponent,
    GraphExpensesOrdersComponent,
    GraphExpensesSalesComponent,
    GraphExpensesLinesComponent,
    GraphExpensesTransportComponent,
    GraphSalesMonthlyComponent,
    GraphSalesClientComponent,
    GraphSalesHistoricalComponent,
    ReporteDescuentosComponent,
    GraphDiscountsMonthlyComponent,
    GraphDiscountsClientComponent,
    GraphDiscountsDetailsComponent,
    InventoryAllComponent,
    ProductInventorySingleComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbModule,
    LeafletModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
        apiKey: environment.keys.gmap,
        libraries: ["places", "geometry"]
    }),
    GooglePlaceModule,
    NgxSmartModalModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    NgxDropzoneModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    NgxPrintModule,
    FilterPipeModule,
    OrderModule,
    AppRoutingModule,
    ChartsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
  }, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
