<div class="container">

  <div class="row mtr-2 text-left">
    <div class="col-12">
      <h6 class="font-weight-bold gray-area p-2 primary">Registra las observaciones</h6>
    </div>
  </div>

  <div class="row justify-content-center mt-2">
    <div class="col-5">
      <form [formGroup]="observationForm" class="text-left">
        <div class="row">
          <div class="col-12 bordered shadow">
            <textarea class="form-control no-border" name="observation" id="observation" formControlName="observation" value="{{observationSelected.observation}}" rows="4" cols="50" placeholder="Describe la observación que deseas registrar." maxlength="150"></textarea>
            <label style="float: right;">Máximo 150 caracteres</label>
          </div>
          <div *ngIf="observationForm.controls['observation'].invalid && (observationForm.controls['observation'].dirty || observationForm.controls['observation'].touched)" class="inputAlert">
            <div *ngIf="observationForm.controls['observation'].errors && observationForm.controls['observation'].errors.required">
                Comentarios obligatorios
            </div>
          </div>
          <div class="col-12">
            <div class="modal-catalog-btn mt-3">
              <img (click)="submitObservation(observationSelected.id);" src="../assets/icons/ic_plus.png" style="width: 62px;" />
              <label (click)="submitObservation(observationSelected.id);" style="font-size: 0.6rem;">
                <span *ngIf="observationSelected.id">EDITAR</span>
                <span *ngIf="!observationSelected.id">AGREGAR</span>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-7">
      <div class="container bordered shadow">
        <div class="row">
          <h6 class="primary m-3">Observaciones registradas en esta venta.</h6>
        </div>
        <div class="row mb-2 timeline-line" *ngFor="let item of rows | paginate: config; let isLast = last">
          <div class="col-2">
            <i class="fas fa-align-right green-circle"></i>
            <div class="{{isLast? '': 'line'}}"></div>
          </div>
          <div class="col-10">
            <p class="m-0 text-left">{{item.observation}}</p>
            <p class="m-0 text-left" style="font-size: 12px;">{{item.registrationDate}}, {{item.user.username}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagerChanged($event)">

              <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                  <a (click)="p.previous()" *ngIf="!p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

              <div *ngFor="let page of p.pages" class="page-number" [class.current]="p.getCurrent() === page.value">
                  <a (click)="p.setCurrent(page.value);" *ngIf="p.getCurrent() !== page.value">
                      <span>{{ page.label }}</span>
                  </a>
                  <div *ngIf="p.getCurrent() === page.value">
                      <span>{{ page.label }}</span>
                  </div>
              </div>

              <div class="pagination-next" [class.disabled]="p.isLastPage()">
                  <a (click)="p.next()" *ngIf="!p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

            </pagination-template>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-12 pager">
            <label>Mostrar <select [(ngModel)]="config.itemsPerPage" (change)='onPagerChanged(1);'>
              <option *ngFor="let c of config.options" [ngValue]="c">{{c}}</option>
            </select> Registros por página</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center mtr-2">
    <div class="col-3">
      <button type="button" (click)="submitObservations();" class="btn btn-primary btn-user btn-block shadow-sm">CONTINUAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
      </button>
    </div>
    <div class="col-2">
       <button type="cancel" [routerLink]="['/purchases']" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
    </div>
  </div>
  
</div>

<ngx-smart-modal customClass="sm-dialog" #successModal identifier="successModal" [closable]="false">
  <div *ngIf="successModal.hasData()" class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-11">
        <h1 class="h5 text-dark">{{ successModal.getData().title }}</h1>
        <p class="h6" style="font-size: 0.9rem;">{{ successModal.getData().subtitle }} </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #deleteModal identifier="deleteModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_warning.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¿Desea eliminar este registro?</h1>
        <p class="h6" style="font-size: 0.9rem;">Recuerda que será eliminado de la base de datos</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <button type="submit" (click)="deleteObservation(observationSelected.id)" class="btn btn-primary btn-user btn-block shadow-sm">SÍ, ELIMINAR<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
      <div class="col-4">
        <button type="cancel" (click)="deleteModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
      </div>
    </div>
  </div>
</ngx-smart-modal>