import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { NgxSmartModalService, NgxSmartModalComponent, } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../../environments/environment';
import { FormsService } from './../../../services/forms/forms.service';
import { AdminService } from './../../../services/admin/admin.service';
import { DriversComponent } from './../../../screens/drivers/drivers.component';
import { PurchasesService } from './../../../services/purchases/purchases.service';
declare var $: any;

@Component({
  selector: 'app-transportlines-order',
  templateUrl: './transportlines-order.component.html',
  styleUrls: ['./transportlines-order.component.css']
})
export class TransportLinesOrderComponent implements OnInit, OnChanges {
  @Output() continue = new EventEmitter<any>();
  @Output() updateOrder = new EventEmitter<any>();
  @Input() orderSelected;

  public formTransportLine:FormGroup;
  public transportLines: any[] = [];
  public drivers: any[] = [];

  //table, pagination
  public config = {
    id: 'transportLines',
    searchText: '',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    options: [20, 50, 100]
  };
  public responseMessage:String;

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private adminService: AdminService, private toastService: ToastrService, private orderService: PurchasesService) {
    this.formTransportLine = this.formBuilder.group({
      transportLines: ['', [Validators.required]],
      driver: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {    
    this.populateTransportLines();
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    let log: string[] = [];
    for (let propName in changes) {
      if(propName == 'orderSelected'){
        console.log("orderSelected", this.orderSelected);
        this.formTransportLine.patchValue(this.orderSelected);
      }
    }
  }

  public populateTransportLines(){
    this.spinner.show();
    this.adminService.getTransportLines().subscribe(
      res => {
        this.transportLines = res;
        setTimeout(()=>{
          if(this.orderSelected.transportLines){
            $("#transportLines").val(this.orderSelected.transportLines.id).selectpicker("refresh");
            this.populateDrivers(this.orderSelected.transportLines.id);
          }else{
            $("#transportLines").val('default').selectpicker("refresh");
          }
        }, 100);
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public populateDrivers(transportLineId){
    this.spinner.show();
    this.adminService.getDrivers(transportLineId).subscribe(
      res => {
        this.drivers = res;
        setTimeout(()=>{
          var value = this.orderSelected.driver? this.orderSelected.driver.id: 'default';
          $("#driver").val(value).selectpicker("refresh");
        }, 100);
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public submitTransportLine(){
    if (!this.formTransportLine.valid) {
       this.formsService.validateAllFormFields(this.formTransportLine);
    }else {
      this.updateCurrentOrder(this.formTransportLine.value);
    }
  }

  public deleteTransportLine(){
    var obj = {
      transportLines: null,
      driver: null
    }
    this.updateCurrentOrder(obj);
    this.formTransportLine.reset();
    setTimeout(()=>{
      $("#driver").val('default').selectpicker("refresh");
      $("#transportLines").val('default').selectpicker("refresh");
    }, 100);
    this.modalService.getModal('deleteModal').close();
  }

  // actions
  edit() {
    this.spinner.show();
    this.adminService.getDrivers(this.orderSelected.transportLines.id).subscribe(
      res => {
        this.drivers = res;
        this.formTransportLine.controls['transportLines'].patchValue(this.orderSelected.transportLines.id);
        this.formTransportLine.controls['driver'].patchValue(this.orderSelected.driver.id);
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  active(item, enabled){ }

  delete(item) {
    this.modalService.open("deleteModal");
  }

  //modals
  public openSuccessModal(title, subtitle){
    const obj: Object = {
      title: title,
      subtitle: subtitle
    };
    console.log("obj: ", JSON.stringify(obj));
    this.modalService.resetModalData('successModal');
    this.modalService.setModalData(obj, 'successModal');
    this.modalService.getModal('successModal').open();
  }

  public onTransportLinesChanged(value){
    console.log("value", value);
    this.formTransportLine.controls['driver'].setValue('');
    this.populateDrivers(value);
  }

  public onDriverChanged(value){
    console.log("value", value);
  }

  public updateCurrentOrder(obj){
    this.updateOrder.next(obj);
  }

  public submitDriver(){
    if(this.formTransportLine.valid){
      this.updateCurrentOrder(this.formTransportLine.value);
      this.continue.next();
    }else{
      this.formsService.validateAllFormFields(this.formTransportLine);
    }
  }

}