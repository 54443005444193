<!-- Page Wrapper -->
<div id="wrapper">

  <!-- Sidebar -->
  <ul *ngIf="profile" class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled" id="accordionSidebar">
    <a class="sidebar-brand d-flex align-items-center justify-content-center" routerLink="/">
      <div id="mainLogo" [routerLink]="['/']" style="cursor: pointer;">
        <img *ngIf="company" [src]="environment.imagesUrl + company.logo" />
      </div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0">

    <div id="optionsBottomContainer">

      <li *ngFor="let option of listOptions; let a = index;" class="nav-item" style="margin: 0 !important;" [routerLinkActiveOptions]="{exact:(option.route == '')}" routerLinkActive="active-link">
        <a *ngIf="!option.childOption.length" class="link" [routerLink]="[option.route]" (click)="toggleContent();" style="text-decoration: none !important;">
          <span class="option list-group-horizontal">
            <div class="list-group-vertical text-center align-items-center" style="margin: 0 auto;">
              <img class="option-icon" src="{{imagesUrl + option.icon}}"/>
              <span style="font-size: 10px; color: white; text-decoration: none;">{{option.name}}</span>
            </div>
          </span>
        </a>
        <a *ngIf="option.childOption.length" class="nav-link collapsed"  href="#" data-toggle="collapse" [attr.data-target]="'#' + option.name" aria-expanded="true" style="text-decoration: none !important;">
          <span class="option list-group-horizontal">
            <div class="list-group-vertical text-center align-items-center" style="margin: 0 auto;">
              <img class="option-icon" src="{{imagesUrl + option.icon}}"/>
              <span style="font-size: 10px; color: white; text-decoration: none;">{{option.name}}</span>
            </div>
          </span>
        </a>

        <div id="{{option.name}}" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded">
            <!-- <h6 class="collapse-header">{{option.name}}</h6> !-->
            <div class="container container-fluid">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12 mb-1 option-rounded">
                  <div class="text-center align-items-center list-group-vertical">
                    <span class="icon" style="width: 50px; height: 50px;">
                      <img *ngIf="profile.photo" class="option-icon" [src]="imagesUrl + profile.photo" />
                      <img *ngIf="!profile.photo" class="option-icon" src="../assets/icons/ic_p.png" />
                    </span>
                    <span style="color:gray !important; font-size: 10px;">{{profile.rol? profile.rol.nombre: ''}}</span>
                  </div>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12 text-center align-items-center" style="display: flex;">                
                  <span class="text" id="roleName">
                    <b style="font-family: poppins-bold !important;">{{profile.name}} {{profile.lastname}}</b>
                  </span>
                </div>
              </div>
              <hr class="sidebar-divider-dark">

              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 childOption mb-1" *ngFor="let child of option.childOption;">
                  <img class="option-icon" src="{{imagesUrl + child.icon}}"/>
                  <a class="" [routerLink]="[child.route]" (click)="toggleContent();">{{child.name}}</a>
                </div>
              </div>

            </div>
          </div>
        </div>

      </li>
    </div>
  </ul>
  <!-- End of Sidebar -->

  <!-- Content Wrapper -->
  <div id="content-wrapper" #contentWrapper class="d-flex flex-column">

    <div class="content-blur"></div>

    <!-- Main Content -->
    <div id="content">

      <!-- Topbar !-->
      <nav *ngIf="profile" class="navbar navbar-expand navbar-light bg-white topbar mb-0 static-top" style="border-bottom: 2px solid #eae9e9;">
        <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
          <i class="fa fa-bars"></i>
        </button>

        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">

            <div class="topbar-divider d-none d-sm-block"></div>

            <!-- Nav Item - User Information -->
            <li class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="flex-direction: row;">
                <span id style="display: grid;margin-left: -1rem; color: #4c6f77;">
                  <div class="mr-2 d-none d-lg-inline small">{{profile.name}} {{profile.lastname}}</div>
                  <div class="mr-2 d-none d-lg-inline small" style="font-size: 70%;">{{profile.rol? profile.rol.nombre: ''}}</div>
                </span>
                <i class="userDropdownIcon fa fa-angle-down show" aria-hidden="true"></i>
                <i class="userDropdownIcon fa fa-angle-up" aria-hidden="true"></i>
                <div class="option-rounded">
                  <div class="text-center align-items-center list-group-vertical">
                    <span class="icon" style="width: 50px; height: 50px;">
                      <img *ngIf="profile.photo" class="option-icon" [src]="imagesUrl + profile.photo" />
                      <img *ngIf="!profile.photo" class="option-icon" src="../assets/icons/ic_p.png" />
                    </span>
                  </div>
                </div>
              </a>
              <!-- Dropdown - User Information -->
              <div id="userDropdownMenu" class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" (click)="logout();">
                      <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Cerrar Sesión
                  </a>
              </div>
            </li>

        </ul>

      </nav>

      <!-- Begin Page Content -->
      <div class="container-fluid mt-3">

        <router-outlet></router-outlet>

      </div>
      <!-- /.container-fluid -->

    </div>
    <!-- End of Main Content -->

    <!-- Footer -->
    <footer class="sticky-footer">
      <div class="container my-auto">
        <div class="copyright text-center my-auto">
          <span>LANDER SELECT © 2020 Todos los derechos reservados.</span>
        </div>
      </div>
    </footer>
    <!-- End of Footer -->

  </div>
  <!-- End of Content Wrapper -->

</div>
<!-- End of Page Wrapper -->

<!-- Scroll to Top Button-->
<a class="scroll-to-top rounded" href="#page-top">
  <i class="fas fa-angle-up"></i>
</a>

<ngx-smart-modal customClass="sm-dialog" #logOutModal identifier="logOutModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_warning.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¿Deseas cerrar sesión y salir del sistema?</h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <button type="submit" (click)="logOutConfirmed();" class="btn btn-primary btn-user btn-block shadow-sm">SÍ, SALIR<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
      <div class="col-4">
        <button type="cancel" (click)="logOutModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.6)" size = "medium" color = "rgb(188, 228, 197)" type = "ball-atom" [fullScreen] = "true"><p style="color: white" > Cargando... </p></ngx-spinner>