import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { NgxSmartModalService, NgxSmartModalComponent, } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { environment } from './../../../../../environments/environment';
import { FormsService } from './../../../../services/forms/forms.service';
import { AdminService } from './../../../../services/admin/admin.service';
import { DriversComponent } from './../../../../screens/drivers/drivers.component';
declare var $: any;

@Component({
  selector: 'app-transportlines-sale',
  templateUrl: './transportlines-sale.component.html',
  styleUrls: ['./transportlines-sale.component.css']
})
export class TransportLinesSaleComponent implements OnInit, OnChanges {
  @Output() continue = new EventEmitter<any>();
  @Output() updateSale = new EventEmitter<any>();
  @Input() saleSelected;

  public formTransportLine:FormGroup;
  public transportLines: any[] = [];
  public drivers: any[] = [];

  constructor(private formBuilder: FormBuilder, private formsService: FormsService, public modalService: NgxSmartModalService, private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private adminService: AdminService, private toastService: ToastrService, @Inject(DOCUMENT) document) {
    this.formTransportLine = this.formBuilder.group({
      transportLines: ['', [Validators.required]],
      driver: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {    
    this.populateTransportLines();
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    let log: string[] = [];
    for (let propName in changes) {
      if(propName == 'saleSelected'){
        console.log("saleSelected", this.saleSelected);
        this.formTransportLine.patchValue(this.saleSelected);
      }
    }
  }

  public populateTransportLines(){
    this.spinner.show();
    this.adminService.getTransportLines().subscribe(
      res => {
        this.transportLines = res;
        if(this.saleSelected.transportLines){
          this.populateDrivers(this.saleSelected.transportLines.id);
          setTimeout(()=>{
            $("#transportLines").val(this.saleSelected.transportLines.id).selectpicker("refresh");
          }, 100);
        }else{
          setTimeout(()=>{
            $("#transportLines").val('default').selectpicker("refresh");
          }, 100);
        }
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public populateDrivers(transportLineId){
    this.spinner.show();
    this.adminService.getDrivers(transportLineId).subscribe(
      res => {
        this.drivers = res;
        if(this.saleSelected.driver){
          setTimeout(()=>{
            $("#driver").val(this.saleSelected.driver.id).selectpicker("refresh");
          }, 100);          
        }else{
          setTimeout(()=>{
            $("#driver").val('default').selectpicker("refresh");
          }, 100);
        }
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public submitTransportLine(){
    if (!this.formTransportLine.valid) {
       this.formsService.validateAllFormFields(this.formTransportLine);
    }else {
      this.updateSale.next(this.formTransportLine.value);
    }
  }

  public deleteTransportLine(){
    var obj = {
      transportLines: null,
      driver: null
    }
     this.updateSale.next(obj);
     this.modalService.getModal('deleteModal').close();
  }

  // actions
  edit() {
    this.spinner.show();
    this.adminService.getDrivers(this.saleSelected.transportLines.id).subscribe(
      res => {
        this.drivers = res;
        this.formTransportLine.controls['transportLines'].patchValue(this.saleSelected.transportLines.id);
        this.formTransportLine.controls['driver'].patchValue(this.saleSelected.driver.id);
        this.spinner.hide();
      },
      err => {
        console.log("error ->", err);
        this.spinner.hide();
      }
    );
  }

  public onTransportLinesChanged(value){
    console.log("value", value);
    this.formTransportLine.controls['driver'].setValue('');
    this.populateDrivers(value);
  }

  public onDriverChanged(value){
    console.log("value", value);
  }

  public next(){
    this.formTransportLine.patchValue(this.saleSelected);
    if (!this.formTransportLine.valid) {
      this.formsService.validateAllFormFields(this.formTransportLine);
    }else{
      this.continue.next()      
    }
  }

}