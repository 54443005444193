<section class="container justify-content-center text-center p-0">
  <article class="row justify-content-center">
    <div class="col-11">
      <h4 class="catalog-title font-weight-bold text-dark mb-3">
        <img class="header-image" src="../assets/icons/ic_products.png"/>
      PRODUCTOS</h4>
      <div class="container">
        <div class="row mb-5" style="align-items: baseline;">
          <div class="col-lg-6 col-8">
            <div class="search-control">
              <i class="fa fa-search"></i>
              <input type="search" #searchInput [(ngModel)]="config.searchText" (search)="onSearch()" class="form-control input-search text-dark" placeholder="Buscar por id, nombre, descripción"/>              
            </div>
          </div>
          <div class="col-lg-6 col-4 text-right">
            <div class="catalog-btn">
              <img src="../assets/icons/ic_plus.png" (click)="openProductModal();" />
              <label (click)="openProductModal();">NUEVO PRODUCTO</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">NOMBRE DEL PRODUCTO</th>
                    <th scope="col">ACCIONES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rows | paginate: config">
                    <td>{{item.id}}</td>
                    <td>{{item.name}}</td>
                    <td class="iconContainer justify-content-center" style="display: flex;">
                      <img class="catalog-icon" src="../assets/icons/ic_edit.png" (click)="openProductModal(item);" placement="top" ngbTooltip="editar"/>
                      <img *ngIf="item.status == 1" class="catalog-icon" src="../assets/icons/ic_enabled.png" (click)="disable(item);" placement="top" ngbTooltip="deshabilitar"/>
                      <img *ngIf="item.status == 0" class="catalog-icon" src="../assets/icons/ic_disabled.png" (click)="enable(item);" placement="top" ngbTooltip="habilitar"/>
                      <img class="catalog-icon" src="../assets/icons/ic_units.png" (click)="showUnits(item);" placement="top" ngbTooltip="unidades"/>
                      <img *ngIf="!item.enabled" class="catalog-icon" src="../assets/icons/ic_delete.png" (click)="delete(item);" placement="top" ngbTooltip="eliminar"/>
                    </td>
                  </tr>
                  <tr *ngIf="(rows).length === 0">
                    <td colspan="6">No se encontraron elementos</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagerChanged($event)">

              <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                  <a (click)="p.previous()" *ngIf="!p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isFirstPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

              <div *ngFor="let page of p.pages" class="page-number" [class.current]="p.getCurrent() === page.value">
                  <a (click)="p.setCurrent(page.value);" *ngIf="p.getCurrent() !== page.value">
                      <span>{{ page.label }}</span>
                  </a>
                  <div *ngIf="p.getCurrent() === page.value">
                      <span>{{ page.label }}</span>
                  </div>
              </div>

              <div class="pagination-next" [class.disabled]="p.isLastPage()">
                  <a (click)="p.next()" *ngIf="!p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
                  <a *ngIf="p.isLastPage();">
                    <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
                  </a>
              </div>

            </pagination-template>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 pager">
            <label>Mostrar <select [(ngModel)]="config.itemsPerPage" (change)='onPagerChanged(1);'>
              <option *ngFor="let c of config.options" [ngValue]="c">{{c}}</option>
            </select> Registros por página</label>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>

<ngx-smart-modal customClass="xl-dialog closable" #productModal identifier="productModal" [closable]="true">
  <button class="btn-back-modal" (click)="productModal.close()">
    <i class="fas fa-long-arrow-alt-left"></i>
  </button>
  <div class="container justify-content-center text-left">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="row justify-content-center mb--4">
          <div class="col-6 text-center">
            <h1 class="h6 font-weight-bold text-dark mb-0 ml-3">
              <p *ngIf="!itemSelected.id">AGREGAR PRODUCTO</p>
              <p *ngIf="itemSelected.id">EDITAR PRODUCTO</p>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container justify-content-center text-left">
      <form [formGroup]="formProduct">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="{{itemSelected.name? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
              <label for="name" class="dark"><b class="red">*</b>Ingrese el nombre</label>
              <input type="text" class="form-control no-border" name="name" id="name" formControlName="name" value="{{itemSelected.name}}" />
              <div class="border-control"></div>
              <div *ngIf="formProduct.controls['name'].invalid && (formProduct.controls['name'].dirty || formProduct.controls['name'].touched)" class="inputAlert">
                <div *ngIf="formProduct.controls['name'].errors && formProduct.controls['name'].errors.required">
                    Nombre obligatorio
                </div>
              </div>
            </div>
          </div>
          <!--
          <div class="col-lg-7">
            <div class="{{itemSelected.description? 'form-group mb-0 label-animate': 'form-group mb-0'}}">
              <label for="description" class="dark"><b class="red">*</b>Ingrese la descripción</label>
              <input type="text" class="form-control no-border" name="description" id="description" formControlName="description" value="{{itemSelected.description}}" />
              <div class="border-control"></div>
              <div *ngIf="formProduct.controls['description'].invalid && (formProduct.controls['description'].dirty || formProduct.controls['description'].touched)" class="inputAlert">
                <div *ngIf="formProduct.controls['description'].errors && formProduct.controls['description'].errors.required">
                    Descripción obligatoria
                </div>
              </div>
            </div>
          </div>
          !-->

        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-lg-5">
            <button type="submit" (click)="submitProduct(itemSelected.id)" class="btn btn-primary btn-user btn-block shadow-sm">
              <span *ngIf="!itemSelected.id">AGREGAR PRODUCTO</span>
              <span *ngIf="itemSelected.id">GUARDAR</span>
              <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
            </button>
          </div>
          <div class="col-lg-3">
            <button type="cancel" (click)="productModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="xxxl-dialog" #unitsModal identifier="unitsModal" [closable]="true">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <app-units [product]="itemSelected" style="width: 100%;"></app-units>
      <div class="row justify-content-center" style="width: 20%;">
        <button type="submit" (click)="unitsModal.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #successModal2 identifier="successModal2" [closable]="false">
  <div *ngIf="successModal2.hasData()" class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_ok.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">{{ successModal2.getData().title }}</h1>
        <p class="h6" style="font-size: 0.9rem;">{{ successModal2.getData().subtitle }} </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <button type="submit" (click)="successModal2.close()" class="btn btn-primary btn-user btn-block shadow-sm">ACEPTAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal customClass="sm-dialog" #deleteModal identifier="deleteModal" [closable]="false">
  <div class="container justify-content-center text-center">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <img class="dialog-ok" src="../assets/icons/ic_warning.png"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h1 class="h5 text-dark">¿Desea eliminar este registro?</h1>
        <p class="h6" style="font-size: 0.9rem;">Recuerda que será eliminado de la base de datos</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <button type="submit" (click)="deleteProduct(itemSelected.id)" class="btn btn-primary btn-user btn-block shadow-sm">SÍ, ELIMINAR<img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
        </button>
      </div>
      <div class="col-4">
        <button type="cancel" (click)="deleteModal.close()" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
      </div>
    </div>
  </div>
</ngx-smart-modal>