import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MouseEvent } from '@agm/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { LossesService } from './../../../../services/losses/losses.service';
declare var $: any;

@Component({
  selector: 'app-products-lost',
  templateUrl: './products-lost.component.html',
  styleUrls: ['./products-lost.component.css']
})
export class ProductsLostComponent implements OnInit, OnChanges {
  @Output() continue = new EventEmitter<any>();
  @Output() updateLost = new EventEmitter<any>();
  @Input() lostSelected;

  public rows: any = [];
  public alreadyOpened = false;

  constructor(private formBuilder: FormBuilder, public modalService: NgxSmartModalService, 
    private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private toastService: ToastrService, private lossesService: LossesService) {
  }

  ngOnInit(): void {}

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    let log: string[] = [];
    for (let propName in changes) {
      if(propName == 'lostSelected'){
        console.log("lostSelected --> ", this.lostSelected);
        if(this.lostSelected.productsOrder){
          this.rows = [this.lostSelected.productsOrder] || [];
        }
      }
    }
  }

  public openInventoryModal(){
    this.modalService.getModal('inventoryModal').open();
    setTimeout(()=>{
      $('#formInventory').trigger("reset");
    }, 100);
    if(!this.alreadyOpened){
      this.alreadyOpened = true;
      setTimeout(()=>{
        $(".input-spinner").inputSpinner();
      }, 100);
    }
  }

  public updateLost2($event){
    this.modalService.getModal('inventoryModal').close();
    this.spinner.show();
    this.lossesService.getLost(this.lostSelected.id).subscribe(
      (res) => {
        console.log("lost-res->", res);
        this.lostSelected = res;
        this.updateLost.next(this.lostSelected);
        this.modalService.getModal('successModal').open();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public submitProducts(){
    this.continue.next();  
  }

}