import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

const headers = new HttpHeaders({
  'Content-Type': 'text/plain; charset=utf-8',
});

@Injectable({
  providedIn: 'root'
})
export class ExcelsService {

  constructor(private http: HttpClient, private datePipe: DatePipe,) {}

  /* Reporte Compras */

  getGraphCompras(config) {
    var params = '?begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get(environment.apiUrl + '/reportsSheets/graphPurchaseProducts/' + params, {
        responseType: 'text', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Compras por mes", "text/csv"),
          error => throwError(error)
        )
      );
  }

  getReporteComprasPorProveedor(config) {
    var params = '?begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get(environment.apiUrl + '/reportsSheets/providerPurchaseProducts/' + params, {
        responseType: 'text', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Compras por proveedor", "text/csv"),
          error => throwError(error)
        )
      );
  }

  getReporteComprasHistorico(config) {
    var params = '?begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd') +
                 '&providerId=' + config.provider;

      return this.http.get(environment.apiUrl + '/reportsSheets/historyPurchaseProducts/' + params, {
        responseType: 'text', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Histórico de compras", "text/csv"),
          error => throwError(error)
        )
      );
  }

  /* Reporte Ventas */
  
  getGraphVentas(config) {
    var params = '?begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get(environment.apiUrl + '/reportsSheets/graphSaleProducts/' + params, {
        responseType: 'text', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Ventas por mes", "text/csv"),
          error => throwError(error)
        )
      );
  }


  getReporteVentasPorCliente(config) {
    var params = '?begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get(environment.apiUrl + '/reportsSheets/clientSaleProducts/' + params, {
        responseType: 'text', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Ventas por cliente", "text/csv"),
          error => throwError(error)
        )
      );
  }

  getReporteVentasHistorico(config) {
    var params = '?begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd') +
                 '&clientId=' + config.client;

      return this.http.get(environment.apiUrl + '/reportsSheets/historySaleProducts/' + params, {
        responseType: 'text', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Histórico de ventas", "text/csv"),
          error => throwError(error)
        )
      );
  }
  
  /*  Reporte Linea de Transporte */

  graphTransportLines(config) {
    var params = '?begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get(environment.apiUrl + '/reportsSheets/graphTransportLines/' + params, {
        responseType: 'text', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Gastos de transporte por mes", "text/csv"),
          error => throwError(error)
        )
      );
  }

  getReportetransportLineExpenses(config) {
    var params = '?begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get(environment.apiUrl + '/reportsSheets/transportLineExpenses' + params, {
        responseType: 'text', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Gastos por línea de transporte", "text/csv"),
          error => throwError(error)
        )
      );
  }
  
  getReporteLineaTransporteGastosVentas(id, config) {
    var params = '?begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get(environment.apiUrl + '/reportsSheets/transportLine/' + id + '/salesExpenses' + params, {
        responseType: 'text', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Detallado gastos en ventas", "text/csv"),
          error => throwError(error)
        )
      );
  }

  getReporteLineaTransporteGastosCompras(id, config) {
    var params = '?begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get(environment.apiUrl + '/reportsSheets/transportLine/' + id + '/purchasesExpenses' + params, {
        responseType: 'text', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Detallado gastos en compras", "text/csv"),
          error => throwError(error)
        )
      );
  }


  /*  Descuentos */

  getGraphDescuentos(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get(environment.apiUrl + '/reportsSheets/graphDiscounts' + params, {
        responseType: 'text', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Descuentos por mes", "text/csv"),
          error => throwError(error)
        )
      );
  }
  
  getReporteDescuentosPorCliente(config) {
      var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get(environment.apiUrl + '/reportsSheets/discountClients' + params, {
        responseType: 'text', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Descuentos sobre venta por cliente", "text/csv"),
          error => throwError(error)
        )
      );
  }

  getReporteDescuentosDetalle(config) {
    var params = '?page=' + config.currentPage +
                 '&limit=' + config.itemsPerPage +
                 '&begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd') +
                 '&clientId=' + config.clientId;

      return this.http.get(environment.apiUrl + '/reportsSheets/discountDetails' + params, {
        responseType: 'text', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Detalle de descuento sobre ventas por cliente", "text/csv"),
          error => throwError(error)
        )
      );
  }

  getReporteProductInventory(id) {
    return this.http.get(environment.apiUrl + '/reportsSheets/' + id + '/productInventory', {
        responseType: 'text', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Inventio del producto", "text/csv"),
          error => throwError(error)
        )
      );
 }

 getGraphAll(config) {
   const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.ms-excel',
      "content-disposition": "attachment; filename=Gráfica General por mes.xlsx"
   });

    var params = '?begin=' + this.datePipe.transform(config.startDate, 'yyyy-MM-dd') +
                 '&end=' + this.datePipe.transform(config.endDate, 'yyyy-MM-dd');

      return this.http.get(environment.apiUrl + '/reportsSheets/graphAll/' + params, {
        responseType: 'blob', headers: headers
      }).pipe(tap( // Log the result or error
          data => this.downLoadFile(data, "Gráfica General por mes.xlsx", "application/vnd.ms-excel"),
          error => throwError(error)
        )
      );
  }


  /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
  downLoadFile(data: any, filename: string, type: string) {
      let blob = new Blob([data], { type: type});

      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
      /*
      let url = window.URL.createObjectURL(blob);
      let pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          alert( 'Please disable your Pop-up blocker and try again.');
      }
      */
  }

}