import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MouseEvent } from '@agm/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from "ngx-filter-pipe";
import { SalesService } from './../../../../services/sales/sales.service';
declare var $: any;

@Component({
  selector: 'app-products-sale',
  templateUrl: './products-sale.component.html',
  styleUrls: ['./products-sale.component.css']
})
export class ProductsSaleComponent implements OnInit, OnChanges {
  @Output() continue = new EventEmitter<any>();
  @Output() updateSale = new EventEmitter<any>();
  @Input() saleSelected;

  public rows: any = [];
  public alreadyOpened = false;

  constructor(private formBuilder: FormBuilder, public modalService: NgxSmartModalService, 
    private spinner: NgxSpinnerService, private filterPipe: FilterPipe, 
    private toastService: ToastrService, private salesService: SalesService) {
  }

  ngOnInit(): void {}

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    let log: string[] = [];
    for (let propName in changes) {
      if(propName == 'saleSelected'){
        console.log("saleSelected --> ", this.saleSelected);
        this.rows = this.saleSelected.saleProductsProductsOrder || [];
      }
    }
  }

  public openInventoryModal(){
    this.modalService.getModal('inventoryModal').open();
    if(!this.alreadyOpened){
      this.alreadyOpened = true;
      setTimeout(()=>{
        $(".input-spinner").inputSpinner();
      }, 100);
    }
  }

  public updateSale2($event){
    this.modalService.getModal('inventoryModal').close();
    this.spinner.show();
    this.salesService.getSale(this.saleSelected.id).subscribe(
      (res) => {
        console.log("Sale-res->", res);
        this.saleSelected = res;
        this.updateSale.next(this.saleSelected);
        this.modalService.getModal('successModal').open();
        this.spinner.hide();
      },
      (err) => {
        console.log("err", err);
        this.spinner.hide();
      }
    );
  }

  public submitProducts(){
    this.continue.next();  
  }

}