<div class="container">
  <!-- Outer Row -->
  <div class="row justify-content-center">
    <div class="col-11">
      <form [formGroup]="formProviderInfo" class="text-left">
        <div class="row mtr-2">
          <div class="col-lg-6">
            <h6 class="font-weight-bold text-dark">Información del proveedor</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mb-0 label-animate">
              <label for="provider" class="dark" style="top: 5px !important;"><b class="red">*</b>Selecciona razón social del proveedor</label>
              <select class="form-control no-border" data-live-search="true" name="provider" id="provider" formControlName="provider" value="{{orderSelected.provider.id}}" (change)='onProviderChanged($event.target.value);'>
                <option value="">--</option>
                <option *ngFor="let p of providers" value="{{p.id}}" attr.data-tokens="p.businessName">{{p.businessName}}</option>
              </select>
              <div class="border-control"></div>
              <div *ngIf="formProviderInfo.controls['provider'].invalid && (formProviderInfo.controls['provider'].dirty || formProviderInfo.controls['provider'].touched)" class="inputAlert">
                <div *ngIf="formProviderInfo.controls['provider'].errors && formProviderInfo.controls['provider'].errors.required">
                    Razón social del proveedor obligatoria
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-0 label-animate">
              <label for="trip" class="dark" style="top: 5px !important;"><b class="red">*</b>Ejemplo: LAURITA001</label>
              <input type="text" class="form-control no-border" name="trip" id="trip" formControlName="trip" value="{{orderSelected.trip}}">
              <img class="catalog-icon" (click)="editSeason()" src="../assets/icons/ic_edit.png" style="float: right; position: relative; bottom: 37px;" />
              <div class="border-control"></div>
              <div *ngIf="formProviderInfo.controls['trip'].invalid && (formProviderInfo.controls['trip'].dirty || formProviderInfo.controls['trip'].touched)" class="inputAlert">
                <div *ngIf="formProviderInfo.controls['trip'].errors && formProviderInfo.controls['trip'].errors.required">
                    Etiqueta obligatoria
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-6">
            <h6 class="font-weight-bold text-dark">Selecciona las fechas de tu compra</h6>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mb-0 label-animate">
              <label for="purchaseDate" class="dark" style="top: 5px !important;"><b class="red">*</b>Selecione la fecha de compra</label>
              <input type="date" class="form-control no-border" name="purchaseDate" id="purchaseDate" formControlName="purchaseDate" value="{{orderSelected.purchaseDate}}">
              <div class="border-control"></div>
              <div *ngIf="formProviderInfo.controls['purchaseDate'].invalid && (formProviderInfo.controls['purchaseDate'].dirty || formProviderInfo.controls['purchaseDate'].touched)" class="inputAlert">
                <div *ngIf="formProviderInfo.controls['purchaseDate'].errors && formProviderInfo.controls['purchaseDate'].errors.required">
                    Fecha de compra obligatoria
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-0 label-animate">
              <label for="arrivalDate" class="dark" style="top: 5px !important;"><b class="red">*</b>Selecione la fecha de llegada</label>
              <input type="date" class="form-control no-border" name="arrivalDate" id="arrivalDate" formControlName="arrivalDate" value="{{orderSelected.arrivalDate}}">
              <div class="border-control"></div>
              <div *ngIf="formProviderInfo.controls['arrivalDate'].invalid && (formProviderInfo.controls['arrivalDate'].dirty || formProviderInfo.controls['arrivalDate'].touched)" class="inputAlert">
                <div *ngIf="formProviderInfo.controls['arrivalDate'].errors && formProviderInfo.controls['arrivalDate'].errors.required">
                    Fecha de llegada obligatoria
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mtr-2">
          <div class="col-3">
            <button type="submit" (click)="submitProvider()" class="btn btn-primary btn-user btn-block shadow-sm">CONTINUAR <img class="btn-icon" src="../assets/icons/ic_chevron_double_right.png"/>
            </button>
          </div>
          <div class="col-2">
             <button type="cancel" [routerLink]="['/purchases']" class="btn btn-border btn-user btn-block shadow-sm">CANCELAR</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>